import React, { Component, useEffect } from "react";

// Component imports
import SideNav from "../../../shared/SideNav";
import { validateEmptyString } from "../../../shared/Validation";
import {
  createHeroImage,
  editHero,
  resetHero,
} from "../../../../actions/HeroAction.js";
import { resetLoader } from "../../../../actions/ApplicationAction";
import NotFound from "../../../shared/NotFound";

// External imports
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";

function CreateHeroImage(props) {
  useEffect(() => {
    return () => {
      // reset the state
      props.resetHero();
      // reset the loader
      props.resetLoader();
    };
  });

  if (typeof props.location.state === "undefined") return <NotFound />;

  const selectedService = props.location.state.selectedService;
  const selectedHero = props.location.state.selectedHero;
  const returnedMessage = props.returnedMessage;
  const isLoading = props.isLoading;

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <div className="px-10 w-full">
        <div className="flex flex-row space-x-5 items-center">
          <Link to={"/hero_image"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Blog Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-default">
              {selectedService === "ADD_HERO" &&
              typeof selectedService !== "undefined"
                ? "Add Hero Image"
                : selectedService === "EDIT_HERO" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedHero !== "undefined"
                ? "Edit Hero Image"
                : ""}
            </p>
            <p className="font-thin text-sm">
              {selectedService === "ADD_HERO" &&
              typeof selectedService !== "undefined"
                ? "Create and add new hero image to store"
                : selectedService === "EDIT_HERO" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedHero !== "undefined"
                ? "Edit hero image information on your store"
                : ""}
            </p>
          </div>
        </div>

        {/* Blog form */}
        <HeroForm
          selectedService={selectedService}
          selectedHero={selectedHero}
          createHero={props.createHero}
          editHero={props.editHero}
          returnedMessage={returnedMessage}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

class HeroForm extends Component {
  state = {
    heroImage: "",
    heroDescription:
      this.props.selectedService === "ADD_HERO"
        ? ""
        : this.props.selectedHero.description,
    heroFeature:
      this.props.selectedService === "ADD_HERO"
        ? ""
        : this.props.selectedHero.is_featured
        ? true
        : false,
    shopButtonAlignment:
      this.props.selectedService === "ADD_HERO"
        ? ""
        : this.props.selectedHero.btn_alignement,
    errorMessage: [],
  };

  render() {
    let {
      isLoading,
      returnedMessage,
      selectedService,
      createHero,
      selectedHero,
      editHero,
    } = this.props;

    // Handling onChange events
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    // Handling onFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      let { heroImage, heroDescription, heroFeature, shopButtonAlignment } =
        this.state;
      const singleErrorMessage = [];

      if (selectedService === "ADD_HERO") {
        if (
          !validateEmptyString(heroImage) ||
          !validateEmptyString(heroDescription) ||
          !validateEmptyString(heroFeature) ||
          !validateEmptyString(shopButtonAlignment)
        ) {
          singleErrorMessage.push(
            "One or more fields are missing. Please fill and try again"
          );
          return this.setState({ errorMessage: singleErrorMessage });
        }
        this.setState({ errorMessage: [] });

        let heroContent = new FormData();
        const leadingImageQuery = document.querySelector("#heroImage");
        heroImage = leadingImageQuery.files[0];

        heroContent.append("description", heroDescription);
        heroContent.append("featured", heroFeature);
        heroContent.append("btn_alignment", shopButtonAlignment);
        heroContent.append("image", heroImage);

        //  Create action
        await createHero(heroContent);
      } else if (selectedService === "EDIT_HERO") {
        if (
          !validateEmptyString(heroDescription) ||
          !validateEmptyString(heroFeature) ||
          !validateEmptyString(shopButtonAlignment)
        ) {
          singleErrorMessage.push(
            "One or more fields are missing. Please fill and try again"
          );
          return this.setState({ errorMessage: singleErrorMessage });
        }

        this.setState({ errorMessage: [] });

        let heroContent = new FormData();
        const leadingImageQuery = document.querySelector("#heroImage");
        heroImage =
          typeof leadingImageQuery === "undefined"
            ? ""
            : leadingImageQuery.files[0];

        heroContent.append("_method", "PUT");
        heroContent.append("description", heroDescription);
        heroContent.append("featured", heroFeature);
        heroContent.append("btn_alignment", shopButtonAlignment);
        if (typeof leadingImageQuery.files[0] !== "undefined") {
          heroContent.append("image", heroImage);
        }

        //   Edit action
        await editHero(heroContent, selectedHero.id);
      }
    };

    const errorMessageMapping =
      this.state.errorMessage && this.state.errorMessage.length > 0
        ? this.state.errorMessage.map((SingleError) => {
            return (
              <ul className="list-inside list-disc" key={SingleError}>
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return (
      <div className="m-auto w-1/2">
        <form
          onSubmit={onFormSubmit}
          className="flex flex-col space-y-5 py-10 font-light"
        >
          {selectedService === "ADD_HERO" ? (
            <div className="flex flex-col space-y-3">
              <label className="text-default">Hero Image</label>
              <input
                type="file"
                name="heroImage"
                id="heroImage"
                onChange={onHandleChangeInField}
                className="py-2 px-3 border font-light text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              />
            </div>
          ) : null}
          <div className="flex flex-col space-y-3">
            <label className="text-default">Description</label>
            <textarea
              type="text"
              name="heroDescription"
              id="heroDescription"
              value={this.state.heroDescription}
              placeholder="Description"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            ></textarea>
          </div>
          <div className="flex flex-row space-x-5">
            <div className="flex flex-col space-y-3 w-1/2">
              <label className="text-default">Featured</label>
              <select
                name="heroFeature"
                id="heroFeature"
                placeholder="Is Hero Featured"
                value={this.state.heroFeature}
                onChange={onHandleChangeInField}
                className="py-2 px-3 border font-light text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              >
                <option value="">Choose option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="flex flex-col space-y-3 w-1/2">
              <label className="text-default">Background Position</label>
              <select
                name="shopButtonAlignment"
                id="shopButtonAlignment"
                placeholder="Background Position"
                value={this.state.shopButtonAlignment}
                onChange={onHandleChangeInField}
                className="py-2 px-3 border font-light text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              >
                <option value="">Choose background position</option>
                <option value="center">Center</option>
                <option value="left">Left</option>
                <option value="right">Right</option>
                <option value="top">Top</option>
                <option value="bottom">Bottom</option>
              </select>
            </div>
          </div>

          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="bg-gray-100 px-3 py-2">
              <p className="text-default">{returnedMessage}</p>
            </div>
          ) : null}
          <button
            className="bg-default text-white border py-2 rounded-lg hover:bg-transparent hover:text-default hover:border-default"
            type="submit"
          >
            {isLoading ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.hero.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createHero: (values) => dispatch(createHeroImage(values)),
    editHero: (values, id) => dispatch(editHero(values, id)),
    resetLoader: () => dispatch(resetLoader()),
    resetHero: () => dispatch(resetHero()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateHeroImage);
