import React, { useState, useEffect } from "react";

// External imports
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import SyncLoader from "react-spinners/SyncLoader";
import clip from "text-clipper";

// Component import
import Nav from "../../shared/Nav";
import Footer from "../../shared/Footer";
import Pagination from "../../shared/Pagination";
import { apiInstance } from "../../../api/api";

export default function Root() {
  const [isPageLoading, setPageLoading] = useState(true);
  const [toggleFiltering, setToggleFiltering] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(4);

  // DEMO FETCH
  useEffect(() => {
    async function fetchingBlog() {
      setPageLoading(true);
      await apiInstance
        .get("posts")
        .then((response) => setBlogList(response.data.posts))
        .catch((error) => console.log(error));
      setPageLoading(false);
    }

    fetchingBlog();
  }, []);

  // Pagination
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = blogList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return !isPageLoading ? (
    <div>
      <Nav />
      <Content
        blogItems={currentPosts}
        filteredBlog={filteredBlog}
        toggleFiltering={toggleFiltering}
      />
      <Pagination
        postsPerPage={postPerPage}
        totalPost={blogList.length}
        paginate={paginate}
        currentPage={currentPage}
      />
      <Footer />
    </div>
  ) : (
    <div className="min-h-screen w-screen flex flex-col items-center justify-center">
      <SyncLoader color="#313131" />
    </div>
  );
}

const Content = ({ blogItems, filteredBlog, toggleFiltering }) => {
  let displayBlog = [];
  toggleFiltering ? (displayBlog = filteredBlog) : (displayBlog = blogItems);
  // Item mapping
  const blogItemsMapping =
    displayBlog && displayBlog.length > 0 ? (
      displayBlog.map((SingleItem) => {
        //   Text clipper
        const clipBlogDescription = clip(SingleItem.body, 100, {
          html: true,
          maxLines: 8,
        });
        return (
          <a
            href={SingleItem.url}
            target="_blank"
            rel="noreferrer"
            className="w-1/4 px-1 mb-5"
            key={SingleItem.id}
            class="rounded overflow-hidden shadow-lg flex-initial border bg-white sm:mx-2 md:mx-1 lg:mx-2 w-full sm:w-1/5 lg:pt-0 mb-10 flex flex-col"
          >
            <img
              src={SingleItem.image ? SingleItem.image.public_url : ""}
              alt={SingleItem.name}
              class="w-full object-cover h-32 sm:h-48 md:h-64"
            />
            <div class="p-4 md:p-6 bg-white flex flex-col flex-1 justify-between space-y-3">
              <div>
                <p className="text-xs text-gray-300">{"Admin"}</p>
                <p className="text-xl font-light mt-2">{SingleItem.title}</p>
                <p className="text-xl font-light mx-3 text-center">
                  {SingleItem.name}{" "}
                </p>
              </div>

              <div className="flex flex-row justify-center items-center space-x-3 w-full">
                <p className="text-default font-light">{clipBlogDescription}</p>
              </div>
              <div class="text-sm flex items-center">
                <p className="text-sm text-gray-400 mx-0 font-light text-center">
                  <a
                    href={SingleItem.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-default text-base mb-4 flex flex-row space-x-3 items-center font-light"
                  >
                    <p>Read more</p>
                    <BsArrowRight size="25" />
                  </a>
                </p>
              </div>
            </div>
          </a>
        );
      })
    ) : (
      <div className="m-auto flex flex-col justify-center items-center">
        <p className="text-center font-light">No Media Found !</p>
      </div>
    );
  return (
    <div className="px-5 lg:px-20 pt-32 ">
      <h2 className="text-3xl font-light mb-8">Our Media</h2>
      <div class="container mx-auto">
        <div class="flex flex-col sm:flex-row justify-start mx-4 md:mx-0 lg:-mx-2 flex-wrap">
          {blogItemsMapping}
        </div>
      </div>
      {/* <div className="flex flex-row flex-wrap my-8 pt-24">
        {blogItemsMapping}
      </div> */}
    </div>
  );
};
