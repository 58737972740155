import {
  ADD_MEDIA_SUCCESS,
  ADD_MEDIA_FAILED,
  EDIT_MEDIA_SUCCESS,
  EDIT_MEDIA_FAILED,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAILED,
  RESET_MEDIA,
} from "./Type";
import { startLoader, stopLoader } from "./ApplicationAction";
import { apiInstance } from "../api/api";

export const createMedia = (values) => {
  return (dispatch) => {
    dispatch(startLoader());
    // Operation
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("posts", values)
        .then((response) => {
          if (response.status === 201) {
            dispatch(stopLoader());
            dispatch({
              type: ADD_MEDIA_SUCCESS,
              payload: "Media added successfully",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: ADD_MEDIA_FAILED,
              payload:
                "Failed to add media. Potential cause of failure is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: ADD_MEDIA_FAILED,
            payload:
              "Failed to add media. Possible cause of failure is: " + error,
          });
        });
    });
  };
};

export const editMedia = (values, id) => {
  return (dispatch) => {
    dispatch(startLoader());
    // OPeration
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("posts/" + id, values)
        .then((response) => {
          if (response.status === 200) {
            dispatch(stopLoader());
            dispatch({
              type: EDIT_MEDIA_SUCCESS,
              payload: "Media updated successfully",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: EDIT_MEDIA_FAILED,
              payload:
                "Failed to update media. Potential cause of failure is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: EDIT_MEDIA_FAILED,
            payload:
              "Failed to update media. Possible cause of failure is: " + error,
          });
        });
    });
  };
};

export const deleteMedia = (id) => {
  return (dispatch) => {
    // Operation
    apiInstance
      .delete("posts/" + id)
      .then((response) => {
        if (response.status === 200)
          dispatch({
            type: DELETE_MEDIA_SUCCESS,
            payload: "Media deleted successfully",
          });
        else
          dispatch({
            type: DELETE_MEDIA_FAILED,
            payload:
              "Failed to delete media. Potential cause of error is internet connectivity or bad request",
          });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_MEDIA_FAILED,
          payload: "Failed to delete media. Potential cause of error: " + error,
        });
        console.log(error);
      });
  };
};

export const resetMedia = () => {
  return (dispatch) => {
    dispatch({ type: RESET_MEDIA });
  };
};
