import React, { useState, useEffect, Component } from "react";

// External imports
import { FiHeart } from "react-icons/fi";
import { BsCheckCircle } from "react-icons/bs";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import parse from "html-react-parser";
import SyncLoader from "react-spinners/SyncLoader";
import clip from "text-clipper";
import ZoomableImage from "react-zoomable-img";

// Component imports
import Nav from "../../shared/Nav";
import Footer from "../../shared/Footer";
import { validateEmptyString } from "../../shared/Validation";
import {
  addToCart,
  addWishlist,
  getAllCart,
} from "../../../actions/OrderMgtAction";
import { resetLoader } from "../../../actions/ApplicationAction";
import { numberFormat } from "../../shared/Variables";
import { apiInstance } from "../../../api/api";

import { toast } from "react-toastify";

function SpecificProduct(props) {
  const [getSpecificProduct, setSpecificProduct] = useState([]);
  const [getSimilarProducts, setSimilarProducts] = useState([]);

  const selectedProductID = props.location.state.selectedProductID;
  const addToCart = props.addToCart;
  const addWishlist = props.addWishlist;
  const getAllCart = props.getAllCart;
  const cartFromPage = props.cart;
  const resetLoader = props.resetLoader;
  const isLoading = props.isLoading;

  useEffect(() => {
    async function fetchSpecificProduct() {
      await apiInstance
        .get("products/" + selectedProductID)
        .then((response) => {
          setSpecificProduct(response.data.product);
          setSimilarProducts(response.data.similar_products);
        })
        .catch((error) => console.log(error));
    }

    fetchSpecificProduct();

    return resetLoader();
  }, [selectedProductID]);
  return (
    <div className="pt-24">
      <Nav cartFromPage={cartFromPage} />
      <Content
        getSpecificProduct={getSpecificProduct}
        getSimilarProducts={getSimilarProducts}
        addToCart={addToCart}
        addWishlist={addWishlist}
        getAllCart={getAllCart}
        isLoading={isLoading}
      />
      <Footer />
    </div>
  );
}

const Content = ({
  getSpecificProduct,
  addToCart,
  addWishlist,
  getSimilarProducts,
  isLoading,
}) => {
  return (
    <div className="flex flex-col px-5 lg:px-20 mb-10 lg:my-20">
      <TopContent
        getSpecificProduct={getSpecificProduct}
        addToCart={addToCart}
        addWishlist={addWishlist}
        getAllCart={getAllCart}
        isLoading={isLoading}
      />
      <BottomContent getSimilarProducts={getSimilarProducts} />
    </div>
  );
};

class TopContent extends Component {
  state = {
    choosenSize: "",
    choosenColor: "",
    choosenQuantity: "",

    findVariants: [],
    fetchingError: [],
    availableStock: "",
    toggleAvailabilityIndicator: false,
    toggleErrorFound: false,
    fetchingVariants: false,
    leadingClicked: "",
    variationImageClicked: "",
  };

  render() {
    const getSpecificProduct = this.props.getSpecificProduct;
    const addToCart = this.props.addToCart;
    const addWishlist = this.props.addWishlist;
    const isLoading = this.props.isLoading;

    const onHandleChange = async (e) => {
      this.setState({ [e.target.name]: e.target.value }, async () => {
        // Mapping states
        let { choosenSize, choosenColor } = this.state;

        // Checking if color is filled
        if (choosenColor !== "" && choosenSize !== "") {
          this.setState({ fetchingVariants: true });
          await apiInstance
            .get(
              "products/" +
                getSpecificProduct.id +
                "/variants?color_id=" +
                parseInt(choosenColor) +
                "&size_id=" +
                parseInt(choosenSize)
            )
            .then((response) =>
              this.setState(
                {
                  findVariants: response.data.variant,
                  availableStock: response.data.variant.available_stock,
                  toggleAvailabilityIndicator: true,
                  toggleErrorFound: false,
                },
                () => console.log(this.state)
              )
            )
            .catch((error) =>
              this.setState({
                findVariants: [],
                toggleErrorFound: true,
                toggleAvailabilityIndicator: true,
              })
            );
          this.setState({ fetchingVariants: false });
        } else {
          this.setState({
            toggleAvailabilityIndicator: false,
            findVariants: [],
            toggleErrorFound: false,
          });
        }
      });
    };
    const onFormSubmit = async (e) => {
      e.preventDefault();
      const {
        choosenSize,
        choosenColor,
        choosenQuantity,
        availableStock,
        findVariants,
      } = this.state;
      if (
        !validateEmptyString(choosenSize) ||
        !validateEmptyString(choosenColor) ||
        !validateEmptyString(choosenQuantity)
      )
        return toast.dark(
          "One or more fields are missing. Please fill and try again",
          { position: "top-center" }
        );
      if (parseInt(choosenQuantity) > parseInt(availableStock))
        return toast.dark(
          "Chosen Quantity is greater than the available stock."
        );

      this.setState({ fetchingVariants: true }, async () => {
        await addToCart(findVariants.id, parseInt(choosenQuantity));
        // await getAllCart();
        this.setState({ fetchingVariants: false });
      });
    };

    const mappingSize =
      getSpecificProduct.sizes && getSpecificProduct.sizes.length > 0
        ? getSpecificProduct.sizes.map((SingleSize) => (
            <option value={SingleSize.id} key={SingleSize.id} id="choosenSize">
              {SingleSize.size}
            </option>
          ))
        : null;
    const mappingColor =
      getSpecificProduct.colors && getSpecificProduct.colors.length > 0
        ? getSpecificProduct.colors.map((SingleColor) => (
            <option
              value={SingleColor.id}
              key={SingleColor.id}
              id="choosenColor"
            >
              {SingleColor.color}
            </option>
          ))
        : null;

    const mappingLeadingImage = this.state.findVariants.images
      ? this.state.findVariants.images.map((SingleImage) =>
          SingleImage.is_leading ? (
            <ZoomableImage
              src={SingleImage.public_url}
              alt={getSpecificProduct.name}
              key={SingleImage.id}
              zoomScale={3}
              transitionDuration={0.5}
              className="w-full"
            />
          ) : null
        )
      : null;

    const mappingSideImage = this.state.findVariants.images
      ? this.state.findVariants.images.map((SingleImage) => (
          <img
            src={SingleImage.public_url}
            alt={getSpecificProduct.name}
            key={SingleImage.id + 1}
            onClick={() =>
              this.setState({ variationImageClicked: SingleImage.public_url })
            }
            // className="w-full"
          />
        ))
      : null;

    const mappingProductFeaturedImages =
      getSpecificProduct.images && getSpecificProduct.images.length > 0
        ? getSpecificProduct.images.map((SingleFeaturedImage) => (
            <img
              src={SingleFeaturedImage.public_url}
              alt={SingleFeaturedImage.name}
              key={SingleFeaturedImage.id + 1}
              onClick={() =>
                this.setState({
                  leadingClicked: SingleFeaturedImage.public_url,
                })
              }
              // className="w-full"
            />
          ))
        : null;

    return (
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 flex flex-row space-x-5">
          {this.state.toggleAvailabilityIndicator ? (
            !this.state.toggleErrorFound ? (
              <div className="flex flex-col space-y-5 w-1/4 lg:w-1/6 h-3/4 overflow-y-auto">
                {mappingSideImage}
              </div>
            ) : null
          ) : (
            <div className="flex flex-col space-y-5 w-1/5 lg:w-1/6 h-3/4 overflow-y-auto">
              {mappingProductFeaturedImages}
            </div>
          )}

          <div className="w-2/3 lg:w-2/3 border">
            {this.state.toggleAvailabilityIndicator ? (
              !this.state.toggleErrorFound ? (
                this.state.variationImageClicked !== "" ? (
                  <ZoomableImage
                    src={this.state.variationImageClicked}
                    zoomScale={3}
                    transitionDuration={0.5}
                    alt={getSpecificProduct.name}
                    className="w-full"
                  />
                ) : (
                  mappingLeadingImage
                )
              ) : (
                <p className="flex flex-row justify-center items-center m-auto w-full h-full font-light">
                  No Product Image Available For this Variation
                </p>
              )
            ) : (
              <ZoomableImage
                className="w-full"
                transitionDuration={0.5}
                zoomScale={3}
                src={
                  this.state.leadingClicked !== ""
                    ? this.state.leadingClicked
                    : getSpecificProduct.leading_image
                }
              />
            )}
          </div>
        </div>
        {/* Form */}
        <div className="w-full lg:w-1/2 flex flex-col space-y-7">
          <div className="flex flex-col space-y-2 text-4xl lg:text-5xl font-light text-default mt-8 lg:mt-0">
            <p className="">{getSpecificProduct.name}</p>
            <p className="text-3xl">${getSpecificProduct.price}</p>
          </div>
          <form
            className="flex flex-col lg:flex-row space-y-5 lg:space-x-5 lg:space-y-0 text-sm font-light"
            onSubmit={onFormSubmit}
          >
            <select
              className="py-3 px-3 border border-gray-400 bg-transparent w-full lg:w-1/6 font-light"
              name="choosenSize"
              onChange={onHandleChange}
            >
              <option value="">Size</option>
              {mappingSize}
            </select>
            <select
              className="py-3 px-3 border border-gray-400 bg-transparent w-full lg:w-1/6 font-light"
              name="choosenColor"
              onChange={onHandleChange}
            >
              <option value="">Color</option>
              {mappingColor}
            </select>
            <input
              className="py-3 px-3 border border-gray-400 bg-transparent w-full lg:w-1/6 font-light"
              type="number"
              name="choosenQuantity"
              max={this.state.availableStock}
              min={this.state.availableStock <= 0 ? 0 : 1}
              placeholder="Quantity"
              disabled={
                this.state.toggleAvailabilityIndicator
                  ? !this.state.toggleErrorFound &&
                    this.state.availableStock > 0
                    ? false
                    : true
                  : true
              }
              onChange={onHandleChange}
            />
            {this.state.fetchingVariants || isLoading ? (
              <SyncLoader color="#313131" size="8px" />
            ) : (
              <button
                className="bg-default text-white border px-8 py-2 rounded-lg hover:bg-transparent hover:text-default hover:border-default uppercase"
                disabled={
                  !this.state.toggleErrorFound && this.state.availableStock > 0
                    ? false
                    : true
                }
              >
                add to cart
              </button>
            )}
          </form>

          <div className="flex flex-row space-x-20 lg:space-x-64 font-light">
            <button
              className="text-default text-right flex flex-row space-x-3 items-center font-light focus:outline-none"
              onClick={async () => await addWishlist(getSpecificProduct.id)}
            >
              <FiHeart className="" /> <p>Add to wishlist</p>
            </button>
            {this.state.toggleAvailabilityIndicator ? (
              !this.state.toggleErrorFound && this.state.availableStock > 0 ? (
                <div className="flex flex-row space-x-3 items-center">
                  <BsCheckCircle />
                  <p>Available</p>
                </div>
              ) : (
                <div className="flex flex-row space-x-3 items-center">
                  <IoCloseCircleOutline />
                  <p>Not Available</p>
                </div>
              )
            ) : null}
          </div>

          <div className="text-default flex flex-col space-y-3 font-light indexed-ul">
            <p className="uppercase text-xl font-bold">Description</p>
            <div>{getSpecificProduct.description}</div>
          </div>
          <div className="text-default flex flex-col space-y-3 font-light indexed-ul">
            <p className="uppercase text-xl font-bold">Details</p>
            <div>{parse(`${getSpecificProduct.details}`)}</div>
          </div>
        </div>
      </div>
    );
  }
}

const BottomContent = ({ getSimilarProducts }) => {
  const mappingSimilarProducts =
    getSimilarProducts !== null && getSimilarProducts.length > 0 ? (
      getSimilarProducts.map((SingleElement) => {
        const clipProductDescription = clip(SingleElement.description, 100, {
          html: true,
          maxLines: 8,
        });
        return (
          <div class="rounded overflow-hidden shadow-lg flex-shrink bg-white sm:mx-2 md:mx-1 lg:mx-2 w-full sm:w-1/5 lg:pt-0 mb-10 flex flex-col">
            <Link
              to={{
                pathname: "/shop/" + SingleElement.id,
                state: { selectedProductID: SingleElement.id },
              }}
            >
              <img
                src={SingleElement.leading_image}
                alt={SingleElement.name}
                class="w-full object-cover h-32 sm:h-48 md:h-64"
              />
            </Link>
            <div class="p-4 md:p-6 bg-white flex flex-col flex-1 space-y-3">
              <p className="text-xl font-light mx-3 text-center">
                {SingleElement.name}{" "}
              </p>
              <div className="flex flex-row justify-center items-center space-x-3 w-full px-3">
                <p className="text-xl font-light leading-6">
                  {"$ " + numberFormat.format(SingleElement.price)}
                </p>

                <div
                  className="bg-gray-100 rounded-full p-2 shadow-md cursor-pointer"
                  onClick={async () => await addWishlist(SingleElement.id)}
                >
                  <FiHeart size="13" />
                </div>
              </div>
              <div class="text-sm flex items-center">
                <p className="text-sm text-gray-400 mx-0 font-light text-center">
                  {clipProductDescription}{" "}
                </p>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="m-auto font-light text-center">
        No Similar Product Found
      </div>
    );
  return (
    <div className="my-20 flex flex-col space-y-7">
      <p className="text-4xl font-light text-default">Matching with . . .</p>
      <div class="container mx-auto">
        <div class="flex flex-col sm:flex-row justify-start mx-4 md:mx-0 lg:-mx-2 flex-wrap">
          {mappingSimilarProducts}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.order.cart,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (variationId, productQuantity) =>
      dispatch(addToCart(variationId, productQuantity)),
    addWishlist: (productId) => dispatch(addWishlist(productId)),
    getAllCart: () => dispatch(getAllCart()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecificProduct);
