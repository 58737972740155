import React, { useState, useEffect } from "react";

// External imports
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { SiVisa, SiAmericanexpress } from "react-icons/si";
import { FaCcMastercard } from "react-icons/fa";
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";
import { toast } from "react-toastify";

// Component imports
import { orderProduct, resetOrderState } from "../../../actions/OrderMgtAction";
import { resetLoader } from "../../../actions/ApplicationAction";

function PaymentFormComponent(props) {
  const [getErrorMessage, setErrorMessage] = useState([]);
  const [isStateFetching, setIsStateFetching] = useState(false);

  const elements = useElements();
  const stripe = useStripe();

  const {
    billingInformation,
    shippingInformation,
    shoppingCartElements,
    isShippingChecked,
    updatePaymentInformation,
    backToPrevious,
    orderProduct,
    updatePage,
    resetLoader,
    loaderState,
  } = props;

  useEffect(() => {
    return () => {
      resetLoader();
      resetOrderState();
    };
  });

  // if (returnedMessage && returnedMessage.type === "SUCCESS") updatePage();

  const errorMessageMapping =
    getErrorMessage && getErrorMessage.length > 0
      ? getErrorMessage.map((SingleError) => {
          return (
            <ul className="list-inside list-disc" id={SingleError}>
              <li className="font-light">{SingleError}</li>
            </ul>
          );
        })
      : null;

  const onFormSubmit = async (e) => {
    e.preventDefault();

    setIsStateFetching(true);
    const paymentID = await processPayment(billingInformation);
    await updatePaymentInformation(paymentID);
    await orderProduct(
      billingInformation,
      paymentID,
      isShippingChecked,
      shippingInformation,
      shoppingCartElements
    );

    setIsStateFetching(false);
  };

  const processPayment = async (billing_details) => {
    setErrorMessage([]);

    const { error, paymentMethod } = await stripe.createPaymentMethod(
      "card",
      elements.getElement(CardElement),
      { billing_details }
    );

    if (!error) {
      const { id } = paymentMethod;
      return id;
      // eslint-disable-next-line no-const-assign
    } else toast.dark(error, { position: "top-center" });
  };

  return (
    <div>
      <BillingShippingInformationHighlight
        billingInformation={billingInformation}
        shippingInformation={shippingInformation}
        backToPrevious={backToPrevious}
      />
      <div className="flex flex-row justify-between items-center">
        <p className="text-default font-light text-xl pb-5">Payment</p>
        <div className="flex flex-row items-center space-x-5">
          <SiVisa size="35px" />
          <FaCcMastercard size="20px" />
          <SiAmericanexpress size="20px" />
        </div>
      </div>
      <form onSubmit={onFormSubmit}>
        <CardElement className="py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default" />
        <div>{errorMessageMapping}</div>

        <div className="flex justify-between mt-10">
          <button
            className="bg-gray-400 text-white py-2 px-5 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
            onClick={() => backToPrevious()}
            disabled={isStateFetching ? true : false}
          >
            {isStateFetching || loaderState ? (
              <SyncLoader color="#FFF" size="8px" />
            ) : (
              "Back"
            )}
          </button>
          <button
            className="bg-default text-white py-2 px-5 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
            type="submit"
            disabled={isStateFetching || loaderState ? true : false}
          >
            {isStateFetching || loaderState ? (
              <SyncLoader color="#FFF" size="8px" />
            ) : (
              "Complete Payment"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

const BillingShippingInformationHighlight = ({
  billingInformation,
  shippingInformation,
  backToPrevious,
}) => {
  return (
    <div className="border rounded-lg px-5 py-5 flex flex-col space-y-2 mb-10">
      <div className="flex flex-row justify-between items-center space-x-16 border-b pb-4">
        <div className="flex flex-row items-center space-x-16">
          <p className="font-bold text-gray-400">Contact</p>
          <p className="font-light">{billingInformation.email}</p>
        </div>
        <button
          className="uppercase text-xs text-gray-400 hover:text-black mr-auto"
          onClick={() => backToPrevious()}
        >
          Update
        </button>
      </div>
      <div className="flex flex-row justify-between items-center space-x-16 border-b pb-4">
        <div className="flex flex-row items-center space-x-16">
          <p className="font-bold text-gray-400">Address</p>
          <p className="font-light">
            {billingInformation.address
              ? billingInformation.address.line1 +
                ", " +
                billingInformation.address.city +
                ", " +
                billingInformation.address.country
              : "----"}
          </p>
        </div>
        <button
          className="uppercase text-xs text-gray-400 hover:text-black mr-auto"
          onClick={() => backToPrevious()}
        >
          Update
        </button>
      </div>
      <div className="flex flex-row justify-between items-center space-x-16">
        <div className="flex flex-row items-center space-x-16">
          <p className="font-bold text-gray-400">Shipped</p>
          <p className="font-light">
            {shippingInformation
              ? shippingInformation.shipping_address +
                ", " +
                shippingInformation.shipping_city +
                ", " +
                shippingInformation.shipping_country
              : "----"}
          </p>
        </div>
        <button
          className="uppercase text-xs text-gray-400 hover:text-black mr-auto"
          onClick={() => backToPrevious()}
        >
          Update
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.order.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderProduct: (
      billing_details,
      paymentID,
      isShippingSame,
      shipping_details,
      productInformation
    ) =>
      dispatch(
        orderProduct(
          billing_details,
          paymentID,
          isShippingSame,
          shipping_details,
          productInformation
        )
      ),
    resetLoader: () => dispatch(resetLoader()),
    resetOrderState: () => dispatch(resetOrderState()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentFormComponent);
