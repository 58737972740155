import React, { useState, Component } from "react";

// External imports
import { Link, Redirect } from "react-router-dom";
import { BiLeftArrowAlt } from "react-icons/bi";
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";

// Component import
import { validateEmptyString, validateEmail } from "../../../shared/Validation";
import { login, resetState } from "../../../../actions/UserMgtAction";
import { resetLoader } from "../../../../actions/ApplicationAction";

class Root extends Component {
  componentWillUnmount() {
    this.props.resetState();
    this.props.resetLoader();
  }
  render() {
    const { login, returnedMessage, isLoggedIn, loaderState } = this.props;

    if (localStorage.getItem("login_user_id") !== null || isLoggedIn) {
      // const user_id = localStorage.getItem("login_user_id");
      const user_role = localStorage.getItem("login_user_role");
      if (user_role.toLowerCase() === "admin") {
        return <Redirect to="/dashboard" />;
      }
    }

    return (
      <div className="flex flex-row min-h-screen">
        <div className="">
          <img
            src={
              "https://images.pexels.com/photos/5771896/pexels-photo-5771896.jpeg?cs=srgb&dl=pexels-jake-%22sulli%22-swoyer-5771896.jpg&fm=jpg"
            }
            alt="Fashion Model - Kunjina Fashion"
            className="h-screen"
          />
        </div>
        <div className="w-1/3 flex flex-col justify-between px-7 py-10 ml-20">
          <Link to={"/"} className="flex flex-row space-x-4 items-center">
            <BiLeftArrowAlt size="20" className="text-gray-400" />
            <p className="font-bold text-gray-400">Back</p>
          </Link>
          <div className="flex flex-col space-y-10">
            <p className="text-default text-2xl font-light">
              Login <hr className="mt-2 w-1/3" />
            </p>

            <LoginForm
              login={login}
              returnedMessage={returnedMessage}
              isLoading={loaderState}
            />
          </div>
          <div className="font-light text-center">Powered by Lazares</div>
        </div>
      </div>
    );
  }
}

// Login form
function LoginForm({ login, returnedMessage, isLoading }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [obscureText, setObscureText] = useState(true);
  // const [isFormSubmitLoading, setFormSubmitLoading] = useState(false);

  // on Form submit
  const onFormSubmit = async (e) => {
    e.preventDefault();

    let singleErrorMessage = [];

    if (!validateEmptyString(username)) {
      singleErrorMessage.push("Username is required");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmail(username)) {
      singleErrorMessage.push("Email is in a wrong format");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmptyString(password)) {
      singleErrorMessage.push("Password is required");
      return setErrorMessage(singleErrorMessage);
    }

    // await setFormSubmitLoading(true);
    // Perform login dispatch to the redux action
    await login(username, password);
  };

  const mappingErrorMessage =
    errorMessage && errorMessage.length > 0
      ? errorMessage.map((SingleError) => {
          return (
            <ul className="list-inside list-disc">
              <li className="font-light">{SingleError}</li>
            </ul>
          );
        })
      : null;

  return (
    <div>
      <form onSubmit={onFormSubmit} className="flex flex-col space-y-5">
        <input
          className="px-3 py-2 bg-transparent border border-gray-300 placeholder-gray-400 w-full focus: outline-none"
          placeholder="Username"
          name="username"
          id="username"
          type="text"
          onChange={(e) => setUsername(e.target.value)}
        />
        <div className="flex flex-col relative">
          <input
            className="px-3 py-2 bg-transparent border border-gray-300 placeholder-gray-400 w-full focus: outline-none"
            placeholder="Password"
            name="password"
            id="password"
            type={obscureText ? "password" : "text"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="absolute inset-y-0 right-3 flex flex-row items-center pl-3">
            {obscureText ? (
              <button
                className="p-1 focus:outline-none focus:shadow-outline text-default opacity-50"
                onClick={(e) => {
                  e.preventDefault();
                  setObscureText(!obscureText);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M12.01 20c-5.065 0-9.586-4.211-12.01-8.424 2.418-4.103 6.943-7.576 12.01-7.576 5.135 0 9.635 3.453 11.999 7.564-2.241 4.43-6.726 8.436-11.999 8.436zm-10.842-8.416c.843 1.331 5.018 7.416 10.842 7.416 6.305 0 10.112-6.103 10.851-7.405-.772-1.198-4.606-6.595-10.851-6.595-6.116 0-10.025 5.355-10.842 6.584zm10.832-4.584c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm0 1c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4z" />
                </svg>
              </button>
            ) : (
              <button
                className="p-1 focus:outline-none focus:shadow-outline text-default opacity-50"
                onClick={(e) => {
                  e.preventDefault();
                  setObscureText(!obscureText);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M8.137 15.147c-.71-.857-1.146-1.947-1.146-3.147 0-2.76 2.241-5 5-5 1.201 0 2.291.435 3.148 1.145l1.897-1.897c-1.441-.738-3.122-1.248-5.035-1.248-6.115 0-10.025 5.355-10.842 6.584.529.834 2.379 3.527 5.113 5.428l1.865-1.865zm6.294-6.294c-.673-.53-1.515-.853-2.44-.853-2.207 0-4 1.792-4 4 0 .923.324 1.765.854 2.439l5.586-5.586zm7.56-6.146l-19.292 19.293-.708-.707 3.548-3.548c-2.298-1.612-4.234-3.885-5.548-6.169 2.418-4.103 6.943-7.576 12.01-7.576 2.065 0 4.021.566 5.782 1.501l3.501-3.501.707.707zm-2.465 3.879l-.734.734c2.236 1.619 3.628 3.604 4.061 4.274-.739 1.303-4.546 7.406-10.852 7.406-1.425 0-2.749-.368-3.951-.938l-.748.748c1.475.742 3.057 1.19 4.699 1.19 5.274 0 9.758-4.006 11.999-8.436-1.087-1.891-2.63-3.637-4.474-4.978zm-3.535 5.414c0-.554-.113-1.082-.317-1.562l.734-.734c.361.69.583 1.464.583 2.296 0 2.759-2.24 5-5 5-.832 0-1.604-.223-2.295-.583l.734-.735c.48.204 1.007.318 1.561.318 2.208 0 4-1.792 4-4z" />
                </svg>
              </button>
            )}
          </span>
        </div>
        <div>{mappingErrorMessage}</div>
        {returnedMessage !== "" ? (
          <div className="bg-gray-100 px-3 py-2">
            <p className="text-default">{returnedMessage}</p>
          </div>
        ) : null}
        <button className="px-3 py-2 text-white bg-default border hover:bg-transparent hover:text-default hover:border-default">
          {isLoading ? <SyncLoader color="#FFF" size="8px" /> : "Login"}
        </button>
        <button
          className="font-light text-default"
          onClick={(e) => e.preventDefault()}
        >
          Forgot password ? Click Here
        </button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.user.returnedMessage,
    isLoggedIn: state.user.isLoggedIn,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    resetState: () => dispatch(resetState()),
    resetLoader: () => dispatch(resetLoader()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Root);
