import React, { Component, useEffect, useState } from "react";

// Component imports
import SideNav from "../../../shared/SideNav";
import { validateEmptyString } from "../../../shared/Validation";
import { apiInstance } from "../../../../api/api";
import {
  createProduct,
  editProduct,
  resetProductState,
} from "../../../../actions/ProductMgtAction";
import { resetLoader } from "../../../../actions/ApplicationAction";

// External imports
import { FaLongArrowAltLeft } from "react-icons/fa";
import { HiPlusSm, HiMinusSm } from "react-icons/hi";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SyncLoader from "react-spinners/SyncLoader";
import Select from "react-select";

function CreateProduct(props) {
  const selectedService = props.location.state.selectedService;
  const selectedProduct = props.location.state.selectedProduct;
  const createProduct = props.createProduct;
  const editProduct = props.editProduct;
  const resetProductState = props.resetProductState;
  const returnedMessage = props.returnedMessage;
  const resetLoader = props.resetLoader;
  const isLoading = props.loaderState;

  const [getAllSizes, setAllSizes] = useState([]);
  const [getAllColors, setAllColors] = useState([]);

  useEffect(() => {
    async function fetchVariations() {
      await apiInstance
        .get("sizes")
        .then((response) => setAllSizes(response.data.sizes))
        .catch((error) => console.log(error));

      await apiInstance
        .get("colors")
        .then((response) => setAllColors(response.data.colors))
        .catch((error) => console.log(error));
    }

    fetchVariations();
  }, []);

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <div className="px-10 w-full">
        <div className="flex flex-row space-x-5 items-center">
          <Link to={"/products"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Product Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-default">
              {selectedService === "ADD_PRODUCT" &&
              typeof selectedService !== "undefined"
                ? "Add Product"
                : selectedService === "EDIT_PRODUCT" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedProduct !== "undefined"
                ? "Edit Product"
                : ""}
            </p>
            <p className="font-thin text-sm">
              {selectedService === "ADD_PRODUCT" &&
              typeof selectedService !== "undefined"
                ? "Create and add new product to store"
                : selectedService === "EDIT_PRODUCT" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedProduct !== "undefined"
                ? "Edit product information on your store"
                : ""}
            </p>
          </div>
        </div>

        {/* Blog form */}
        <ProductForm
          selectedService={selectedService}
          selectedProduct={selectedProduct}
          createProduct={createProduct}
          editProduct={editProduct}
          resetProductState={resetProductState}
          returnedMessage={returnedMessage}
          resetLoader={resetLoader}
          isLoading={isLoading}
          getAllSizes={getAllSizes}
          getAllColors={getAllColors}
        />
      </div>
    </div>
  );
}

class ProductForm extends Component {
  state = {
    name:
      this.props.selectedService === "ADD_PRODUCT"
        ? ""
        : this.props.selectedProduct.name,
    price:
      this.props.selectedService === "ADD_PRODUCT"
        ? ""
        : this.props.selectedProduct.price,
    description:
      this.props.selectedService === "ADD_PRODUCT"
        ? ""
        : this.props.selectedProduct.description,
    details:
      this.props.selectedService === "ADD_PRODUCT"
        ? ""
        : this.props.selectedProduct.details,
    leadingImage: "",
    leadingSideImage: [],
    category:
      this.props.selectedService === "ADD_PRODUCT"
        ? []
        : this.props.selectedProduct.categories.map((SingleCategory) =>
            parseInt(SingleCategory.id)
          ),
    variation: [{ size: "", color: "", leadingImage: "", image: [], stock: 0 }],
    errorMessage: [],

    getAllCategories: [],
    stateLoading: false,

    imageRef: React.createRef(),
    selectOptions: [],
  };

  componentWillUnmount() {
    this.props.resetProductState();
    this.props.resetLoader();
  }

  async componentDidMount() {
    this.setState({ stateLoading: true });

    await apiInstance
      .get("categories")
      .then((response) => {
        var fetchedOptions = [];
        this.setState({ getAllCategories: response.data.categories }, () =>
          response.data.categories && response.data.categories.length > 0
            ? response.data.categories.map((SingleCategory) =>
                fetchedOptions.push({
                  value: SingleCategory.id,
                  label: SingleCategory.title,
                })
              )
            : (fetchedOptions = [])
        );
        this.setState({
          selectOptions: fetchedOptions,
        });
      })
      .catch((error) => console.log(error));

    this.setState({ stateLoading: false });
  }

  render() {
    const {
      selectedService,
      selectedProduct,
      getAllColors,
      getAllSizes,
      createProduct,
      editProduct,
      returnedMessage,
      isLoading,
    } = this.props;

    const onFormSubmit = async (e) => {
      e.preventDefault();
      const singleErrorMessage = [];
      const { name, price, description, details, leadingImage, category } =
        this.state;

      if (selectedService === "ADD_PRODUCT") {
        if (
          !validateEmptyString(name) ||
          !validateEmptyString(price) ||
          !validateEmptyString(description) ||
          !validateEmptyString(leadingImage) ||
          !validateEmptyString(category) ||
          !validateEmptyString(details)
        ) {
          singleErrorMessage.push(
            "One or more required fields are missing. Please fill and try again."
          );
          return this.setState({ errorMessage: singleErrorMessage });
        }

        this.setState({ errorMessage: [] });

        await formDataProcessing();

        // this.setState({ stateLoading: false });
      } else if (selectedService === "EDIT_PRODUCT") {
        if (
          !validateEmptyString(name) ||
          !validateEmptyString(price) ||
          !validateEmptyString(description) ||
          !validateEmptyString(category) ||
          !validateEmptyString(details)
        ) {
          singleErrorMessage.push(
            "One or more required fields are missing. Please fill and try again."
          );
          return this.setState({ errorMessage: singleErrorMessage });
        }

        this.setState({ errorMessage: [] });

        console.log(this.state.leadingSideImage);
        console.log(this.state.variation);

        // await formDataProcessing();

        // this.setState({ stateLoading: false });
      }
    };

    const formDataProcessing = async () => {
      let productData = new FormData();
      const leadingPhoto = document.querySelector("#leadingImage");

      this.state.leadingImage =
        typeof leadingPhoto.files[0] === "undefined"
          ? ""
          : leadingPhoto.files[0];

      productData.append("name", this.state.name);
      productData.append("price", this.state.price);
      productData.append("description", this.state.description);
      productData.append("details", this.state.details);
      this.state.category.forEach((singleCategory) => {
        productData.append("categories[]", singleCategory);
      });
      this.state.leadingSideImage.forEach((image) => {
        productData.append("images[]", image);
      });
      if (typeof leadingPhoto.files[0] !== "undefined") {
        productData.append("leading_image", this.state.leadingImage);
      }

      this.setState({ errorMessage: [] });
      if (selectedService === "ADD_PRODUCT") {
        await createProduct(productData, this.state.variation);
      } else {
        productData.append("_method", "PUT");
        await editProduct(productData, selectedProduct.id);
      }
    };

    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    const onHandleProductImage = (e) => {
      const values = [...this.state.leadingSideImage];

      if (e.target.id === "leadingSideImage") {
        let files = e.target.files;

        for (let i = 0; i < files.length; i++) {
          values.push(files[i]);
        }

        this.setState({ leadingSideImage: values });
      }
    };

    // Handling onFileChange
    const onHandleFileInput = (index, e) => {
      const values = [...this.state.variation];

      if (e.target.id === "image") {
        let files = e.target.files;

        for (let i = 0; i < files.length; i++) {
          values[index][e.target.name].push(files[i]);
        }

        this.setState({ variation: values });
      } else {
        values[index][e.target.name] =
          e.target.type === "file" ? e.target.files[0] : e.target.value;
        this.setState({ variation: values });
      }
    };

    // Handle add file field
    const onHandleAddFileField = (e) => {
      e.preventDefault();
      this.setState({
        variation: [
          ...this.state.variation,
          { size: "", color: "", leadingImage: "", image: [], stock: 0 },
        ],
      });
    };

    // Handle remove file field
    const onHandleRemoveFileField = (index) => {
      // e.preventDefault();
      const values = [...this.state.variation];
      values.splice(index, 1);
      this.setState({ variation: values });
    };

    const sizeVariationsMapping =
      getAllSizes && getAllSizes.length > 0
        ? getAllSizes.map((SingleSize) => {
            return (
              <option key={SingleSize.id} value={SingleSize.id}>
                {SingleSize.size}
              </option>
            );
          })
        : null;

    const colorVariationsMapping =
      getAllColors && getAllColors.length > 0
        ? getAllColors.map((SingleColor) => {
            return (
              <option key={SingleColor.id} value={SingleColor.id}>
                {SingleColor.color}
              </option>
            );
          })
        : null;

    //   Error mapping
    const errorMessageMapping =
      this.state.errorMessage && this.state.errorMessage.length > 0
        ? this.state.errorMessage.map((SingleError) => {
            return (
              <ul className="list-inside list-disc" key={SingleError}>
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return (
      <div className="m-auto w-2/3 font-light">
        <form onSubmit={onFormSubmit} className="flex flex-col space-y-5 py-10">
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Product Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={this.state.name}
              placeholder="Product Name"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-row justify-between items-center space-x-5">
            <div className="flex flex-col space-y-3 w-1/2">
              <label className="text-textColor">Product Category</label>
              <Select
                name="category"
                id="category"
                classNamePrefix="select"
                className="basic-multi-select py-2 px-3 font-light border text-textColor rounded-lg focus:outline-none focus:border-default"
                onChange={(value) => {
                  var selected = [];
                  for (var option of value) {
                    selected.push(option.value);
                  }
                  this.setState({ category: selected }, () =>
                    console.log(this.state.category)
                  );
                }}
                isMulti
                options={this.state.selectOptions}
              />
            </div>
            <div className="flex flex-col space-y-3 w-1/2">
              <label className="text-textColor">Price</label>
              <input
                type="number"
                name="price"
                id="price"
                value={this.state.price}
                placeholder="Price"
                onChange={onHandleChangeInField}
                className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              />
            </div>
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Description</label>
            <textarea
              type="text"
              name="description"
              id="description"
              value={this.state.description}
              placeholder="Description"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            ></textarea>
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Details</label>
            <CKEditor
              editor={ClassicEditor}
              id="details"
              name="details"
              onChange={(event, editor) => {
                const data = editor.getData();
                this.setState({ details: data });
              }}
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Leading Product Image</label>
            <input
              type="file"
              name="leadingImage"
              id="leadingImage"
              accept="image/*"
              placeholder="Featured Image"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>

          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Featured Product Images</label>
            <input
              type="file"
              name="leadingSideImage"
              id="leadingSideImage"
              accept="image/*"
              multiple
              placeholder="Featured Image"
              onChange={onHandleProductImage}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>

          {selectedService === "ADD_PRODUCT"
            ? this.state.variation.map((SingleVariation, index) => (
                <div
                  className="flex flex-row justify-between items-center space-x-8"
                  key={index}
                >
                  <div className="flex flex-col space-y-7 pt-7">
                    <div className="flex flex-row justify-start items-center space-x-5">
                      <div className="w-1/2 flex flex-col space-y-3">
                        <label className="text-textColor">Variation Size</label>
                        <select
                          name="size"
                          id="size"
                          value={SingleVariation.size}
                          placeholder="Size"
                          onChange={(e) => onHandleFileInput(index, e)}
                          className="py-2 px-3 w-full font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                          required
                        >
                          <option value="">Select size</option>
                          {sizeVariationsMapping}
                        </select>
                      </div>

                      <div className="w-1/2 flex flex-col space-y-3">
                        <label className="text-textColor">
                          Variation Color
                        </label>
                        <select
                          name="color"
                          id="color"
                          value={SingleVariation.color}
                          placeholder="Color"
                          onChange={(e) => onHandleFileInput(index, e)}
                          className="py-2 px-3 w-full font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                          required
                        >
                          <option value="">Select color</option>
                          {colorVariationsMapping}
                        </select>
                      </div>
                    </div>
                    <div className="flex flex-row justify-start items-center space-x-5">
                      <div className="w-1/2 flex flex-col space-y-3">
                        <label className="text-textColor">
                          Variation Featured Image
                        </label>
                        <input
                          type="file"
                          name="leadingImage"
                          id="leadingImage"
                          placeholder="Leading Image"
                          accept="image/*"
                          onChange={(e) => onHandleFileInput(index, e)}
                          className="py-2 px-3 w-full font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                          required
                        />
                      </div>
                      <div className="w-1/2 flex flex-col space-y-3">
                        <label className="text-textColor">
                          Variation Side Images
                        </label>
                        <input
                          type="file"
                          name="image"
                          id="image"
                          placeholder="Images"
                          multiple
                          accept="image/*"
                          onChange={(e) => onHandleFileInput(index, e)}
                          className="py-2 px-3 w-full font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                          required
                        />
                      </div>
                    </div>
                    <input
                      type="number"
                      name="stock"
                      id="stock"
                      placeholder="Available Stock"
                      multiple
                      accept="image/*"
                      onChange={(e) => onHandleFileInput(index, e)}
                      className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                      required
                    />
                  </div>
                  <div className="flex flex-row space-x-8">
                    <button
                      className="p-2 bg-default rounded-lg"
                      onClick={(e) => onHandleAddFileField(e)}
                    >
                      <HiPlusSm size="30px" color="#FFF" />
                    </button>
                    <button
                      className="p-2 bg-default rounded-lg"
                      onClick={(e) => onHandleRemoveFileField(e)}
                    >
                      <HiMinusSm size="30px" color="#FFF" />
                    </button>
                  </div>
                </div>
              ))
            : null}
          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="bg-gray-100 px-3 py-2">
              <p className="text-default">{returnedMessage}</p>
            </div>
          ) : null}
          <button
            className="bg-default text-white py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
            type="submit"
            disabled={this.state.stateLoading ? true : false}
          >
            {this.state.stateLoading || isLoading ? (
              <SyncLoader color="#FFF" size="8px" />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.product.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createProduct: (values, variationData) =>
      dispatch(createProduct(values, variationData)),
    editProduct: (values, id) => dispatch(editProduct(values, id)),
    resetProductState: () => dispatch(resetProductState()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct);
