import React, { Component } from "react";

// Component imports
import SideNav from "../../../shared/SideNav";
import { apiInstance } from "../../../../api/api";
import { createUser, resetState } from "../../../../actions/UserMgtAction";
import {
  validateEmptyString,
  validateFullNameLength,
  validateEmail,
  validateOnlyLetter,
  validatePasswordConfirmation,
} from "../../../shared/Validation";
import { resetLoader } from "../../../../actions/ApplicationAction";

// External imports
import SyncLoader from "react-spinners/SyncLoader";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function AddUserForm(props) {
  const selectedService = props.location.state.selectedService;
  const selectedUser = props.location.state.selectedUser;
  const createUser = props.createUser;
  const returnedMessage = props.returnedMessage;
  const resetState = props.resetState;
  const resetLoader = props.resetLoader;
  const isLoading = props.loaderState;

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <div className="px-10 w-full">
        <div className="flex flex-row space-x-5 items-center">
          <Link to={"/user"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* User Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-default">
              {selectedService === "ADD_USER" &&
              typeof selectedService !== "undefined"
                ? "Add User"
                : selectedService === "EDIT_USER" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedUser !== "undefined"
                ? "Edit User"
                : selectedService === "CHANGE_PASSWORD" &&
                  typeof selectedService !== "undefined"
                ? "Change Password"
                : null}
            </p>
            <p className="font-thin text-sm">
              {selectedService === "ADD_USER" &&
              typeof selectedService !== "undefined"
                ? "Create and new user to store"
                : selectedService === "EDIT_USER" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedUser !== "undefined"
                ? "Edit user information on your store"
                : selectedService === "CHANGE_PASSWORD" &&
                  typeof selectedService !== "undefined"
                ? "Change Password for existing user"
                : null}
            </p>
          </div>
        </div>

        {/* User form */}
        <UserForm
          selectedService={selectedService}
          selectedUser={selectedUser}
          createUser={createUser}
          resetState={resetState}
          returnedMessage={returnedMessage}
          resetLoader={resetLoader}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

class UserForm extends Component {
  // Component state
  state = {
    userFullName: "",
    userEmail: "",
    userPhoneNumber: "",
    userRole: "",
    userCurrentPassword: "",
    userNewPassword: "",
    userConfirmPassword: "",
    userErrorMessage: [],

    isFormSubmitLoading: false,
    isRoleFetching: false,
    getAllRoles: [],
  };

  // Fetching Role
  async componentDidMount() {
    this.setState({ isRoleFetching: true });
    await apiInstance
      .get("/roles")
      .then((response) => this.setState({ getAllRoles: response.data.roles }))
      .catch((error) => console.log(error));
    this.setState({ isRoleFetching: false });
  }

  componentWillUnmount() {
    this.props.resetState();
    this.props.resetLoader();
  }

  render() {
    // Accepting props
    const { selectedService, createUser, returnedMessage, isLoading } =
      this.props;

    //   onHandleChangeInField
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   onHandleFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();
      const {
        userFullName,
        userEmail,
        userRole,
        userCurrentPassword,
        userNewPassword,
        userConfirmPassword,
      } = this.state;
      const singleErrorMessage = [];

      // Checking the service
      if (selectedService === "ADD_USER") {
        if (
          !validateEmptyString(userFullName) ||
          !validateEmptyString(userEmail) ||
          !validateEmptyString(userNewPassword) ||
          !validateEmptyString(userConfirmPassword) ||
          !validateEmptyString(userRole)
        ) {
          singleErrorMessage.push(
            "One or more required fields are missing. Please fill and try again."
          );
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        if (
          !validatePasswordConfirmation(userNewPassword, userConfirmPassword)
        ) {
          singleErrorMessage.push(
            "Password confirmation failed. Please try to match the password"
          );
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        if (!validateFullNameLength(userFullName)) {
          singleErrorMessage.push(
            "Full name should be less than 50 and greater than 5 characters."
          );
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        if (!validateEmail(userEmail)) {
          singleErrorMessage.push("Email is in a wrong format");
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        if (!validateOnlyLetter(userFullName)) {
          singleErrorMessage.push("Full name is in wrong format");
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        this.setState({ userErrorMessage: [] }, async () => {
          await createUser(this.state);
        });
      } else if (selectedService === "CHANGE_PASSWORD") {
        if (
          !validateEmptyString(userNewPassword) ||
          !validateEmptyString(userConfirmPassword) ||
          !validateEmptyString(userCurrentPassword)
        ) {
          singleErrorMessage.push(
            "One or more required fields are missing. Please fill and try again."
          );
          return this.setState({ userErrorMessage: singleErrorMessage });
        }
        if (
          !validatePasswordConfirmation(userNewPassword, userConfirmPassword)
        ) {
          singleErrorMessage.push(
            "Password confirmation failed. Please try to match the password"
          );
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        this.setState({ isFormSubmitLoading: true }, () => {
          // action
          this.setState({ isFormSubmitLoading: false });
        });
      }
    };

    const roleMapping =
      this.state.getAllRoles && this.state.getAllRoles.length > 0
        ? this.state.getAllRoles.map((SingleRole) => (
            <option value={SingleRole.id} key={SingleRole.id}>
              {SingleRole.title}
            </option>
          ))
        : null;

    const errorMessageMapping =
      this.state.userErrorMessage && this.state.userErrorMessage.length > 0
        ? this.state.userErrorMessage.map((SingleError) => {
            return (
              <ul className="list-inside list-disc" key={SingleError}>
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return selectedService === "ADD_USER" ? (
      <div className="m-auto w-1/2 font-light">
        <form onSubmit={onFormSubmit} className="flex flex-col space-y-5 py-10">
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Full Name</label>
            <input
              type="text"
              name="userFullName"
              id="userFullName"
              placeholder="Full Name"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Email</label>
            <input
              type="text"
              name="userEmail"
              id="userEmail"
              placeholder="Email"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Role</label>
            <select
              type="text"
              name="userRole"
              id="userRole"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            >
              {this.state.isRoleFetching ? (
                <option value="">Loading Roles . . . </option>
              ) : (
                <>
                  <option value="">Select Role</option>
                  {roleMapping}
                </>
              )}
            </select>
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">New Password</label>
            <input
              type="password"
              name="userNewPassword"
              id="userNewPassword"
              placeholder="Password"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Confirm Password</label>
            <input
              type="password"
              name="userConfirmPassword"
              id="userConfirmPassword"
              placeholder="Password"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="bg-gray-100 px-3 py-2">
              <p className="text-default">{returnedMessage}</p>
            </div>
          ) : null}
          <button
            className="bg-default text-white py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
            type="submit"
          >
            {isLoading ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
          </button>
        </form>
      </div>
    ) : (
      <div className="m-auto w-1/2 font-light">
        <form onSubmit={onFormSubmit} className="flex flex-col space-y-5 py-10">
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">New Password</label>
            <input
              type="text"
              name="userConfirmPassword"
              id="userConfirmPassword"
              placeholder="Confirm Password"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Confirm Password</label>
            <input
              type="text"
              name="userConfirmPassword"
              id="userConfirmPassword"
              placeholder="Confirm Password"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div>{errorMessageMapping}</div>
          <button
            className="bg-default text-white py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
            type="submit"
          >
            {isLoading ? <SyncLoader color="#FFF" size="8" /> : "Submit"}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.user.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (values) => dispatch(createUser(values)),
    resetState: () => dispatch(resetState()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserForm);
