import React from "react";

const Pagination = ({ postsPerPage, totalPost, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPost / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  console.log(pageNumbers, postsPerPage, totalPost, currentPage);

  const paginationMapping =
    pageNumbers.length > 0 ? (
      pageNumbers.map((SingleNumber) => {
        return (
          <div
            className={`w-8 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  border-t-2 ${
              currentPage === SingleNumber
                ? "border-black"
                : "border-transparent"
            } ${currentPage === SingleNumber ? "font-bold" : "font-light"}`}
            onClick={() => paginate(SingleNumber)}
          >
            {SingleNumber}
          </div>
        );
      })
    ) : (
      <p className="font-light">No More Pagination</p>
    );
  return (
    <div className="flex flex-col items-center my-12">
      <div className="flex text-gray-700">
        <button
          className={`h-8 w-8 mr-1 flex justify-center items-center  cursor-pointer focus:outline-none`}
          disabled={currentPage === 1 ? true : false}
          onClick={() => paginate(--currentPage)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chevron-left w-4 h-4"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </button>
        <div className="flex h-8 font-medium">{paginationMapping}</div>
        {console.log(pageNumbers.length)}
        <button
          className="h-8 w-8 ml-1 flex justify-center items-center cursor-pointer focus:outline-none"
          disabled={currentPage === pageNumbers.length ? true : false}
          onClick={() => paginate(++currentPage)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chevron-right w-4 h-4"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
