import React, { useState, useEffect, Suspense, lazy } from "react";

// External imports
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";

// Component imports
import Nav from "../../shared/Nav";
import { HeroSection } from "./HeroSlider";
import { PopularProducts } from "./PopularProducts";
import { FindUsOnInstagram } from "./FindOnInstagram";
import { apiInstance } from "../../../api/api";
import { addWishlist } from "../../../actions/OrderMgtAction";
import Footer from "../../shared/Footer";

function Root(props) {
  const [getPopularProducts, setPopularProducts] = useState([]);
  const [getInstagramFeeds, setInstagramFeeds] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  const addWishlist = props.addWishlist;

  // Data fetching
  useEffect(() => {
    async function fetchingLandingData() {
      setStateLoading(true);
      await apiInstance
        .get("popular-products")
        .then((response) => {
          setPopularProducts(response.data.popular_products);
        })
        .catch((error) => console.log(error));
      await apiInstance
        .get("instagram-feed")
        .then((response) => {
          setInstagramFeeds(response.data.feeds);
        })
        .catch((error) => console.log(error));
      setStateLoading(false);
    }

    fetchingLandingData();
  }, []);

  const Hero = lazy(() => import("./HeroSlider"));
  // const PopularProduct = lazy(() => import("./PopularProducts"));
  // const FindUsOnInstagramLazy = lazy(() => import("./FindOnInstagram"));

  return (
    <div>
      <Nav />
      <Suspense fallbackPlacement={<div />}>
        <HeroSection />
      </Suspense>

      <Suspense fallbackPlacement={<div />}>
        <PopularProducts
          getPopularProducts={getPopularProducts}
          addWishlist={addWishlist}
        />
      </Suspense>
      <Suspense fallbackPlacement={<div />}>
        <FindUsOnInstagram getInstagramFeeds={getInstagramFeeds} />
      </Suspense>

      <Footer />
    </div>
    // ) : (
    //   <div className="min-h-screen w-screen flex flex-row items-center justify-center">
    //     <SyncLoader color="#313131" />
    //   </div>
    // );
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addWishlist: (id) => dispatch(addWishlist(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
