import {
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILED,
  GET_ALL_CART_SUCCESS,
  GET_ALL_CART_FAILED,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_FAILED,
  REMOVE_ALL_FROM_CART_SUCCESS,
  REMOVE_ALL_FROM_CART_FAILED,
  GET_ALL_WISHLIST_SUCCESS,
  GET_ALL_WISHLIST_FAILED,
  ADD_TO_WISHLIST_SUCCESS,
  ADD_TO_WISHLIST_FAILED,
  REMOVE_FROM_WISHLIST_SUCCESS,
  REMOVE_FROM_WISHLIST_FAILED,
  ORDER_SUCCESS,
  ORDER_FAILED,
  RESET_ORDER_STATE,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_FAILED,
} from "../actions/Type";
import { toast } from "react-toastify";

const initialState = { cart: [], wishlist: [], returnedMessage: "" };

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART_SUCCESS:
      toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case ADD_TO_CART_FAILED:
      toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case GET_ALL_CART_SUCCESS:
      return { ...state, cart: action.payload };
    case GET_ALL_CART_FAILED:
      toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case REMOVE_FROM_CART_SUCCESS:
      // toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case REMOVE_FROM_CART_FAILED:
      toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case REMOVE_ALL_FROM_CART_SUCCESS:
      // toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case REMOVE_ALL_FROM_CART_FAILED:
      toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case GET_ALL_WISHLIST_SUCCESS:
      return { ...state, wishlist: action.payload };
    case GET_ALL_WISHLIST_FAILED:
      toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case ADD_TO_WISHLIST_SUCCESS:
      toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case ADD_TO_WISHLIST_FAILED:
      toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case REMOVE_FROM_WISHLIST_SUCCESS:
      toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case REMOVE_FROM_WISHLIST_FAILED:
      toast.dark(action.payload, { position: "top-center" });
      return { ...state };
    case ORDER_SUCCESS:
      toast.dark(action.payload, { position: "top-center" });
      return {
        ...state,
      };
    case ORDER_FAILED:
      toast.dark(action.payload, { position: "top-center" });
      return {
        ...state,
      };
    case RESET_ORDER_STATE:
      return { ...state, returnedMessage: "" };
    default:
      return state;
  }
};

export default OrderReducer;
