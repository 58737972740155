import {
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAILED,
  RESET_USER_STATE,
} from "./Type";
import { startLoader, stopLoader } from "./ApplicationAction";
import { apiInstance } from "../api/api";

export const createUser = (values) => {
  return (dispatch) => {
    //   Converting form input in the way the backend accesses
    const inputData = {
      name: values.userFullName,
      email: values.userEmail,
      role_id: parseInt(values.userRole),
      password: values.userNewPassword,
      password_confirmation: values.userConfirmPassword,
    };

    dispatch(startLoader());

    // API call
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("register", inputData)
        .then((response) => {
          if (response.status === 201) {
            dispatch({
              type: ADD_USER_SUCCESS,
              payload: "User added successfully",
            });
            dispatch(stopLoader());
          } else {
            dispatch({
              type: ADD_USER_FAILED,
              payload:
                "Failed to add user. Potential cause is internet connectivity and bad request",
            });
            dispatch(stopLoader());
          }
        })
        .catch((error) => {
          dispatch({
            type: ADD_USER_FAILED,
            payload: "Failed to add user. Possible cause is " + error,
          });
          dispatch(stopLoader());
        });
    });
  };
};

export const login = (email, password) => {
  return (dispatch) => {
    dispatch(startLoader());
    // API call
    apiInstance
      .get("csrf-cookie")
      .then(() => {
        setTimeout(() => {
          apiInstance
            .post("login", { email, password })
            .then((response) => {
              let user_roles = [];
              if (response.status === 200) {
                localStorage.setItem("login_user_id", response.data.user.id);
                localStorage.setItem(
                  "login_user_name",
                  response.data.user.name
                );
                localStorage.setItem(
                  "login_user_email",
                  response.data.user.email
                );
                if (response.data) {
                  response.data.user.roles.forEach((SingleRole) =>
                    user_roles.push(SingleRole.title)
                  );

                  localStorage.setItem("login_user_role", user_roles);
                }
                dispatch(stopLoader());
                dispatch({ type: LOGIN_SUCCESS });
              } else {
                dispatch(stopLoader());
                dispatch({
                  type: LOGIN_FAILED,
                  payload: "Unsuccessful login attempt.",
                });
              }
            })
            .catch((error) => {
              dispatch(stopLoader());
              dispatch({
                type: LOGIN_FAILED,
                payload:
                  "Unsuccessful login attempt. " + error.response.data.error,
              });
            });
        }, 7000);
      })
      .catch((error) => {
        dispatch(stopLoader());
        dispatch({
          type: LOGIN_FAILED,
          payload: "Unsuccessful login attempt." + error,
        });
      });
  };
};

export const changePassword = (values) => {
  return (dispatch) => {
    // Converting form input in the way the backend access
    const inputData = {
      old_password: values.userCurrentPassword,
      new_password: values.userNewPassword,
      new_password_confirmation: values.userConfirmPassword,
      _method: "PUT",
    };

    dispatch(startLoader());

    // Operation
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post(
          "update-password/" + localStorage.getItem("login_user_id"),
          inputData
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CHANGE_PASSWORD_SUCCESS,
              payload: "Password changed successfully",
            });
            dispatch(stopLoader());
          } else {
            dispatch({
              type: CHANGE_PASSWORD_FAILED,
              payload:
                "Failed to change password. Potential cause of error is internet connectivity or bad request",
            });
            dispatch(stopLoader());
          }
        })
        .catch((error) => {
          let error_message = "";
          Object.keys(error.response.data.error_message).map((key, i) => {
            error_message = error.response.data.error_message[key];
          });
          dispatch({
            type: CHANGE_PASSWORD_FAILED,
            payload:
              "Failed to change password. Possible cause of error is :" +
              error_message,
          });
          dispatch(stopLoader());
        });
    });
  };
};

export const updateProfile = (values) => {
  return (dispatch) => {
    const inputData = {
      name: values.userFullName,
      email: values.userEmail,
      _method: "PUT",
    };

    dispatch(startLoader());

    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("update-profile", inputData)
        .then((response) => {
          if (response.status === 200) {
            // Updating local storage
            localStorage.setItem("login_user_email", values.userEmail);
            localStorage.setItem("login_user_name", values.userFullName);

            dispatch({
              type: EDIT_PROFILE_SUCCESS,
              payload: "User profile updated successfully",
            });

            dispatch(stopLoader());
          } else
            dispatch({
              type: EDIT_PROFILE_FAILED,
              payload:
                "Failed to update user profile. Potential cause is is internet connectivity or bad request",
            });

          dispatch(stopLoader());
        })
        .catch((error) => {
          dispatch({
            type: EDIT_PROFILE_FAILED,
            payload:
              "Failed to update user profile. Cause of error: " +
              error.response.data.error,
          });
          dispatch(stopLoader());
        });
    });
  };
};

export const resetState = () => {
  return (dispatch) => {
    console.log(RESET_USER_STATE);
    dispatch({ type: RESET_USER_STATE });
  };
};
