import React, { useState, useEffect } from "react";

// External imports
import SyncLoader from "react-spinners/SyncLoader";

// Component imports
import {
  validateEmptyString,
  validateFullNameLength,
  validateOnlyLetter,
  validateEmail,
} from "../../shared/Validation.js";

export default function BlogComment(props) {
  const selectedBlogComment = props.selectedBlogComment
    ? props.selectedBlogComment
    : [];

  const mappingCommentAndReply =
    selectedBlogComment.length > 0 ? (
      selectedBlogComment.map((SingleComment) => {
        return (
          <div className="">
            <div className="text-sm flex flex-col space-y-2">
              <p className="font-bold text-gray-400">
                {SingleComment.comment_by}
              </p>
              <p>{SingleComment.comment}</p>
            </div>

            {SingleComment.replies && SingleComment.replies.length > 0
              ? SingleComment.replies.map((SingleReply) => {
                  return (
                    <div className="text-sm flex flex-col space-y-2 mt-5 ml-20">
                      <p className="font-bold text-gray-400">
                        {SingleReply.reply_by}
                      </p>
                      <p>{SingleReply.reply}</p>
                    </div>
                  );
                })
              : null}
          </div>
        );
      })
    ) : (
      <div className="text-center">No Comment Available</div>
    );

  return (
    <div className="w-2/3 my-20">
      <p className="text-xl text-default font-bold mb-5">
        {selectedBlogComment.length} Comments
      </p>

      {/*  */}
      <div className="flex flex-col space-y-8">
        <div className="flex flex-col space-y-10">{mappingCommentAndReply}</div>
        <hr />
        <BlogCommentForm />
      </div>
    </div>
  );
}

function BlogCommentForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [isFormSubmitLoading, setFormSubmitLoading] = useState(false);

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const singleErrorMessage = [];

    if (!validateEmptyString(name)) {
      singleErrorMessage.push("Full Name is required");
      return setErrorMessage(singleErrorMessage);
    }

    if (!validateFullNameLength(name)) {
      singleErrorMessage.push(
        "Full Name should be less than 50 characters and greater than 5 characters"
      );
      return setErrorMessage(singleErrorMessage);
    }

    if (!validateOnlyLetter(name)) {
      singleErrorMessage.push("Full name is letter only field");
      return setErrorMessage(singleErrorMessage);
    }

    if (!validateEmptyString(email)) {
      singleErrorMessage.push("Email is required");
      return setErrorMessage(singleErrorMessage);
    }

    if (!validateEmail(email)) {
      singleErrorMessage.push("Email is in a wrong format");
      return setErrorMessage(singleErrorMessage);
    }

    if (!validateEmptyString(message)) {
      singleErrorMessage.push("Comment is required");
      return setErrorMessage(singleErrorMessage);
    }

    setFormSubmitLoading(true);
    //   Action
    setFormSubmitLoading(false);
  };

  const mappingErrorMessage =
    errorMessage && errorMessage.length > 0
      ? errorMessage.map((SingleError) => {
          return (
            <ul className="list-inside list-disc">
              <li className="font-light">{SingleError}</li>
            </ul>
          );
        })
      : null;

  return (
    <div>
      <form className="w-1/2 flex flex-col space-y-4" onSubmit={onFormSubmit}>
        <input
          className="px-3 py-2 bg-transparent border border-gray-300 placeholder-gray-400 w-full focus: outline-none"
          placeholder="Full name"
          name="name"
          id="name"
          type="text"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="px-3 py-2 bg-transparent border border-gray-300 placeholder-gray-400 w-full focus: outline-none"
          placeholder="Email"
          name="email"
          id="email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          className="px-3 py-2 bg-transparent border border-gray-300 placeholder-gray-400 w-full focus: outline-none"
          placeholder="Comment"
          name="message"
          id="message"
          type="text"
          row="10"
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <div>{mappingErrorMessage}</div>
        <div>
          <button
            className="px-6 py-2 bg-dark border text-white uppercase hover:border-default hover:bg-white hover:text-default"
            type="submit"
          >
            {isFormSubmitLoading ? (
              <SyncLoader color="#FFF" size="8" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
