import React, { useState, useEffect } from "react";

// Component import
import {
  validateEmptyString,
  validateFullNameLength,
  validateOnlyLetter,
  validateEmail,
} from "../../shared/Validation.js";

// External imports
import csc from "country-state-city";
import SyncLoader from "react-spinners/SyncLoader";

function ShippingFormComponent(props) {
  const [shippingName, setShippingName] = useState("");
  const [shippingEmail, setShippingEmail] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingPhone, setShippingPhone] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const [shippingZIP, setShippingZIP] = useState("");
  const [isShippingSame, setIsShippingSame] = useState(false);

  const [getAllShippingCountry, setAllShippingCountry] = useState([]);
  const [getShippingCountryCode, setShippingCountryCode] = useState("");
  const [getAllShippingCity, setAllShippingCity] = useState([]);
  const [getAllShippingState, setAllShippingState] = useState([]);

  const [getErrorMessage, setErrorMessage] = useState([]);
  const [isStateFetching, setIsStateFetching] = useState(false);

  const { billingInformation, backToPrevious } = props;

  // On Handle Change
  const onHandleChange = (e) => {
    if (e.target.name === "shippingCountry") {
      let optionShippingState = e.target.value;

      setShippingCountryCode(optionShippingState);
      setShippingCountry(optionShippingState);
      setAllShippingState(csc.getStatesOfCountry(optionShippingState));
    }

    if (e.target.name === "shippingState") {
      let optionState = e.target.value;

      setShippingState(optionState);
      setAllShippingCity(
        csc.getCitiesOfState(getShippingCountryCode, optionState)
      );
    }
  };

  // On Form Submit
  const onFormSubmit = async (e) => {
    e.preventDefault();
    const singleErrorMessage = [];

    const { updateIsShippingChecked, updatePage, updateShippingInformation } =
      props;

    if (isShippingSame) {
      if (
        !validateEmptyString(shippingName) ||
        !validateEmptyString(shippingEmail) ||
        !validateEmptyString(shippingAddress) ||
        !validateEmptyString(shippingPhone) ||
        !validateEmptyString(shippingCountry) ||
        !validateEmptyString(shippingState) ||
        !validateEmptyString(shippingCity) ||
        !validateEmptyString(shippingZIP)
      ) {
        singleErrorMessage.push("Recipient full name is required");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateFullNameLength(shippingName)) {
        singleErrorMessage.push(
          "Recipient full name should be less than 50 characters and greater than 5 characters"
        );
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateOnlyLetter(shippingName)) {
        singleErrorMessage.push("Recipient full name should be letter only");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateEmail(shippingEmail)) {
        singleErrorMessage.push("Recipient email is in a wrong format");
        return setErrorMessage(singleErrorMessage);
      }

      const shipping_details = {
        shipping_address: shippingAddress,
        shipping_city: shippingCity,
        shipping_state: shippingState,
        shipping_country: shippingCountry,
        shipping_name: shippingName,
        shipping_email: shippingEmail,
        shipping_phone: shippingPhone,
      };

      setErrorMessage([]);
      await updateIsShippingChecked(isShippingSame);
      await updateShippingInformation(shipping_details);
      updatePage();
    } else {
      // Business Rule - Only delivers products to United States of America
      if (billingInformation.address.country !== "US") {
        singleErrorMessage.push(
          "Sorry! Currently, We are only delivering products to United State of America."
        );
        return setErrorMessage(singleErrorMessage);
      }
      const shipping_details = {
        shipping_address: billingInformation.address.line1,
        shipping_city: billingInformation.address.city,
        shipping_state: billingInformation.address.state,
        shipping_country: billingInformation.address.country,
        shipping_name: billingInformation.name,
        shipping_email: billingInformation.email,
        shipping_phone: billingInformation.phone,
      };

      setErrorMessage([]);
      await updateIsShippingChecked(isShippingSame);
      await updateShippingInformation(shipping_details);
      updatePage();
    }
  };

  // Mapping functions
  const shippingStateMapping =
    getAllShippingState && getAllShippingState.length > 0
      ? getAllShippingState.map((SingleCountry) => {
          return (
            <option value={SingleCountry.isoCode} id={SingleCountry.isoCode}>
              {SingleCountry.name}
            </option>
          );
        })
      : null;

  const shippingCityMapping =
    getAllShippingCity && getAllShippingCity.length > 0
      ? getAllShippingCity.map((SingleCountry) => {
          return (
            <option value={SingleCountry.name} id={SingleCountry.isoCode}>
              {SingleCountry.name}
            </option>
          );
        })
      : null;

  const errorMessageMapping =
    getErrorMessage && getErrorMessage.length > 0
      ? getErrorMessage.map((SingleError) => {
          return (
            <ul className="list-inside list-disc" id={SingleError}>
              <li className="font-light">{SingleError}</li>
            </ul>
          );
        })
      : null;

  return (
    <div>
      <BillingInformationHighlight
        billingInformation={billingInformation}
        backToPrevious={backToPrevious}
      />
      <form onSubmit={onFormSubmit}>
        <div className="flex flex-col space-y-5 font-light">
          <p className="text-default font-light text-xl pb-5">
            Shipping Information
          </p>
          <div className="flex flex-row space-x-5 justify-start items-center border py-3 px-3 rounded-lg">
            <input
              className="font-light border text-textColor placeholder-gray-300 rounded-full focus:outline-none focus:border-default"
              placeholder="Address"
              name="address"
              id="address"
              type="checkbox"
              onChange={(e) => setIsShippingSame(e.target.checked)}
            />
            <label className="">
              Is Shipping address different from billing address? Check if YES
            </label>
          </div>
          {isShippingSame ? (
            <>
              <input
                className="w-full py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                placeholder="Full name"
                name="shippingName"
                id="shippingName"
                type="text"
                onChange={(e) => setShippingName(e.target.value)}
              />
              <div className="flex flex-row space-x-2 w-full">
                <input
                  className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default w-1/2"
                  placeholder="Email"
                  name="shippingEmail"
                  id="shippingEmail"
                  type="email"
                  onChange={(e) => setShippingEmail(e.target.value)}
                />
                <input
                  className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default w-1/2"
                  placeholder="Phone number"
                  name="shippingPhone"
                  id="shippingPhone"
                  type="text"
                  onChange={(e) => setShippingPhone(e.target.value)}
                />
              </div>
              <div className="flex flex-row space-x-5 w-full">
                <input
                  className="w-2/3 py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                  placeholder="Address"
                  name="address"
                  id="address"
                  type="text"
                  onChange={(e) => setShippingAddress(e.target.value)}
                />
                <input
                  className="w-1/3 py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                  placeholder="ZIP"
                  name="zip"
                  id="zip"
                  type="number"
                  onChange={(e) => setShippingZIP(e.target.value)}
                />
              </div>
              <div className="flex flex-row space-x-5 w-full">
                <select
                  className="w-1/2 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                  name="shippingCountry"
                  id="shippingCountry"
                  onChange={(e) => onHandleChange(e)}
                >
                  <option value="">Choose Country</option>
                  <option value="US">United States of America</option>
                </select>
                <select
                  className="w-1/3 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                  name="shippingState"
                  id="shippingState"
                  onChange={(e) => onHandleChange(e)}
                >
                  <option value="">Choose State</option>
                  {shippingStateMapping}
                </select>
                <select
                  className="w-1/3 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                  name="shippingCity"
                  id="shippingCity"
                  onChange={(e) => setShippingCity(e.target.value)}
                >
                  <option value="">Choose City</option>
                  {shippingCityMapping}
                </select>
              </div>
            </>
          ) : null}
          <div>{errorMessageMapping}</div>
          <div className="flex justify-between">
            <button
              className="bg-gray-400 text-white py-2 px-5 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
              onClick={() => backToPrevious()}
            >
              Back
            </button>
            <button
              className="bg-default text-white py-2 px-5 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
              type="submit"
              disabled={isStateFetching ? true : false}
            >
              {isStateFetching ? (
                <SyncLoader color="#FFF" size="8px" />
              ) : (
                "Continue to Payment"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

const BillingInformationHighlight = ({
  billingInformation,
  backToPrevious,
}) => {
  return (
    <div className="border rounded-lg px-5 py-5 flex flex-col space-y-2 mb-10">
      <div className="flex flex-row justify-between items-center space-x-16 border-b pb-4">
        <div className="flex flex-row items-center space-x-16">
          <p className="font-bold text-gray-400">Contact</p>
          <p className="font-light">{billingInformation.email}</p>
        </div>
        <button
          className="uppercase text-xs text-gray-400 hover:text-black mr-auto"
          onClick={() => backToPrevious()}
        >
          Update
        </button>
      </div>
      <div className="flex flex-row justify-between items-center space-x-16">
        <div className="flex flex-row items-center space-x-16">
          <p className="font-bold text-gray-400">Address</p>
          <p className="font-light">
            {billingInformation.address
              ? billingInformation.address.line1 +
                ", " +
                billingInformation.address.city +
                ", " +
                billingInformation.address.country
              : "----"}
          </p>
        </div>
        <button
          className="uppercase text-xs text-gray-400 hover:text-black mr-auto"
          onClick={() => backToPrevious()}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default ShippingFormComponent;
