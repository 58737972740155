import React, { useEffect, useState } from "react";

// External imports
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";
import Moment from "react-moment";
import "moment-timezone";

// Component imports
import Nav from "../../shared/Nav";
import NotFound from "../../shared/NotFound";
import Comment from "./BlogComment";
import SimilarBlog from "./SimilarBlogs";
import Footer from "../../shared/Footer";

export default function SpecificBlog(props) {
  const [isPageLoading, setPageLoading] = useState(false);
  const [selectedBlogDetail, setSelectedBlogDetail] = useState();
  const selectedBlogID = props.location.state.selectedBlogID
    ? props.location.state.selectedBlogID
    : "";

  useEffect(() => {
    async function fetchSingleBlog() {
      setPageLoading(true);
      await axios
        .get("../data/blog.json")
        .then((response) => {
          response.data.map((SingleBlog, index) => {
            if (SingleBlog.id === selectedBlogID) {
              setSelectedBlogDetail(response.data[index]);
              console.log(response.data[index]);
            }
          });
        })
        .catch((error) => console.log(error));
      setPageLoading(false);
    }

    fetchSingleBlog();
  }, [selectedBlogID]);

  return selectedBlogID !== "" ? (
    !isPageLoading ? (
      selectedBlogDetail ? (
        <div>
          <Nav />
          <Content selectedBlogDetail={selectedBlogDetail} />
          <Footer />
        </div>
      ) : (
        <NotFound />
      )
    ) : (
      <div className="min-h-screen w-screen flex flex-col items-center justify-center">
        <SyncLoader color="#313131" />
      </div>
    )
  ) : (
    <NotFound />
  );
}

const Content = ({ selectedBlogDetail }) => {
  return (
    <div className="px-20 py-20 flex flex-col justify-center items-center w-screen text-default">
      <div className="my-10 text-center flex flex-col space-y-3 w-2/3">
        <p className="font-light">
          Published{" "}
          <Moment
            date={selectedBlogDetail.posted_at}
            format={"MMMM DD, YYYY"}
          />
        </p>
        <p className="font-bold text-3xl px-10">{selectedBlogDetail.title}</p>
      </div>
      <img
        src={selectedBlogDetail.image}
        alt={"Blog Title"}
        className="w-2/3"
      />
      <div className="w-2/3 my-20 px-10">{selectedBlogDetail.description}</div>
      <hr />
      <div className="w-2/3 flex flex-row space-x-5">
        <img
          src={""}
          alt="BlogUser"
          className="rounded-full border-4 border-gray-100 w-20 h-20"
        />
        <div className="flex flex-col space-y-2">
          <p className="text-sm text-gray-300 font-bold">Author</p>
          <p>{selectedBlogDetail.posted_by}</p>
          <p className="text-sm">
            Laboris pariatur quis cillum dolore voluptate nulla ea velit commodo
            ex qui tempor. Ut aute dolor eiusmod elit quis culpa officia
            pariatur elit qui nostrud tempor. Nulla veniam incididunt esse
            irure. Elit Lorem veniam voluptate ipsum cupidatat. Laboris
            consectetur veniam nostrud cillum.
          </p>
        </div>
      </div>
      <hr />
      <Comment
        selectedBlogComment={
          selectedBlogDetail.comments ? selectedBlogDetail.comments : []
        }
      />
      <SimilarBlog
        blogItems={selectedBlogDetail.similar ? selectedBlogDetail.similar : []}
      />
    </div>
  );
};
