import React from "react";

// External import
import clip from "text-clipper";
import { Link } from "react-router-dom";

export default function SimilarBlogs(props) {
  console.log(props.blogItems);
  const blogItems = props.blogItems ? props.blogItems : [];
  // Item mapping
  const blogItemsMapping =
    blogItems && blogItems.length > 0
      ? blogItems.map((SingleItem) => {
          //   Text clipper
          const clipBlogDescription = clip(SingleItem.description, 100, {
            html: true,
            maxLines: 8,
          });
          return (
            <Link
              to={{
                pathname: "/blog/" + SingleItem.id,
                state: { selectedBlogID: SingleItem.id },
              }}
              className="w-1/3 px-1 mb-5"
              key={SingleItem.id}
            >
              <div className="bg-white rounded-lg flex flex-col justify-center items-center shadow-lg">
                <img
                  src={SingleItem.image}
                  alt={"Instagram"}
                  className="w-full h-52"
                />
                <div className="flex flex-col justify-between space-y-5 p-5 py-3">
                  <div>
                    <p className="text-xs text-gray-300">
                      {SingleItem.posted_by}
                    </p>
                    <p className="text-xl font-light mt-2">
                      {SingleItem.title}
                    </p>
                  </div>
                  <p className="text-default font-light">
                    {clipBlogDescription}
                  </p>
                </div>
              </div>
            </Link>
          );
        })
      : null;
  return (
    <div className="w-2/3">
      <p className="text-xl text-default font-bold mb-5">Similar Blogs</p>
      <div className="flex flex-row flex-wrap my-8">{blogItemsMapping}</div>
    </div>
  );
}
