import React, { useState } from "react";

// External imports
import { FaArrowRight, FaCheck } from "react-icons/fa";

import { connect } from "react-redux";

// Component imports
import BillingFormComponent from "./BillingFormComponent";
import ShippingFormComponent from "./ShippingFormComponent";
import PaymentFormComponent from "./PaymentFormComponent";
import CompletedFormComponent from "./CompletedFormComponent";
// import { orderProduct } from "../../../actions/OrderMgtAction";

function ParentCheckout(props) {
  const [page, setPage] = useState(1);
  const [totalPage] = useState(4);
  const [billingInformation, setBillingInformation] = useState([]);
  const [shippingInformation, setShippingInformation] = useState([]);
  const [paymentInformation, setPaymentInformation] = useState("");
  const [isShippingChecked, setIsShippingChecked] = useState(false);

  const shoppingCartElements = props.shoppingCartElements;
  const returnedMessage = props.returnedMessage;
  //   const orderProduct = props.orderProduct;

  const updateBillingInformation = (billingInformation) =>
    setBillingInformation(billingInformation);
  const updateShippingInformation = (shippingInformation) =>
    setShippingInformation(shippingInformation);
  const updatePaymentInformation = (paymentInformation) =>
    setPaymentInformation(paymentInformation);
  const updateIsShippingChecked = (isShippingChecked) =>
    setIsShippingChecked(isShippingChecked);

  const updatePage = () => {
    if (page === totalPage) return;
    setPage((page) => page + 1);
  };

  const backToPrevious = () => {
    if (page === 1) return;
    setPage((page) => page - 1);
  };

  //   const completePaymentAction = async () => {
  //     await orderProduct(
  //       billingInformation,
  //       paymentInformation,
  //       isShippingChecked,
  //       shippingInformation,
  //       shoppingCartElements
  //     );

  //     // if (returnedMessage === "") setPage((page) => page + 1);

  //     console.log(returnedMessage);
  //   };

  return (
    <div className="my-20 lg:mr-44">
      <div className="flex flex-row justify-around items-center">
        <div className="flex flex-col justify-center items-center space-y-2 uppercase text-xs">
          <div
            className={`rounded-full px-6 py-4 border-4 font-bold text-xl ${
              page === 1
                ? "border-black text-black"
                : "border-gray-200 text-gray-400"
            }`}
          >
            {page > 1 ? <FaCheck /> : 1}
          </div>
          <p>Billing</p>
        </div>
        <FaArrowRight size="30px" className="text-gray-400" />
        <div className="flex flex-col justify-center items-center space-y-2 uppercase text-xs">
          <div
            className={`rounded-full px-6 py-4 border-4 font-bold text-xl ${
              page === 2
                ? "border-black text-black"
                : "border-gray-200 text-gray-400"
            }`}
          >
            {page > 2 ? <FaCheck /> : 2}
          </div>
          <p>Shipping</p>
        </div>
        <FaArrowRight size="30px" className="text-gray-400" />
        <div className="flex flex-col justify-center items-center space-y-2 uppercase text-xs">
          <div
            className={`rounded-full px-6 py-4 border-4 font-bold text-xl ${
              page === 3
                ? "border-black text-black"
                : "border-gray-200 text-gray-400"
            }`}
          >
            {page > 3 ? <FaCheck /> : 3}
          </div>
          <p>Payment</p>
        </div>
        <FaArrowRight size="30px" className="text-gray-400" />
        <div className="flex flex-col justify-center items-center space-y-2 uppercase text-xs ">
          <div
            className={`rounded-full px-6 py-4 border-4 font-bold text-xl ${
              page === 4
                ? "border-black text-black"
                : "border-gray-200 text-gray-400"
            }`}
          >
            {page > 3 ? <FaCheck /> : 4}
          </div>
          <p>Complete</p>
        </div>
      </div>
      <div className="my-20">
        {page === 1 && (
          <BillingFormComponent
            updateBillingInformation={updateBillingInformation}
            billingInformation={billingInformation}
            updatePage={updatePage}
          />
        )}
        {page === 2 && (
          <ShippingFormComponent
            billingInformation={billingInformation}
            shippingInformation={shippingInformation}
            isShippingChecked={isShippingChecked}
            backToPrevious={backToPrevious}
            updatePage={updatePage}
            updateIsShippingChecked={updateIsShippingChecked}
            updateShippingInformation={updateShippingInformation}
          />
        )}
        {page === 3 && (
          <>
            <PaymentFormComponent
              billingInformation={billingInformation}
              shippingInformation={shippingInformation}
              shoppingCartElements={shoppingCartElements}
              isShippingChecked={isShippingChecked}
              backToPrevious={backToPrevious}
              updatePaymentInformation={updatePaymentInformation}
              updatePage={updatePage}
            />
            {returnedMessage !== "" ? (
              <div className="bg-gray-100 px-3 py-2">
                <p className="text-default">{returnedMessage}</p>
              </div>
            ) : null}
          </>
        )}
        {page === 4 && <CompletedFormComponent />}
      </div>
    </div>
  );
}

export default ParentCheckout;
