import React, { useState, useEffect } from "react";

// External imports
import { Link } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { BiMessageSquareEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";

// Component imports
import { apiInstance } from "../../../../api/api";

function ViewVariants(props) {
  const [getAllColors, setAllColors] = useState([]);
  const [getAllSizes, setAllSizes] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const deleteVariants = props.deleteVariants;
  const returnedMessage = props.returnedMessage;

  useEffect(() => {
    async function fetchVariants() {
      setIsPageLoading(true);
      await apiInstance.get("colors").then((response) => {
        setAllColors(response.data.colors);
      });
      await apiInstance.get("sizes").then((response) => {
        setAllSizes(response.data.sizes);
      });
      setIsPageLoading(false);
    }

    fetchVariants();
  }, []);

  const mappingSizes =
    getAllSizes && getAllSizes.length > 0 ? (
      getAllSizes.map((SingleSize) => {
        return (
          <div key={SingleSize.id} className="px-3 w-1/3">
            <div className="bg-light rounded-lg shadow-lg flex flex-row justify-between items-center p-4 mb-4">
              <p className="text-2xl text-default font-light w-1/2">
                {SingleSize.size}
              </p>
              <div className="flex flex-row items-center justify-center space-x-3 w-1/2">
                <Link
                  to={{
                    pathname: "/variant/create",
                    state: {
                      selectedService: "EDIT_SIZE",
                      selectedProduct: SingleSize,
                    },
                  }}
                  className="rounded-full shadow-lg bg-default p-2"
                >
                  <BiMessageSquareEdit size="15" color="#FFF" />
                </Link>
                <button
                  onClick={() => deleteVariants(SingleSize.id, "DELETE_SIZE")}
                  className="rounded-full shadow-lg bg-default p-2"
                >
                  <AiFillDelete size="15" color="#FFF" />
                </button>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="flex justify-center items-center">No Size Found !</div>
    );

  const mappingColors =
    getAllColors && getAllColors.length > 0 ? (
      getAllColors.map((SingleColor) => {
        return (
          <div key={SingleColor.id} className="px-3 w-1/3">
            <div className="bg-light rounded-lg shadow-lg flex flex-row justify-between items-center p-4 mb-4">
              <p className="text-2xl text-default font-light w-1/2">
                {SingleColor.color}
              </p>
              <div className="flex flex-row items-center justify-center space-x-3 w-1/2">
                <Link
                  to={{
                    pathname: "/variant/create",
                    state: {
                      selectedService: "EDIT_COLOR",
                      selectedProduct: SingleColor,
                    },
                  }}
                  className="rounded-full shadow-lg bg-default p-2"
                >
                  <BiMessageSquareEdit size="15" color="#FFF" />
                </Link>
                <button
                  onClick={() => deleteVariants(SingleColor.id, "DELETE_COLOR")}
                  className="rounded-full shadow-lg bg-default p-2"
                >
                  <AiFillDelete size="15" color="#FFF" />
                </button>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="flex justify-center items-center">No Color Found !</div>
    );

  return !isPageLoading ? (
    <div className="px-5 py-10 flex flex-col space-y-10">
      <div className="flex flex-col space-y-5">
        <p className="font-light text-default px-3 text-lg">Sizes</p>
        <div className="flex flex-row flex-wrap">{mappingSizes}</div>
      </div>
      <div className="flex flex-col space-y-5">
        <p className="font-light text-default px-3 text-lg">Colors</p>
        <div className="flex flex-row flex-wrap">{mappingColors}</div>
      </div>
    </div>
  ) : (
    <div className="flex flex-row justify-center items-center mt-20">
      <SyncLoader color="#313131" size="8px" />
    </div>
  );
}

export default ViewVariants;
