import React from "react";

// External import
import { Link } from "react-router-dom";
import { numberFormat } from "../../shared/Variables";
import clip from "text-clipper";
import { FiHeart } from "react-icons/fi";

export const PopularProducts = ({ getPopularProducts, addWishlist }) => {
  const mappingPopularProducts =
    getPopularProducts && getPopularProducts.length > 0
      ? getPopularProducts.map((SingleProduct, index) => {
          // Text clipper
          const clipProductDescription = clip(SingleProduct.description, 100, {
            html: true,
            maxLines: 8,
          });
          return index <= 2 ? (
            <Link
              to={{
                pathname: "/shop/" + SingleProduct.id,
                state: { selectedProductID: SingleProduct.id },
              }}
              className="rounded overflow-hidden shadow-lg flex-initial bg-white sm:mx-2 md:mx-1 lg:mx-2 w-full sm:w-1/4 lg:pt-0 mb-10 flex flex-col"
            >
              <Link
                to={{
                  pathname: "/shop/" + SingleProduct.id,
                  state: { selectedProductID: SingleProduct.id },
                }}
              >
                <img
                  src={SingleProduct.leading_image}
                  alt={SingleProduct.name}
                  className="w-full object-cover h-32 sm:h-48 md:h-64"
                />
              </Link>
              <div className="p-4 md:p-6 bg-white flex flex-col flex-1 space-y-3">
                <p className="text-xl font-light mx-3 text-center">
                  {SingleProduct.name}{" "}
                </p>
                <div className="flex flex-row justify-center items-center space-x-3 w-full px-3">
                  <p className="text-xl font-light leading-6">
                    {"$ " + numberFormat.format(SingleProduct.price)}
                  </p>

                  <div
                    className="bg-gray-100 rounded-full p-2 shadow-md cursor-pointer"
                    onClick={async () => await addWishlist(SingleProduct.id)}
                  >
                    <FiHeart size="13" />
                  </div>
                </div>
                {/* <div class="text-sm flex items-center">
                    <p className="text-sm text-gray-400 mx-0 font-light text-center">
                      {clipProductDescription}{" "}
                    </p>
                  </div> */}
              </div>
            </Link>
          ) : null;
        })
      : null;

  return (
    <div className="mx-10 lg:mx-32 mt-10 lg:mt-20 lg:mb-32 flex flex-col space-y-10">
      <p className="text-center">
        <span className="uppercase text-4xl font-light">Popular Products</span>
      </p>

      <div class="container mx-auto">
        <div class="flex flex-col sm:flex-row justify-center mx-4 md:mx-0 lg:-mx-2 flex-wrap">
          {mappingPopularProducts}
        </div>
      </div>
    </div>
  );
};
