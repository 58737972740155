import React, { useState, useEffect } from "react";

// External imports
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory, Redirect } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { AiTwotoneSetting, AiFillNotification } from "react-icons/ai";
import { RiEditBoxLine } from "react-icons/ri";
import { FaExclamation } from "react-icons/fa";
import { FiUnlock, FiLogOut } from "react-icons/fi";
import { connect } from "react-redux";

// Component import
import SideNav from "../../../shared/SideNav";
import { resetState } from "../../../../actions/UserMgtAction";
import { numberFormat } from "../../../shared/Variables";
import { apiInstance } from "../../../../api/api";

function Root(props) {
  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;
  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <DashboardBody resetState={props.resetState} />
    </div>
  );
}

const DashboardBody = ({ resetState }) => {
  return (
    <div className="bg-light flex flex-row w-full">
      <DashboardLeftSide />
      <DashboardRightSide resetState={resetState} />
    </div>
  );
};

const DashboardLeftSide = () => {
  const [totalOrders, setTotalOrders] = useState();
  const [totalBlogs, setTotalBlogs] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [recentOrders, setRecentOrders] = useState([]);
  const [isStatLoading, setIsStatLoading] = useState(false);

  useEffect(() => {
    async function getStats() {
      setIsStatLoading(true);
      await apiInstance
        .get("orders")
        .then((response) => setTotalOrders(response.data.orders.length))
        .catch((error) => console.log(error));
      await apiInstance
        .get("posts")
        .then((response) => setTotalBlogs(response.data.posts.length))
        .catch((error) => console.log(error));
      await apiInstance
        .get("products")
        .then((response) => setTotalProducts(response.data.products.length))
        .catch((error) => console.log(error));
      await apiInstance
        .get("users")
        .then((response) => setTotalUsers(response.data.users.length))
        .catch((error) => console.log(error));
      await apiInstance
        .get("recent-orders")
        .then((response) => setRecentOrders(response.data.recent_orders))
        .catch((error) => console.log(error));
      setIsStatLoading(false);
    }

    getStats();
  }, []);

  const quickStats = [
    {
      id: 1,
      quickStatType: "Total Order",
      statNumber: totalOrders,
      viewLink: "/orders",
    },
    {
      id: 2,
      quickStatType: "No. of Blogs",
      statNumber: totalBlogs,
      viewLink: "/admin_blog",
    },
    {
      id: 3,
      quickStatType: "No. of Products",
      statNumber: totalProducts,
      viewLink: "/products",
    },
    {
      id: 4,
      quickStatType: "No. of Users",
      statNumber: totalUsers,
      viewLink: "/user",
    },
  ];

  const quickStatMapping =
    quickStats.length > 0 ? (
      quickStats.map((SingleStat) => {
        return (
          <div
            className="flex flex-col items-center shadow-xl rounded-2xl w-1/4 px-5 py-8 bg-white"
            key={SingleStat.id}
          >
            <p className="font-thin text-sm">{SingleStat.quickStatType}</p>
            <p className="font-bold text-4xl text-default mt-5">
              {numberFormat.format(SingleStat.statNumber)}
            </p>
            <Link
              to={SingleStat.viewLink}
              className="text-default flex flex-row items-center mt-4"
            >
              View
              <span className="ml-3">
                <BsArrowRight size="25" />
              </span>
            </Link>
          </div>
        );
      })
    ) : (
      <p className="text-center">No Stat</p>
    );

  const recentOrderMapping =
    recentOrders.length > 0 ? (
      recentOrders.map((SingleOrder) => {
        return (
          <div className="shadow-xl rounded-2xl px-5 py-8 w-1/3 bg-white mt-8">
            <p className="font-bold text-sm text-default">
              {SingleOrder.customer.name}
            </p>
            <div className="flex flex-row justify-between">
              <div className="mt-5 flex flex-col">
                <p className="font-bold text-sm text-gray-300">Product</p>
                <p className="font-light text-sm text-default">
                  {SingleOrder.products.map(
                    (SingleProduct) => SingleProduct.product.name
                  )}
                </p>
              </div>
              <div className="mt-5 flex flex-col">
                <p className="font-bold text-sm text-gray-300">Status</p>
                <p className="font-light text-sm text-default capitalize">
                  {SingleOrder.order_status === ""
                    ? "Just Ordered"
                    : SingleOrder.order_status}
                </p>
              </div>
            </div>
            <hr className="border border-gray-100 mt-5" />
            <Link
              to="/orders"
              className="text-default flex flex-row items-center mt-4"
            >
              View
              <span className="ml-3">
                <BsArrowRight size="25" />
              </span>
            </Link>
          </div>
        );
      })
    ) : (
      <p className="text-center font-light m-auto mt-20">No Recent Order</p>
    );

  return !isStatLoading ? (
    <div className="px-10 w-3/4">
      {/* Dashboard Header */}
      <div className="flex flex-col py-10">
        <p className="text-4xl font-bold text-default">Dashboard</p>
        <p className="font-thin text-sm">Welcome Admin</p>
      </div>
      {/* Quick Stats */}
      <div className="flex flex-col">
        <p className="text-default font-bold">Quick stats</p>
        <div className="flex flex-row flex-wrap mt-8">{quickStatMapping}</div>
      </div>
      {/* Recent Orders */}
      <div className="flex flex-col mt-10">
        <p className="text-default font-bold">Recent orders</p>
        <div className="flex flex-row space-x-0 flex-wrap">
          {recentOrderMapping}
        </div>
      </div>
    </div>
  ) : (
    <div className="min-h-screen w-screen flex flex-col items-center justify-center">
      <SyncLoader color="#313131" />
    </div>
  );
};

const DashboardRightSide = ({ resetState }) => {
  const [inventoryNotice, setInventoryNotice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();

  useEffect(() => {
    async function getInventory() {
      setIsLoading(true);
      await apiInstance
        .get("check-stock")
        .then((response) =>
          setInventoryNotice(response.data.low_stock_variations)
        )
        .catch((error) => console.log(error));
      setIsLoading(false);
    }

    getInventory();
  }, []);

  // onLogoutButtonClicked
  const onLogOutButtonClicked = async () => {
    await apiInstance
      .get("csrf-cookie")
      .then(async () => {
        await apiInstance
          .post("logout")
          .then(() => {
            localStorage.clear();
            resetState();
            history.push("/login");
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const mappingInventory =
    inventoryNotice && inventoryNotice.length > 0 ? (
      inventoryNotice.map((SingleInventory) => {
        return (
          <div
            to={"..."}
            className="flex flex-row space-x-5 items-start mt-8"
            key={SingleInventory.id}
          >
            <div className="bg-gray-100 p-3 rounded-xl">
              <FaExclamation size="30" className="text-default" />
            </div>
            <div className="text-default">
              <p className="font-bold">{SingleInventory.product.name}</p>
              <div className="flex flex-row">
                <p className="text-gray-300 text-sm font-bold">
                  Left with : {SingleInventory.available_stock}
                </p>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <p className="flex flex-row font-light justify-center items-center mt-20">
        No Notice !
      </p>
    );

  return !isLoading ? (
    <div className="w-1/3 bg-white rounded-l-2xl px-10 max-h-screen overflow-y-auto">
      {/* User Profile Information */}
      <div className="flex flex-row justify-start items-center">
        <div className="flex flex-col py-10 w-1/2">
          <p className="font-thin text-lg">Hello,</p>
          <p className="text-4xl font-bold text-default">Kunjina!</p>
        </div>
      </div>

      {/* Settings */}
      <div className="mt-8">
        <div className="flex flex-row justify-between items-center">
          <p className="text-default font-bold text-lg">Settings</p>
          <AiTwotoneSetting className="text-default" size="25" />
        </div>
        {/* Edit Profile */}
        <Link
          to={{ pathname: "/edit", state: { selectedService: "EDIT_PROFILE" } }}
          className="flex flex-row space-x-5 items-start mt-8"
        >
          <div className="bg-gray-100 p-3 rounded-xl">
            <RiEditBoxLine size="30" className="text-default" />
          </div>
          <div className="text-default">
            <p className="font-bold">Edit Profile</p>
            <p className="text-gray-300 text-sm font-light">
              Edit your profile information by navigating from here.
            </p>
          </div>
        </Link>
        {/* Change Password */}
        <Link
          to={{
            pathname: "/edit",
            state: { selectedService: "CHANGE_PASSWORD" },
          }}
          className="flex flex-row space-x-5 items-start mt-8"
        >
          <div className="bg-gray-100 p-3 rounded-xl">
            <FiUnlock size="30" className="text-default" />
          </div>
          <div className="text-default">
            <p className="font-bold">Change Password</p>
            <p className="text-gray-300 text-sm font-light">
              You can change your password here
            </p>
          </div>
        </Link>
        {/* Logout */}
        <div
          onClick={() => onLogOutButtonClicked()}
          className="flex flex-row space-x-5 items-start mt-8 cursor-pointer"
        >
          <div className="bg-gray-100 p-3 rounded-xl">
            <FiLogOut size="30" className="text-default" />
          </div>
          <div className="text-default">
            <p className="font-bold">Logout</p>
            <p className="text-gray-300 text-sm font-light">
              Exit the system here.
            </p>
          </div>
        </div>
      </div>

      {/* Notice */}
      <div className="mt-8">
        <div className="flex flex-row justify-between items-center">
          <p className="text-default font-bold text-lg">Notice</p>
          <AiFillNotification className="text-default" size="25" />
        </div>
        {/* Each Notice */}
        {mappingInventory}
      </div>
    </div>
  ) : (
    <div className="min-h-screen w-screen flex flex-col items-center justify-center">
      <SyncLoader color="#313131" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
