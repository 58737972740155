import React, { useState, useEffect } from "react";

// Component imports
import Nav from "../../shared/Nav";
import Footer from "../../shared/Footer";
import {
  validateEmptyString,
  validateFullNameLength,
  validateOnlyLetter,
  validateEmail,
} from "../../shared/Validation.js";
import { orderProduct } from "../../../actions/OrderMgtAction";
import NotFound from "../../shared/NotFound";

// External imports
import { connect } from "react-redux";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import csc from "country-state-city";
import SyncLoader from "react-spinners/SyncLoader";

function Checkout(props) {
  if (typeof props.location.state === "undefined") return <NotFound />;

  const productOrderInformation = props.location.state.productOrderInformation;
  const orderProduct = props.orderProduct;

  return (
    <div className="bg-light">
      <Nav />
      <Content
        productOrderInformation={productOrderInformation}
        orderProduct={orderProduct}
      />
      <Footer />
    </div>
  );
}

const Content = ({ productOrderInformation, orderProduct }) => {
  // State
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [ZIP, setZIP] = useState("");
  const [isShippingSame, setIsShippingSame] = useState(false);

  const [shippingName, setShippingName] = useState("");
  const [shippingEmail, setShippingEmail] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingPhone, setShippingPhone] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const [shippingZIP, setShippingZIP] = useState("");

  const [getAllCountry, setAllCountry] = useState([]);
  const [getCountryCode, setCountryCode] = useState("");
  const [getAllCity, setAllCity] = useState([]);
  const [getAllState, setAllState] = useState([]);

  const [getAllShippingCountry, setAllShippingCountry] = useState([]);
  const [getShippingCountryCode, setShippingCountryCode] = useState("");
  const [getAllShippingCity, setAllShippingCity] = useState([]);
  const [getAllShippingState, setAllShippingState] = useState([]);

  const [getErrorMessage, setErrorMessage] = useState([]);
  const [isStateFetching, setIsStateFetching] = useState(false);

  const elements = useElements();
  const stripe = useStripe();

  // Data fetch
  useEffect(() => {
    async function fetchFormData() {
      setIsStateFetching(true);
      await setAllCountry(csc.getAllCountries());
      setIsStateFetching(false);
    }

    // Calling fetchFormData
    fetchFormData();
  }, []);

  // on Change
  const onHandleChange = (e) => {
    if (e.target.name === "country") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let option = el.getAttribute("id");

      setCountryCode(option);
      setCountry(option);
      setAllState(csc.getStatesOfCountry(option));
    }

    if (e.target.name === "state") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let optionState = el.getAttribute("id");

      setState(e.target.value);
      setAllCity(csc.getCitiesOfState(getCountryCode, optionState));
    }

    if (e.target.name === "shippingCountry") {
      let optionShippingState = e.target.value;

      setShippingCountryCode(optionShippingState);
      setShippingCountry(optionShippingState);
      setAllShippingState(csc.getStatesOfCountry(optionShippingState));
    }

    if (e.target.name === "shippingState") {
      let optionState = e.target.value;

      setShippingState(optionState);
      setAllShippingCity(
        csc.getCitiesOfState(getShippingCountryCode, optionState)
      );
    }
  };

  //   on Form Submit
  const onFormSubmit = async (e) => {
    e.preventDefault();

    const singleErrorMessage = [];

    if (!stripe || !elements) {
      return setErrorMessage(
        "Payment gateway is loading. Please check your internet connection"
      );
    }

    if (!validateEmptyString(name)) {
      singleErrorMessage.push("Full Name is required");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateFullNameLength(name)) {
      singleErrorMessage.push(
        "Full Name should be less than 50 characters and greater than 5 characters"
      );
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateOnlyLetter(name)) {
      singleErrorMessage.push("Full Name should be letter only");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmptyString(email)) {
      singleErrorMessage.push("Email is required");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmail(email)) {
      singleErrorMessage.push("Email is in a wrong format");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmptyString(address)) {
      singleErrorMessage.push("Address is required");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmptyString(phone)) {
      singleErrorMessage.push("Phone Number is required");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmptyString(country)) {
      singleErrorMessage.push("Country is required");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmptyString(state)) {
      singleErrorMessage.push("State is required");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmptyString(city)) {
      singleErrorMessage.push("City is required");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmptyString(ZIP)) {
      singleErrorMessage.push("ZIP code is required");
      return setErrorMessage(singleErrorMessage);
    }

    if (isShippingSame) {
      if (!validateEmptyString(shippingName)) {
        singleErrorMessage.push("Recipient full name is required");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateFullNameLength(shippingName)) {
        singleErrorMessage.push(
          "Recipient full name should be less than 50 characters and greater than 5 characters"
        );
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateOnlyLetter(shippingName)) {
        singleErrorMessage.push("Recipient full name should be letter only");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateEmptyString(shippingEmail)) {
        singleErrorMessage.push("Recipient email is required");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateEmail(shippingEmail)) {
        singleErrorMessage.push("Recipient email is in a wrong format");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateEmptyString(shippingAddress)) {
        singleErrorMessage.push("Recipient address is required");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateEmptyString(shippingPhone)) {
        singleErrorMessage.push("Recipient phone number is required");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateEmptyString(shippingCountry)) {
        singleErrorMessage.push("Recipient country is required");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateEmptyString(shippingState)) {
        singleErrorMessage.push("Recipient state is required");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateEmptyString(shippingCity)) {
        singleErrorMessage.push("Recipient city is required");
        return setErrorMessage(singleErrorMessage);
      }
      if (!validateEmptyString(shippingZIP)) {
        singleErrorMessage.push("Recipient ZIP code is required");
        return setErrorMessage(singleErrorMessage);
      }

      // After filling both the billing and shipping addresses
      setIsStateFetching(true);
      const billing_details = {
        name: name,
        email: email,
        phone: phone,
        address: {
          city: city,
          country: country,
          line1: address,
          state: state,
        },
      };
      const shipping_details = {
        shipping_address: shippingAddress,
        shipping_city: shippingCity,
        shipping_state: shippingState,
        shipping_country: shippingCountry,
        shipping_name: shippingName,
        shipping_email: shippingEmail,
        shipping_phone: shippingPhone,
      };
      const paymentID = await processPayment(billing_details);
      console.log(paymentID);
      // await orderProduct(
      //   billing_details,
      //   paymentID,
      //   isShippingSame,
      //   shipping_details,
      //   productOrderInformation
      // );
      setIsStateFetching(false);
    } else {
      setIsStateFetching(true);
      const billing_details = {
        name: name,
        email: email,
        phone: phone,
        address: {
          city: city,
          country: country,
          line1: address,
          state: state,
        },
      };
      const shipping_details = {
        shipping_address: address,
        shipping_city: city,
        shipping_state: state,
        shipping_country: country,
        shipping_name: name,
        shipping_email: email,
        shipping_phone: phone,
      };
      const paymentID = await processPayment(billing_details);
      console.log(paymentID);
      // await orderProduct(
      //   billing_details,
      //   paymentID,
      //   isShippingSame,
      //   shipping_details,
      //   productOrderInformation
      // );
      setIsStateFetching(false);
    }
  };

  const processPayment = async (billing_details) => {
    setErrorMessage([]);

    const { error, paymentMethod } = await stripe.createPaymentMethod(
      "card",
      elements.getElement(CardElement),
      { billing_details }
    );

    if (!error) {
      const { id } = paymentMethod;
      return id;
    } else setErrorMessage(error);
  };

  const countryMapping =
    getAllCountry && getAllCountry.length > 0
      ? getAllCountry.map((SingleCountry) => {
          return (
            <option value={SingleCountry.name} id={SingleCountry.isoCode}>
              {SingleCountry.name}
            </option>
          );
        })
      : null;

  const stateMapping =
    getAllState && getAllState.length > 0
      ? getAllState.map((SingleState) => {
          return (
            <option value={SingleState.name} id={SingleState.isoCode}>
              {SingleState.name}
            </option>
          );
        })
      : null;

  const shippingStateMapping =
    getAllShippingState && getAllShippingState.length > 0
      ? getAllShippingState.map((SingleCountry) => {
          return (
            <option value={SingleCountry.isoCode} id={SingleCountry.isoCode}>
              {SingleCountry.name}
            </option>
          );
        })
      : null;

  const cityMapping =
    getAllCity && getAllCity.length > 0
      ? getAllCity.map((SingleCity) => {
          return <option value={SingleCity.name}>{SingleCity.name}</option>;
        })
      : null;

  const shippingCityMapping =
    getAllShippingCity && getAllShippingCity.length > 0
      ? getAllShippingCity.map((SingleCountry) => {
          return (
            <option value={SingleCountry.name} id={SingleCountry.isoCode}>
              {SingleCountry.name}
            </option>
          );
        })
      : null;

  const errorMessageMapping =
    getErrorMessage && getErrorMessage.length > 0
      ? getErrorMessage.map((SingleError) => {
          return (
            <ul className="list-inside list-disc">
              <li className="font-light">{SingleError}</li>
            </ul>
          );
        })
      : null;

  return (
    <div className="py-24 px-20">
      <form onSubmit={onFormSubmit} className="">
        <div className="flex flex-col justify-start font-light space-y-20 mb-5">
          <div className="mt-10 flex flex-col space-y-5 w-1/2">
            <p className="text-default font-light text-xl pb-5">
              Billing Information
            </p>
            <input
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              placeholder="Full name"
              name="name"
              id="name"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
            <div className="flex flex-row space-x-2">
              <input
                className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default w-1/2"
                placeholder="Email"
                name="email"
                id="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default w-1/2"
                placeholder="Phone number"
                name="phone"
                id="phone"
                type="text"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="flex flex-row space-x-3 w-full">
              <input
                className="w-2/3 py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                placeholder="Address"
                name="address"
                id="address"
                type="text"
                onChange={(e) => setAddress(e.target.value)}
              />
              <input
                className="w-1/3 py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                placeholder="ZIP"
                name="zip"
                id="zip"
                type="number"
                onChange={(e) => setZIP(e.target.value)}
              />
            </div>

            <div className="flex flex-row space-x-5 w-full">
              <select
                className="w-1/2 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                name="country"
                id="country"
                onChange={onHandleChange}
              >
                <option value="">Choose Country</option>
                {countryMapping}
              </select>
              <select
                className="w-1/3 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                name="state"
                id="state"
                onChange={onHandleChange}
              >
                <option value="">Choose State</option>
                {stateMapping}
              </select>
              <select
                className="w-1/3 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                name="city"
                id="city"
                onChange={(e) => setCity(e.target.value)}
              >
                <option value="">Choose City</option>
                {cityMapping}
              </select>
            </div>
            <CardElement className="py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default" />
          </div>
          <div className="mt-10 flex flex-col space-y-5 justify-start items-start w-1/2">
            <p className="text-default font-light text-xl pb-5">
              Shipping Information
            </p>
            <div className="flex flex-row space-x-5 justify-center items-center pb-5">
              <input
                className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                placeholder="Address"
                name="address"
                id="address"
                type="checkbox"
                onChange={(e) => setIsShippingSame(e.target.checked)}
              />
              <label className="">
                Is Shipping address different from billing address? Check if YES
              </label>
            </div>
            {isShippingSame ? (
              <>
                <input
                  className="w-full py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                  placeholder="Full name"
                  name="shippingName"
                  id="shippingName"
                  type="text"
                  onChange={(e) => setShippingName(e.target.value)}
                />
                <div className="flex flex-row space-x-2 w-full">
                  <input
                    className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default w-1/2"
                    placeholder="Email"
                    name="shippingEmail"
                    id="shippingEmail"
                    type="email"
                    onChange={(e) => setShippingEmail(e.target.value)}
                  />
                  <input
                    className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default w-1/2"
                    placeholder="Phone number"
                    name="shippingPhone"
                    id="shippingPhone"
                    type="text"
                    onChange={(e) => setShippingPhone(e.target.value)}
                  />
                </div>
                <div className="flex flex-row space-x-5 w-full">
                  <input
                    className="w-2/3 py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                    placeholder="Address"
                    name="address"
                    id="address"
                    type="text"
                    onChange={(e) => setShippingAddress(e.target.value)}
                  />
                  <input
                    className="w-1/3 py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                    placeholder="ZIP"
                    name="zip"
                    id="zip"
                    type="number"
                    onChange={(e) => setShippingZIP(e.target.value)}
                  />
                </div>
                <div className="flex flex-row space-x-5 w-full">
                  <select
                    className="w-1/2 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                    name="shippingCountry"
                    id="shippingCountry"
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value="">Choose Country</option>
                    <option value="US">United States of America</option>
                  </select>
                  <select
                    className="w-1/3 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                    name="shippingState"
                    id="shippingState"
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value="">Choose State</option>
                    {shippingStateMapping}
                  </select>
                  <select
                    className="w-1/3 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                    name="shippingCity"
                    id="shippingCity"
                    onChange={(e) => setShippingCity(e.target.value)}
                  >
                    <option value="">Choose City</option>
                    {shippingCityMapping}
                  </select>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div>{errorMessageMapping}</div>

        <button
          className="bg-default text-white w-1/2 my-8 py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
          type="submit"
          disabled={isStateFetching ? true : false}
        >
          {isStateFetching ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderProduct: (
      billing_details,
      paymentID,
      isShippingSame,
      shipping_details,
      productInformation
    ) =>
      dispatch(
        orderProduct(
          billing_details,
          paymentID,
          isShippingSame,
          shipping_details,
          productInformation
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
