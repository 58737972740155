import {
  ADD_MEDIA_SUCCESS,
  ADD_MEDIA_FAILED,
  EDIT_MEDIA_SUCCESS,
  EDIT_MEDIA_FAILED,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAILED,
  RESET_MEDIA,
} from "../actions/Type";

const initialState = { returnedMessage: "" };

const MediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MEDIA_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case ADD_MEDIA_FAILED:
      return { ...state, returnedMessage: action.payload };
    case EDIT_MEDIA_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case EDIT_MEDIA_FAILED:
      return { ...state, returnedMessage: action.payload };
    case DELETE_MEDIA_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case DELETE_MEDIA_FAILED:
      return { ...state, returnedMessage: action.payload };
    case RESET_MEDIA:
      return { ...state, returnedMessage: "" };
    default:
      return { ...state };
  }
};

export default MediaReducer;
