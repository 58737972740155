import React, { Component } from "react";

// Component imports
import { validateEmptyString, validateEmail } from "../../shared/Validation";
import { addToSubscribeList } from "../../../actions/ContactAction";
import { resetLoader } from "../../../actions/ApplicationAction";

// External imports
import SyncLoader from "react-spinners/SyncLoader";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class NewsletterForm extends Component {
  state = { email: "", errorMessage: [], isFormSubmitLoading: false };

  componentWillUnmount() {
    resetLoader();
  }

  render() {
    const { subscribed, addToSubscribeList, loaderState } = this.props;

    if (typeof subscribed !== "undefined" && subscribed.status === true)
      return <Redirect to="/thankyou" />;

    // on Change
    const onHandleChange = (e) => {
      this.setState({ email: e.target.value });
    };
    // on Submit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      const { email } = this.state;
      const singleErrorMessage = [];

      if (!validateEmptyString(email)) {
        singleErrorMessage.push("Email is required");
        return this.setState({ errorMessage: singleErrorMessage });
      }

      if (!validateEmail(email)) {
        singleErrorMessage.push("Email is in a wrong format");
        return this.setState({ errorMessage: singleErrorMessage });
      }

      this.setState({ errorMessage: [] });
      // Taking action
      await addToSubscribeList(email);
    };
    return (
      <div className="mt-8">
        <form
          className="flex flex-col justify-center items-center md:flex-row md:justify-start md:items-start space-y-3 md:space-x-3 md:space-y-0"
          onSubmit={onFormSubmit}
        >
          <input
            className="py-2 px-3 border border-gray-600 bg-transparent font-light text-white w-2/3 md:w-full focus:outline-none"
            placeholder="Email"
            id="email"
            name="email"
            type="text"
            onChange={onHandleChange}
          />
          <div>
            <button
              type="submit"
              className="bg-dark text-white px-4 py-2 uppercase border hover:border-dark hover:bg-light hover:text-dark"
              disabled={loaderState ? true : false}
            >
              {loaderState ? <SyncLoader color="#FFF" size="8" /> : "Subscribe"}
            </button>
          </div>
        </form>
        <div>{this.state.errorMessage}</div>
        {typeof subscribed !== "undefined" &&
        subscribed.status === false &&
        subscribed.message !== "" ? (
          <div className="px-3 py-2">
            <p className="text-white font-light">{subscribed.message}</p>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subscribed: state.contact.subscribed,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToSubscribeList: (email) => dispatch(addToSubscribeList(email)),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterForm);
