import React, { Component } from "react";

// External import
import SyncLoader from "react-spinners/SyncLoader";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Component imports
import SideNav from "../../../shared/SideNav";
import {
  validateEmptyString,
  validateFullNameLength,
  validateEmail,
  validateOnlyLetter,
  validatePasswordConfirmation,
} from "../../../shared/Validation";
import NotFound from "../../../shared/NotFound";
import {
  changePassword,
  updateProfile,
  resetState,
} from "../../../../actions/UserMgtAction";
import { resetLoader } from "../../../../actions/ApplicationAction";
import { apiInstance } from "../../../../api/api";

function EditProfile(props) {
  if (typeof props.location.state === "undefined") return <NotFound />;

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  // Getting selected service props
  const selectedService = props.location.state.selectedService;
  const changePassword = props.changePassword;
  const updateProfile = props.updateProfile;
  const returnedMessage = props.returnedMessage;
  const resetState = props.resetState;
  const resetLoader = props.resetLoader;
  const isLoading = props.loaderState;

  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <div className="px-10 w-full">
        <div className="flex flex-row space-x-5 py-10 items-center">
          <Link to={"/dashboard"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>

          {/* Profile Header */}
          <div className="flex flex-col">
            <p className="text-4xl font-bold text-textColor">
              {selectedService === "EDIT_PROFILE" &&
              typeof selectedService !== "undefined"
                ? "Edit Your Profile"
                : "Change Password"}
            </p>
            <p className="font-thin text-sm">
              {selectedService === "EDIT_PROFILE" &&
              typeof selectedService !== "undefined"
                ? "Edit your personalize content"
                : "Change your password"}
            </p>
          </div>
        </div>

        {/* Hotel/Room form */}
        <EditProfileForm
          selectedService={selectedService}
          changePassword={changePassword}
          updateProfile={updateProfile}
          returnedMessage={returnedMessage}
          resetState={resetState}
          isLoading={isLoading}
          resetLoader={resetLoader}
        />
      </div>
    </div>
  );
}

class EditProfileForm extends Component {
  // Component state
  state = {
    userFullName: "",
    userEmail: "",
    userCurrentPassword: "",
    userNewPassword: "",
    userConfirmPassword: "",
    userErrorMessage: [],

    isFormSubmitLoading: false,
    pageLoading: false,
  };

  async componentDidMount() {
    this.setState({ pageLoading: true }, async () => {
      await apiInstance
        .get("user")
        .then((response) => {
          this.setState({
            userFullName: response.data.name,
            userEmail: response.data.email,
          });
        })
        .catch((error) => console.log(error));
    });
    this.setState({ pageLoading: false });
  }

  componentWillUnmount() {
    this.props.resetState();
    this.props.resetLoader();
  }

  render() {
    // Accepting props
    const {
      selectedService,
      changePassword,
      updateProfile,
      returnedMessage,
      isLoading,
      selectedUserInfo,
    } = this.props;

    //   onHandleChangeInField
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   onHandleFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();
      const {
        userFullName,
        userEmail,
        userCurrentPassword,
        userNewPassword,
        userConfirmPassword,
        isFormSubmitLoading,
      } = this.state;
      const singleErrorMessage = [];

      // Checking the service
      if (selectedService === "EDIT_PROFILE") {
        if (
          !validateEmptyString(userFullName) ||
          !validateEmptyString(userEmail)
        ) {
          singleErrorMessage.push(
            "One or more required fields are missing. Please fill and try again."
          );
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        if (!validateFullNameLength(userFullName)) {
          singleErrorMessage.push(
            "Full name should be less than 50 and greater than 5 characters."
          );
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        if (!validateEmail(userEmail)) {
          singleErrorMessage.push("Email is in a wrong format");
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        if (!validateOnlyLetter(userFullName)) {
          singleErrorMessage.push("Full name is in wrong format");
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        this.setState({ userErrorMessage: [] }, async () => {
          // action
          await updateProfile(this.state);
        });
      } else if (selectedService === "CHANGE_PASSWORD") {
        if (
          !validateEmptyString(userCurrentPassword) ||
          !validateEmptyString(userNewPassword) ||
          !validateEmptyString(userConfirmPassword)
        ) {
          singleErrorMessage.push(
            "One or more required fields are missing. Please fill and try again."
          );
          return this.setState({ userErrorMessage: singleErrorMessage });
        }
        if (
          !validatePasswordConfirmation(userNewPassword, userConfirmPassword)
        ) {
          singleErrorMessage.push(
            "Password confirmation failed. Please try to match the password"
          );
          return this.setState({ userErrorMessage: singleErrorMessage });
        }

        this.setState({ errorMessage: [] }, async () => {
          // action
          await changePassword(this.state);
        });
      }
    };

    const errorMessageMapping =
      this.state.userErrorMessage && this.state.userErrorMessage.length > 0
        ? this.state.userErrorMessage.map((SingleError) => {
            return (
              <ul className="list-inside list-disc">
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return selectedService === "EDIT_PROFILE" ? (
      !this.state.pageLoading ? (
        <div className="m-auto w-1/2 font-light">
          <form
            onSubmit={onFormSubmit}
            className="flex flex-col space-y-5 py-10"
          >
            <div className="flex flex-col space-y-3">
              <label className="text-textColor">Full Name</label>
              <input
                type="text"
                name="userFullName"
                id="userFullName"
                placeholder="Full Name"
                value={this.state.userFullName}
                onChange={onHandleChangeInField}
                className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              />
            </div>
            <div className="flex flex-col space-y-3">
              <label className="text-textColor">Email</label>
              <input
                type="text"
                name="userEmail"
                id="userEmail"
                placeholder="Email"
                value={this.state.userEmail}
                onChange={onHandleChangeInField}
                className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              />
            </div>
            <div>{errorMessageMapping}</div>
            {returnedMessage !== "" ? (
              <div className="bg-gray-100 px-3 py-2">
                <p className="text-default">{returnedMessage.new_password}</p>
              </div>
            ) : null}
            <button
              className="bg-default text-white py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
              type="submit"
            >
              {isLoading ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
            </button>
          </form>
        </div>
      ) : (
        <div className="m-auto flex flex-row justify-center items-center">
          <SyncLoader color="#313131" size="8px" />
        </div>
      )
    ) : (
      <div className="m-auto w-1/2 font-light">
        <form onSubmit={onFormSubmit} className="flex flex-col space-y-5 py-10">
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Current Password</label>
            <input
              type="password"
              name="userCurrentPassword"
              id="userCurrentPassword"
              placeholder="Current Password"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">New Password</label>
            <input
              type="password"
              name="userNewPassword"
              id="userNewPassword"
              placeholder="New Password"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Confirm Password</label>
            <input
              type="password"
              name="userConfirmPassword"
              id="userConfirmPassword"
              placeholder="Confirm Password"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="bg-gray-100 px-3 py-2">
              <p className="text-default">{returnedMessage}</p>
            </div>
          ) : null}
          <button
            className="bg-default text-white py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
            type="submit"
          >
            {isLoading ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.user.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (values) => dispatch(changePassword(values)),
    updateProfile: (values) => dispatch(updateProfile(values)),
    resetState: () => dispatch(resetState()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
