import React, { useState, useEffect } from "react";

// External imports
import { Link } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import {isMobileOnly} from "react-device-detect";

// Image imports
import HeroOne from "../../../assets/images/new_arrival.png";
import { apiInstance } from "../../../api/api";

// Hero section
export const HeroSection = () => {
    const [getHero, setHero] = useState([]);
    const [isHeroLoading, setIsHeroLoading] = useState(false);

    useEffect(() => {
        async function fetchHeroImage() {
            setIsHeroLoading(true);
            await apiInstance
                .get("single-hero-image")
                .then((response) => {
                    setHero(response.data.hero_image);
                })
                .catch((error) => console.log(error));
            setIsHeroLoading(false);
        }

        fetchHeroImage();
    }, []);

    return isHeroLoading ? (
        <div className="flex flex-col items-center justify-center w-screen min-h-screen border">
            <SyncLoader color="#313131" size="10" />
        </div>
    ) : (
        <div className="pt-24 border">
            {!isMobileOnly ? <div
                className="flex items-center justify-center w-screen text-4xl text-center text-white md:text-8xl text-bold heroSectionImage"
                style={{
                    backgroundImage: `url(${
                        getHero.image ? getHero.image.public_url : HeroOne
                    })`,
                    backgroundPosition: `${getHero.btn_alignement}`,
                    backgroundSize: "cover",
                    backgroundColor: "rgba(0,0,0,0.3)",
                    backgroundBlendMode: "multiply",
                }}
            >
                <div
                    className="absolute w-full text-left bg-gray-100 border top-2/3 left-1/2 bottom-1/2 md:left-20 sm:top-1/2 md:w-1/4"
                    style={{ backgroundColor: "#f7f7f7" }}
                >
                    <Link
                        to={"/shop"}
                        className="flex flex-col items-center justify-center px-5 py-4 mt-10 text-4xl font-thin text-gray-100 uppercase border hover:bg-transparent hover:text-default hover:border-default"
                    >
                        shop now
                    </Link>
                </div>
            </div> : <div className="flex items-center justify-center w-screen text-4xl text-center text-white md:text-8xl text-bold heroSectionImage"
                style={{
                    backgroundImage: `url(${
                        getHero.image ? getHero.image.public_url : HeroOne
                    })`,
                    backgroundPosition: "right",
                    backgroundSize: "cover",
                    backgroundColor: "rgba(0,0,0,0.3)",
                    backgroundBlendMode: "multiply",
                }}>
                <div
                    className="absolute w-full pl-10 pr-10 text-left border-t top-2/3 md:bottom-1/2 md:left-20 sm:top-1/2 md:w-1/4"
                    // style={{ backgroundColor: "#f7f7f7" }}
                >
                    <Link
                        to={"/shop"}
                        className="flex flex-col items-center justify-center px-5 py-4 mt-10 text-4xl font-thin text-gray-100 uppercase border hover:bg-transparent hover:text-default hover:border-default"
                    >
                        shop now
                    </Link>
                </div>
            </div>}
        </div>
    );
};
