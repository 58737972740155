// Component imports
import {
  ADD_PRODUCT_CATEGORY_SUCCESS,
  ADD_PRODUCT_CATEGORY_FAILED,
  ADD_COLOR_SUCCESS,
  ADD_COLOR_FAILED,
  ADD_SIZE_SUCCESS,
  ADD_SIZE_FAILED,
  EDIT_VARIANT_SUCCESS,
  EDIT_VARIANT_FAILED,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT_FAILED,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILED,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILED,
  ADD_VARIATION_SUCCESS,
  ADD_VARIATION_FAILED,
  EDIT_VARIATION_SUCCESS,
  EDIT_VARIATION_FAILED,
  DELETE_PRODUCT_VARIATION_SUCCESS,
  DELETE_PRODUCT_VARIATION_FAILED,
  EDIT_PRODUCT_CATEGORY_SUCCESS,
  EDIT_PRODUCT_CATEGORY_FAILED,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAILED,
  RESET_PRODUCT_STATE,
} from "../actions/Type";

const initialState = { returnedMessage: "" };

const ProductMgtReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case ADD_PRODUCT_CATEGORY_FAILED:
      return { ...state, returnedMessage: action.payload };
    case EDIT_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case EDIT_PRODUCT_CATEGORY_FAILED:
      return { ...state, returnedMessage: action.payload };
    case DELETE_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case DELETE_PRODUCT_CATEGORY_FAILED:
      return { ...state, returnedMessage: action.payload };
    case ADD_COLOR_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case ADD_COLOR_FAILED:
      return { ...state, returnedMessage: action.payload };
    case ADD_SIZE_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case ADD_SIZE_FAILED:
      return { ...state, returnedMessage: action.payload };
    case EDIT_VARIANT_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case EDIT_VARIANT_FAILED:
      return { ...state, returnedMessage: action.payload };
    case DELETE_VARIANT_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case DELETE_VARIANT_FAILED:
      return { ...state, returnedMessage: action.payload };
    case ADD_PRODUCT_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case ADD_PRODUCT_FAILED:
      return { ...state, returnedMessage: action.payload };
    case EDIT_PRODUCT_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case EDIT_PRODUCT_FAILED:
      return { ...state, returnedMessage: action.payload };
    case DELETE_PRODUCT_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case DELETE_PRODUCT_FAILED:
      return { ...state, returnedMessage: action.payload };
    case ADD_VARIATION_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case ADD_VARIATION_FAILED:
      return { ...state, returnedMessage: action.payload };
    case EDIT_VARIATION_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case EDIT_VARIATION_FAILED:
      return { ...state, returnedMessage: action.payload };
    case DELETE_PRODUCT_VARIATION_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case DELETE_PRODUCT_VARIATION_FAILED:
      return { ...state, returnedMessage: action.payload };
    case RESET_PRODUCT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default ProductMgtReducer;
