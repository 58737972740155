import React, { Component } from "react";

// Component imports
import SideNav from "../../../shared/SideNav";
import {
  validateEmptyString,
  validateOnlyLetter,
} from "../../../shared/Validation";
import {
  createCategory,
  editCategory,
  resetProductState,
} from "../../../../actions/ProductMgtAction";
import { resetLoader } from "../../../../actions/ApplicationAction";

// External imports
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";

function CreateCategory(props) {
  const selectedService = props.location.state.selectedService;
  const selectedProduct = props.location.state.selectedProduct;
  const createCategory = props.createCategory;
  const editCategory = props.editCategory;
  const returnedMessage = props.returnedMessage;
  const resetProductState = props.resetProductState;
  const resetLoader = props.resetLoader;
  const isLoading = props.loaderState;

  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <div className="px-10 w-full">
        <div className="flex flex-row space-x-5 items-center">
          <Link to={"/products"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Category Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-default">
              {selectedService === "ADD_CATEGORY" &&
              typeof selectedService !== "undefined"
                ? "Add Category"
                : selectedService === "EDIT_CATEGORY" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedProduct !== "undefined"
                ? "Edit Category"
                : ""}
            </p>
            <p className="font-thin text-sm">
              {selectedService === "ADD_CATEGORY" &&
              typeof selectedService !== "undefined"
                ? "Create and add new category to store"
                : selectedService === "EDIT_CATEGORY" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedProduct !== "undefined"
                ? "Edit category information on your store"
                : ""}
            </p>
          </div>
        </div>

        {/* Category form */}
        <CategoryForm
          selectedService={selectedService}
          selectedProduct={selectedProduct}
          createCategory={createCategory}
          editCategory={editCategory}
          returnedMessage={returnedMessage}
          resetProductState={resetProductState}
          isLoading={isLoading}
          resetLoader={resetLoader}
        />
      </div>
    </div>
  );
}

class CategoryForm extends Component {
  state = {
    name:
      this.props.selectedService === "EDIT_CATEGORY"
        ? this.props.selectedProduct.title
        : "",
    errorMessage: [],
    stateLoading: false,
  };

  componentWillUnmount() {
    this.props.resetProductState();
    this.props.resetLoader();
  }

  render() {
    //   Accepting props
    const {
      selectedService,
      selectedProduct,
      createCategory,
      editCategory,
      returnedMessage,
      isLoading,
    } = this.props;

    //   On Change
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   On Submit
    const onFormSubmit = async (e) => {
      e.preventDefault();
      const { name } = this.state;
      const singleErrorMessage = [];

      if (!validateEmptyString(name)) {
        singleErrorMessage.push(
          "One or more required fields are missing. Please fill and try again."
        );
        return this.setState({ errorMessage: singleErrorMessage });
      }

      if (!validateOnlyLetter(name)) {
        singleErrorMessage.push("Category name is in wrong format");
        return this.setState({ errorMessage: singleErrorMessage });
      }

      if (selectedService === "ADD_CATEGORY") {
        this.setState({ stateLoading: true, errorMessage: [] }, () => {
          createCategory(this.state);
          this.setState({ stateLoading: false });
        });
      } else if (selectedService === "EDIT_CATEGORY") {
        this.setState({ stateLoading: true, errorMessage: [] }, () => {
          editCategory(this.state, selectedProduct.id);
          this.setState({ stateLoading: false });
        });
      } else {
        singleErrorMessage.push("Unknown operation detected");
        this.setState({ errorMessage: singleErrorMessage });
      }
    };

    //   Error mapping
    const errorMessageMapping =
      this.state.errorMessage && this.state.errorMessage.length > 0
        ? this.state.errorMessage.map((SingleError) => {
            return (
              <ul className="list-inside list-disc" key={SingleError}>
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return (
      <div className="m-auto w-1/2 font-light">
        <form onSubmit={onFormSubmit} className="flex flex-col space-y-5 py-10">
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Category Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={this.state.name}
              placeholder="Category Name"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="bg-gray-100 px-3 py-2">
              <p className="text-default">{returnedMessage}</p>
            </div>
          ) : null}
          <button
            className="bg-default text-white py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
            type="submit"
          >
            {isLoading ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.product.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCategory: (values) => dispatch(createCategory(values)),
    editCategory: (values, selectedCategoryId) =>
      dispatch(editCategory(values, selectedCategoryId)),
    resetProductState: () => dispatch(resetProductState()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory);
