import React from "react";

// External imports
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

function CompletedFormComponent() {
  return (
    <div className="flex flex-col justify-center items-center my-20 space-y-5 text-center font-light">
      <FaCheck size="50px" className="text-gray-400" />
      <p className="text-default text-4xl font-light">Order Completed</p>
      <p>
        Please check your email for the detail of the order.
        <br />
        For customer support, please send us an email on info@kunjina.com
      </p>
      <Link
        to={"/shop"}
        className="bg-default text-white py-2 px-5 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
      >
        Back to Shop
      </Link>
    </div>
  );
}

export default CompletedFormComponent;
