import React, { Component } from "react";

// External imports
import SyncLoader from "react-spinners/SyncLoader";
import { connect } from "react-redux";

// Component imports
import {
  validateEmptyString,
  validateOnlyLetter,
  validateEmail,
  validateFullNameLength,
} from "../../shared/Validation";
import {
  sendContactEmail,
  returnedMessage,
  resetContact,
} from "../../../actions/ContactAction";
import { resetLoader } from "../../../actions/ApplicationAction";

class ContactForm extends Component {
  state = {
    fullName: "",
    email: "",
    message: "",
    errorMessage: [],
    isLoading: false,
  };

  componentWillUnmount() {
    this.props.resetContact();
    this.props.resetLoader();
  }

  render() {
    const { sendContactEmail, returnedMessage, loaderState } = this.props;

    //   on Change
    const onHandleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    // on Submit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      const { fullName, email, message } = this.state;

      const singleErrorMessage = [];

      if (!validateEmptyString(fullName)) {
        singleErrorMessage.push("Full Name is required");
        return this.setState({ errorMessage: singleErrorMessage });
      }
      if (!validateFullNameLength(fullName)) {
        singleErrorMessage.push(
          "Full Name should be less than 50 characters and greater than 5 characters"
        );
        return this.setState({ errorMessage: singleErrorMessage });
      }
      if (!validateOnlyLetter(fullName)) {
        singleErrorMessage.push("Full Name should be letter only");
        return this.setState({ errorMessage: singleErrorMessage });
      }
      if (!validateEmptyString(email)) {
        singleErrorMessage.push("Email is required");
        return this.setState({ errorMessage: singleErrorMessage });
      }
      if (!validateEmail(email)) {
        singleErrorMessage.push("Email is in a wrong format");
        return this.setState({ errorMessage: singleErrorMessage });
      }
      if (!validateEmptyString(message)) {
        singleErrorMessage.push("Message is required");
        return this.setState({ errorMessage: singleErrorMessage });
      }

      this.setState({ errorMessage: [] });
      await sendContactEmail(this.state);
      // this.setState({ isLoading: false });
    };

    //   Mapping error messages
    const errorMessageMapping =
      this.state.errorMessage && this.state.errorMessage.length > 0
        ? this.state.errorMessage.map((SingleError) => {
            return (
              <ul className="list-outside list-disc">
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return (
      <div className="px-20 py-10">
        <form
          className="w-full flex flex-col space-y-6"
          onSubmit={onFormSubmit}
        >
          <input
            className="py-2 border-b border-gray-500 bg-transparent font-light text-lg w-full focus:outline-none"
            placeholder="Name"
            id="fullName"
            name="fullName"
            type="text"
            onChange={onHandleChange}
          />
          <input
            className="py-2 border-b border-gray-500 bg-transparent font-light text-lg w-full focus:outline-none"
            placeholder="Email"
            id="email"
            name="email"
            type="email"
            onChange={onHandleChange}
          />
          <textarea
            className="py-2 border-b border-gray-500 bg-transparent font-light text-lg w-full focus:outline-none"
            placeholder="Message"
            id="message"
            name="message"
            type="text"
            rows="4"
            onChange={onHandleChange}
          ></textarea>
          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="px-3 py-2">
              <p className="text-white font-light">{returnedMessage}</p>
            </div>
          ) : null}
          <div>
            <button
              type="submit"
              className="bg-dark text-white px-8 py-2 uppercase border hover:border-dark hover:bg-light hover:text-dark"
              disabled={loaderState ? true : false}
            >
              {loaderState ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.contact.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendContactEmail: (contactDetails) =>
      dispatch(sendContactEmail(contactDetails)),
    resetContact: () => dispatch(resetContact()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
