import {
  CONTACT_FORM_FAILED,
  CONTACT_FORM_SUCCESS,
  ADD_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_FAILED,
  RESET_CONTACT_FORM,
} from "../actions/Type";

const initialState = {
  returnedMessage: "",
  subscribed: {},
};

const ContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_FORM_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case CONTACT_FORM_FAILED:
      return { ...state, returnedMessage: action.payload };
    case ADD_SUBSCRIBER_SUCCESS:
      return { ...state, subscribed: action.payload };
    case ADD_SUBSCRIBER_FAILED:
      return { ...state, subscribed: action.payload };
    case RESET_CONTACT_FORM:
      return { initialState };
    default:
      return { state };
  }
};

export default ContactReducer;
