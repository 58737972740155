import axios from "axios";

export const apiInstance = axios.create({
    baseURL: "https://api.kunjina.com.et/api/",
    withCredentials: true,
});

// export const apiInstance = axios.create({
//   baseURL: "http://localhost:8000/api/",
//   withCredentials: true,
// });
