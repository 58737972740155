// External imports
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Component imports
import ApplicationReducer from "./ApplicationReducer";
import UserMgtReducer from "./UserMgtReducer";
import ProductMgtReducer from "./ProductMgtReducer";
import CollectionReducer from "./CollectionReducer";
import MediaReducer from "./MediaReducer";
import OrderReducer from "./OrderReducer";
import ContactReducer from "./ContactReducer";
import HeroReducer from "./HeroReducer";

const persistConfig = {
  key: "root",
  storage,
  whiteList: ["order"],
};

const rootReducer = combineReducers({
  app: ApplicationReducer,
  user: UserMgtReducer,
  product: ProductMgtReducer,
  collection: CollectionReducer,
  media: MediaReducer,
  order: OrderReducer,
  contact: ContactReducer,
  hero: HeroReducer,
});

export default persistReducer(persistConfig, rootReducer);
