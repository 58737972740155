import React from "react";

export default function NotFound() {
  return (
    <div className="min-h-screen w-screen flex flex-col justify-center items-center">
      <div className="flex flex-row space-x-10 font-light text-default text-base">
        <p>404</p>
        <p>NOT FOUND</p>
      </div>
    </div>
  );
}
