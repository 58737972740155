import React, { useState, useEffect } from "react";

// External imports
import { Link, useHistory, Redirect } from "react-router-dom";
import { RiAddFill } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { BiMessageSquareEdit, BiMessageSquareDetail } from "react-icons/bi";
import { GiClothes } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import SyncLoader from "react-spinners/SyncLoader";
import { AiFillDelete, AiOutlineDelete } from "react-icons/ai";
import { AgGridReact } from "ag-grid-react";
import { Carousel } from "react-responsive-carousel";
import { connect } from "react-redux";
import Moment from "react-moment";
import Modal from "react-modal";
import parse from "html-react-parser";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "moment-timezone";

// Component imports
import SideNav from "../../../shared/SideNav";
import ViewCategory from "../category/ViewCategory";
import ViewVariants from "../variants/ViewVariants";
import { apiInstance } from "../../../../api/api";
import { numberFormat } from "../../../shared/Variables";
import {
    deleteCategory,
    deleteVariants,
    deleteProduct,
    deleteVariation,
} from "../../../../actions/ProductMgtAction";

function Root(props) {
    const deleteCategory = props.deleteCategory;
    const deleteVariants = props.deleteVariants;
    const deleteProduct = props.deleteProduct;
    const deleteVariations = props.deleteVariation;
    const returnedMessage = props.returnedMessage;

    if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

    return (
        <div>
            <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
                <SideNav />
                <ProductDashContent
                    deleteCategory={deleteCategory}
                    deleteVariants={deleteVariants}
                    deleteProduct={deleteProduct}
                    deleteVariations={deleteVariations}
                    returnedMessage={returnedMessage}
                />
            </div>
        </div>
    );
}

// Product Dash
const ProductDashContent = ({
    deleteCategory,
    deleteVariants,
    deleteProduct,
    deleteVariations,
    returnedMessage,
}) => {
    const [getProduct, setProducts] = useState([]);
    const [toggleDetail, setToggleDetail] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState();
    const [stateLoading, setStateLoading] = useState(false);

    useEffect(() => {
        async function fetchProduct() {
            setStateLoading(true);
            await apiInstance
                .get("products")
                .then((response) => {
                    setProducts(response.data.products);
                })
                .catch((error) => console.log(error));
            setStateLoading(false);
        }

        fetchProduct();
    }, []);

    return !stateLoading ? (
        <div className="bg-light flex flex-row w-full">
            <ProductDashLeftSide
                toggleDetail={toggleDetail}
                setToggleDetail={setToggleDetail}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                deleteCategory={deleteCategory}
                deleteVariants={deleteVariants}
                deleteProduct={deleteProduct}
                deleteVariations={deleteVariations}
                returnedMessage={returnedMessage}
            />
            <ProductDashRightSide
                getProduct={getProduct.length}
                toggleDetail={toggleDetail}
                setToggleDetail={setToggleDetail}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                deleteVariations={deleteVariations}
            />
        </div>
    ) : (
        <div className="flex flex-col justify-center items-center min-h-screen border w-screen">
            <SyncLoader color="#313131" size="10" />
        </div>
    );
};

const ProductDashLeftSide = ({
    toggleDetail,
    setToggleDetail,
    selectedProduct,
    setSelectedProduct,
    deleteCategory,
    deleteVariants,
    deleteProduct,
    deleteVariations,
    returnedMessage,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDeletableField, setSelectedDeletableField] = useState();
    const [selectedDelletableFieldName, setSelectedDelletableFieldName] =
        useState();
    const [toggleCategory, setToggleCategory] = useState("");
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const history = useHistory();
    const colors = [];

    // Ag Grid
    const onGridReady = (params) => {
        apiInstance
            .get("products")
            .then((response) => {
                params.api.applyTransaction({ add: response.data.products });
            })
            .catch((error) => console.log(error));
    };

    const columnDefs = [
        { headerName: "Title", field: "name", checkboxSelection: true },
        {
            headerName: "Description",
            field: "description",
        },
        {
            headerName: "Posted Date",
            field: "comment_at",
            cellRendererFramework: (params) => (
                <Moment date={params.comment_at} format={"DD MMMM, YYYY"} />
            ),
        },
        {
            headerName: "Category",
            field: "categories",
            cellRendererFramework: (params) =>
                params.data.categories.map((SingleData) => (
                    <p key={SingleData.id}>{SingleData.title}</p>
                )),
        },
        { headerName: "Price", field: "price" },
        {
            headerName: "Action",
            field: "id",
            filter: false,
            cellRendererFramework: (params) => (
                <div className="flex flex-row space-x-5 items-center my-2">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setToggleDetail(true);
                            setSelectedProduct(params.data);
                        }}
                        className="py-0 text-default rounded-full bg-transparent"
                    >
                        <BiMessageSquareDetail size="25" />
                    </button>
                    <Link
                        to={{
                            pathname: "products/create",
                            state: {
                                selectedService: "EDIT_PRODUCT",
                                selectedProduct: params.data,
                            },
                        }}
                        className="py-0 text-default rounded-full bg-transparent"
                    >
                        <BiMessageSquareEdit size="25" />
                    </Link>
                    <button
                        className="text-red-400 rounded-full bg-transparent"
                        onClick={() =>
                            openingModalElement(
                                params.data.id,
                                params.data.name
                            )
                        }
                    >
                        <AiFillDelete size="25" />
                    </button>
                </div>
            ),
        },
    ];

    const defaultColDef = {
        filter: true,
        sortable: true,
        floatingFilter: true,
        resizable: true,
        scrollable: true,
    };

    // row selection type
    const rowSelectionType = "single";

    // operation when the row is selected
    const onSelectionChanged = (e) => {
        console.log(e.api.getSelectedRows());
    };

    // opening modal
    const openingModalElement = (selectedField, selectedFieldName) => {
        setIsModalOpen(true);
        setSelectedDeletableField(selectedField);
        setSelectedDelletableFieldName(selectedFieldName);
    };

    // closing Modal
    const closingModalElement = () => {
        setIsModalOpen(false);
        setSelectedDeletableField(null);
        setSelectedDelletableFieldName("");
    };

    const onDeleteClicked = async (id) => {
        setIsDeleteLoading(true);
        await deleteCategory(id);
        setIsDeleteLoading(false);
        window.location.reload();
    };

    const onDeleteVariantClicked = async (id, variantType) => {
        setIsDeleteLoading(true);
        await deleteVariants(id, variantType);
        setIsDeleteLoading(false);
        window.location.reload();
    };

    return (
        <div className="w-5/6 max-h-screen overflow-x-auto">
            {/* Product Header */}
            <div className="flex flex-row justify-between items-center px-5 py-10 space-x-10">
                {toggleCategory === "CATEGORY" ? (
                    <div className="flex flex-col ">
                        <p className="text-4xl font-bold text-default">
                            Category Management
                        </p>
                        <p className="font-thin text-sm">
                            Manage lists of categories in your store
                        </p>
                    </div>
                ) : toggleCategory === "VARIANTS" ? (
                    <div className="flex flex-col ">
                        <p className="text-4xl font-bold text-default">
                            Variant Management
                        </p>
                        <p className="font-thin text-sm">
                            Manage lists of size and color in your store
                        </p>
                    </div>
                ) : (
                    <div className="flex flex-col ">
                        <p className="text-4xl font-bold text-default">
                            Product Management
                        </p>
                        <p className="font-thin text-sm">
                            Manage lists of products in your store
                        </p>
                    </div>
                )}

                <select
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Category Name"
                    onChange={(e) => setToggleCategory(e.target.value)}
                    className="py-3 px-3 w-1/3 bg-transparent font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                >
                    <option value="PRODUCT">Product</option>
                    <option value="CATEGORY">Category</option>
                    <option value="VARIANTS">Variants</option>
                </select>
            </div>
            {/* View Product */}
            {toggleCategory === "CATEGORY" ? (
                <ViewCategory
                    deleteCategory={onDeleteClicked}
                    returnedMessage={returnedMessage}
                />
            ) : toggleCategory === "VARIANTS" ? (
                <ViewVariants
                    deleteVariants={onDeleteVariantClicked}
                    returnedMessage={returnedMessage}
                />
            ) : (
                <div
                    className="ag-theme-material w-full bg-transparent border text-black placeholder-black"
                    style={{ height: "80vh", color: "black" }}
                >
                    <AgGridReact
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        rowSelectionType={rowSelectionType}
                        onSelectionChanged={onSelectionChanged}
                        rowMultiSelectWithClick={true}
                        pagination={true}
                        className="text-left text-black placeholder-black"
                    ></AgGridReact>
                </div>
            )}

            <Modal
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog"
                ariaModal="true"
                ariaLabelledBy="modal-headline"
                ariaHideApp={false}
                isOpen={isModalOpen}
                onRequestClose={() => closingModalElement()}
                contentLabel="Delete Modal"
                style={{
                    overlay: {
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                    },
                    content: {
                        display: "inline-block",
                        alignItems: "bottom",
                        background: "white",
                        borderRadius: "20px",
                        textAlign: "left",
                        overflow: "hidden",
                        position: "fixed",
                        left: "30%",
                        right: "auto",
                        bottom: "auto",
                        top: "30vh",
                    },
                }}
            >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                                className="h-6 w-6 text-red-600"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                            </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                Deleting{" "}
                                <span className="font-bold">
                                    {selectedDelletableFieldName}
                                </span>{" "}
                                from Product
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Are you sure you want to delete{" "}
                                    <span className="font-bold">
                                        {selectedDelletableFieldName}
                                    </span>{" "}
                                    from product? All data will be permanently
                                    removed. This action cannot be undone.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={async () => {
                            await deleteProduct(selectedDeletableField);
                            closingModalElement();
                            window.location.reload();
                        }}
                    >
                        Delete
                    </button>
                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => closingModalElement()}
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
        </div>
    );
};

const ProductDashRightSide = ({
    getProduct,
    toggleDetail,
    setToggleDetail,
    selectedProduct,
    setSelectedProduct,
    deleteVariations,
}) => {
    return toggleDetail ? (
        <div className="w-1/3 bg-white rounded-l-2xl px-10 max-h-screen overflow-y-auto">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-col py-10">
                    <p className="font-thin text-sm">
                        View product detail information
                    </p>
                    <p className="text-4xl font-bold text-default">
                        {selectedProduct.name}
                    </p>
                    {/* <p className="font-thin text-sm">
            Status:
            <span className="text-dark font-bold">
              {" "}
              {selectedProduct.stock_status}
            </span>
          </p> */}
                </div>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        setToggleDetail(false);
                        setSelectedProduct();
                    }}
                >
                    <IoClose />
                </button>
            </div>
            <SelectedProductDetail
                selectedProduct={selectedProduct}
                deleteVariations={deleteVariations}
            />
        </div>
    ) : (
        <div className="w-1/3 bg-white rounded-l-2xl px-10 max-h-screen overflow-y-auto">
            <div className="flex flex-col py-10">
                <p className="font-thin text-sm">
                    Perform utility functions on products
                </p>
                <p className="text-4xl font-bold text-default">Utility</p>
            </div>

            {/* Add Product */}
            <Link
                to={{
                    pathname: "/products/create",
                    state: { selectedService: "ADD_PRODUCT" },
                }}
                className="flex flex-row space-x-5 items-start"
            >
                <div className="bg-gray-100 p-3 rounded-xl">
                    <RiAddFill size="25" className="text-default" />
                </div>
                <div className="text-default">
                    <p className="font-bold">Add Products</p>
                    <p className="text-gray-300 text-sm">
                        Add new product to your store
                    </p>
                </div>
            </Link>

            {/* Add Category */}
            <Link
                to={{
                    pathname: "/category/create",
                    state: { selectedService: "ADD_CATEGORY" },
                }}
                className="flex flex-row space-x-5 items-start mt-8"
            >
                <div className="bg-gray-100 p-3 rounded-xl">
                    <RiAddFill size="25" className="text-default" />
                </div>
                <div className="text-default">
                    <p className="font-bold">Add Category</p>
                    <p className="text-gray-300 text-sm">
                        Add new category to your store
                    </p>
                </div>
            </Link>

            {/* Add Variants */}
            <Link
                to={{
                    pathname: "/variant/create",
                    state: { selectedService: "ADD_VARIANT" },
                }}
                className="flex flex-row space-x-5 items-start mt-8"
            >
                <div className="bg-gray-100 p-3 rounded-xl">
                    <RiAddFill size="25" className="text-default" />
                </div>
                <div className="text-default">
                    <p className="font-bold">Add Variants</p>
                    <p className="text-gray-300 text-sm">
                        Add new variants to your store
                    </p>
                </div>
            </Link>

            {/* Summary */}
            <div className="mt-8">
                <div className="flex flex-row justify-between items-center">
                    <p className="text-default font-bold text-lg">Summary</p>
                </div>
                {/* Each Notice */}
                <div
                    to={"..."}
                    className="flex flex-row space-x-5 items-start mt-8"
                >
                    <div className="bg-gray-100 p-3 rounded-xl">
                        <GiClothes size="30" className="text-default" />
                    </div>
                    <div className="text-default">
                        <p className="font-bold">
                            {numberFormat.format(getProduct)}
                        </p>
                        <p className="text-gray-300 text-sm">
                            Total Products in your Store
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SelectedProductDetail = ({ selectedProduct, deleteVariations }) => {
    // Variables
    const [getColors, setColors] = useState([]);
    const [getSize, setSize] = useState([]);

    useEffect(() => {
        const colors = [...getColors];
        const sizes = [...getSize];

        selectedProduct.colors && selectedProduct.colors.length > 0
            ? selectedProduct.colors.map((SingleColor, index) => {
                  index === 0
                      ? colors.push(SingleColor.color)
                      : colors.push(", " + SingleColor.color);

                  setColors(colors);
              })
            : setColors("");

        selectedProduct.sizes && selectedProduct.sizes.length > 0
            ? selectedProduct.sizes.map((SingleSize, index) => {
                  index === 0
                      ? sizes.push(SingleSize.size)
                      : sizes.push(", " + SingleSize.size);

                  setSize(sizes);
              })
            : setSize("");
    }, []);
    const variationMapping =
        selectedProduct.variations && selectedProduct.variations.length > 0 ? (
            selectedProduct.variations.map((SingleVariation) => {
                return (
                    <div className="flex flex-col space-y-2">
                        <div className="flex flex-row justify-between items-center">
                            <div className="flex flex-col">
                                <div className="flex flex-row space-x-2">
                                    <p className="text-default font-light text-sm">
                                        Size: {SingleVariation.size.size}
                                    </p>
                                    <p className="text-default font-light text-sm">
                                        &
                                    </p>
                                    <p className="text-default font-light text-sm">
                                        Color: {SingleVariation.color.color}
                                    </p>
                                </div>
                                <p className="text-default font-bold text-xs">
                                    {SingleVariation.available_stock} in stock
                                </p>
                            </div>
                            <div className="flex flex-row space-x-2">
                                <Link
                                    to={{
                                        pathname: "/edit-variation",
                                        state: {
                                            selectedProduct: SingleVariation,
                                        },
                                    }}
                                    className="text-default font-light text-sm "
                                >
                                    <FiEdit size="17px" />
                                </Link>
                                <button
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        await deleteVariations(
                                            SingleVariation.id
                                        );
                                        window.location.reload();
                                    }}
                                    className="text-default font-light text-sm "
                                >
                                    <AiOutlineDelete size="17px" />
                                </button>
                            </div>
                        </div>
                        <hr />
                    </div>
                );
            })
        ) : (
            <div className="flex flex-row justify-between items-center">
                <p className="text-default font-light">No Variation Found !</p>
            </div>
        );
    const mappingProductImages =
        selectedProduct.images && selectedProduct.images.length > 0
            ? selectedProduct.images.map((image) => (
                  <div>
                      <img src={image.public_url} alt={selectedProduct.name} />
                  </div>
              ))
            : null;

    return (
        <div className="flex flex-col space-y-5 mb-10">
            <Carousel>{mappingProductImages}</Carousel>

            <div className="flex flex-col space-y-2">
                <div className="flex flex-row justify-between items-center">
                    <p className="text-default font-light text-sm">Size</p>
                    <p className="text-default font-light text-sm">{getSize}</p>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <p className="text-default font-light text-sm">Color</p>
                    <p className="text-default font-light text-sm">
                        {getColors}
                    </p>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <p className="text-default font-light text-sm">Price</p>
                    <p className="text-default font-light text-sm">
                        ${selectedProduct.price}
                    </p>
                </div>
                <div className="flex flex-row justify-between items-center">
                    <p className="text-default font-light text-sm">Category</p>
                    <p className="text-default font-light text-sm">
                        {selectedProduct.categories
                            ? selectedProduct.categories.map(
                                  (SingleCategory) => SingleCategory.title
                              )
                            : ""}
                    </p>
                </div>
            </div>
            <hr />
            <p className="text-default font-light text-sm">
                {selectedProduct.description}
            </p>
            <p className="text-default font-light text-sm">
                {parse(`${selectedProduct.details}`)}
            </p>
            <hr />
            <div className="flex flex-row justify-between items-center">
                <p className="text-default font-bold text-sm">Variations</p>
                <Link
                    to={{
                        pathname: "/create-variation",
                        state: { selectedProduct: selectedProduct },
                    }}
                    className="text-white font-light text-sm rounded-lg bg-default px-4 py-1"
                >
                    Add
                </Link>
            </div>

            <div className="flex flex-col space-y-2">{variationMapping}</div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        returnedMessage: state.product.returnedMessage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCategory: (id) => dispatch(deleteCategory(id)),
        deleteVariants: (id, variantType) =>
            dispatch(deleteVariants(id, variantType)),
        deleteProduct: (id) => dispatch(deleteProduct(id)),
        deleteVariation: (id) => dispatch(deleteVariation(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
