import React, { useState, useEffect } from "react";

// External imports
import { Link, Redirect } from "react-router-dom";
import { RiAddFill } from "react-icons/ri";
import { FiUnlock } from "react-icons/fi";
import { AiOutlineUser, AiFillDelete } from "react-icons/ai";
import { AgGridReact } from "ag-grid-react";
import Modal from "react-modal";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

// Component imports
import SideNav from "../../../shared/SideNav";
import { numberFormat } from "../../../shared/Variables";
import { apiInstance } from "../../../../api/api";

export default function Root() {
  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;
  return (
    <div>
      <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
        <SideNav />
        <UserDashContent />
      </div>
    </div>
  );
}

// User Dashboard Content
const UserDashContent = () => {
  return (
    <div className="bg-light flex flex-row w-full">
      <UserDashLeftSide />
      <UserDashRightSide />
    </div>
  );
};

// User Left Side
const UserDashLeftSide = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeletableField, setSelectedDeletableField] = useState();
  const [selectedDelletableFieldName, setSelectedDelletableFieldName] =
    useState();
  // Ag Grid
  const onGridReady = (params) => {
    apiInstance
      .get("users")
      .then((response) => {
        console.log(response.data.users);
        params.api.applyTransaction({ add: response.data.users });
      })
      .catch((error) => console.log(error));
  };

  const columnDefs = [
    { headerName: "Full Name", field: "name", checkboxSelection: true },
    {
      headerName: "Email",
      field: "email",
    },
    { headerName: "Role", field: "roles.title" },
    {
      headerName: "Action",
      field: "id",
      filter: false,
      cellRendererFramework: (params) => (
        <div className="flex flex-row space-x-5 items-center my-2">
          <Link
            to={{
              pathname: "user/create",
              state: {
                selectedService: "CHANGE_PASSWORD",
                selectedUser: params.data,
              },
            }}
            className="py-0 text-default rounded-full bg-transparent"
          >
            <FiUnlock size="25" />
          </Link>
          <button
            className="text-red-400 rounded-full bg-transparent"
            onClick={() =>
              openingModalElement(params.data.id, params.data.hotelName)
            }
          >
            <AiFillDelete size="25" />
          </button>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    filter: true,
    sortable: true,
    floatingFilter: true,
    resizable: true,
    scrollable: true,
  };

  // row selection type
  const rowSelectionType = "single";

  // operation when the row is selected
  const onSelectionChanged = (e) => {
    console.log(e.api.getSelectedRows());
  };

  // opening modal
  const openingModalElement = (selectedField, selectedFieldName) => {
    setIsModalOpen(true);
    setSelectedDeletableField(selectedField);
    setSelectedDelletableFieldName(selectedFieldName);
  };

  // closing Modal
  const closingModalElement = () => {
    setIsModalOpen(false);
    setSelectedDeletableField(null);
    setSelectedDelletableFieldName("");
  };

  return (
    <div className="w-5/6 max-h-screen overflow-x-auto">
      {/* User Header */}
      <div className="flex flex-col px-5 py-10">
        <p className="text-4xl font-bold text-default">User Management</p>
        <p className="font-thin text-sm">Manage lists of users in your store</p>
      </div>

      {/* View User */}
      <div
        className="ag-theme-material w-full bg-transparent border text-black placeholder-black"
        style={{ height: "80vh", color: "black" }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelectionType={rowSelectionType}
          onSelectionChanged={onSelectionChanged}
          rowMultiSelectWithClick={true}
          pagination={true}
          className="text-left text-black placeholder-black"
        ></AgGridReact>
      </div>
      <Modal
        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        isOpen={isModalOpen}
        onRequestClose={() => closingModalElement()}
        contentLabel="Delete Modal"
        style={{
          content: {
            display: "inline-block",
            alignItems: "bottom",
            background: "white",
            borderRadius: "20px",
            textAlign: "left",
            overflow: "hidden",
            position: "fixed",
            left: "30%",
            right: "auto",
            bottom: "auto",
            top: "30vh",
          },
        }}
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg
                className="h-6 w-6 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Deleting{" "}
                <span className="font-bold">{selectedDelletableFieldName}</span>{" "}
                from Users
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete{" "}
                  <span className="font-bold">
                    {selectedDelletableFieldName}
                  </span>{" "}
                  from users? All data will be permanently removed. This action
                  cannot be undone.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            // onClick={() => deleteUser(this.state.selectedDeletableField)}
          >
            Delete
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => closingModalElement()}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

// Blog Right Side
const UserDashRightSide = () => {
  const [getUsers, setUsers] = useState([]);
  useEffect(() => {
    async function fetchOrders() {
      await apiInstance
        .get("users")
        .then((response) => {
          setUsers(response.data.users);
        })
        .catch((error) => console.log(error));
    }

    fetchOrders();
  }, []);
  return (
    <div className="w-1/3 bg-white rounded-l-2xl px-10 max-h-screen overflow-y-auto">
      <div className="flex flex-col py-10">
        <p className="font-thin text-sm">Perform utility functions on users</p>
        <p className="text-4xl font-bold text-default">Utility</p>
      </div>

      {/* Add User */}
      <Link
        to={{
          pathname: "/user/create",
          state: { selectedService: "ADD_USER" },
        }}
        className="flex flex-row space-x-5 items-start"
      >
        <div className="bg-gray-100 p-3 rounded-xl">
          <RiAddFill size="25" className="text-default" />
        </div>
        <div className="text-default">
          <p className="font-bold">Add User</p>
          <p className="text-gray-300 text-sm">Add new user to your store</p>
        </div>
      </Link>

      {/* Summary */}
      <div className="mt-8">
        <div className="flex flex-row justify-between items-center">
          <p className="text-default font-bold text-lg">Summary</p>
        </div>
        {/* Each Notice */}
        <div to={"..."} className="flex flex-row space-x-5 items-start mt-8">
          <div className="bg-gray-100 p-3 rounded-xl">
            <AiOutlineUser size="30" className="text-default" />
          </div>
          <div className="text-default">
            <p className="font-bold">{numberFormat.format(getUsers.length)}</p>
            <p className="text-gray-300 text-sm">Total Users in your Store</p>
          </div>
        </div>
      </div>

      {/* Recently Added User */}
      {/* <div className="mt-8">
        <div className="flex flex-row justify-between items-center">
          <p className="text-default font-bold text-lg">Recently Added</p>
          <AiOutlineUser className="text-default" size="25" />
        </div>
        {/* Each Notice */}
      {/* <div to={"..."} className="flex flex-row space-x-5 items-start mt-8">
          <div className="bg-gray-100 p-3 rounded-xl">
            <AiOutlineUser size="30" className="text-default" />
          </div>
          <div className="text-default">
            <p className="font-bold">User Name</p>
            <p className="text-gray-300 text-sm">Phone</p>
            <p className="text-gray-300 text-sm">Email</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};
