import React, { useState, useEffect } from "react";

// External imports
import { Link, Redirect } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { BiMessageSquareEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { connect } from "react-redux";

// Component imports
import { apiInstance } from "../../../../api/api";
import { deleteCollectionCategories } from "../../../../actions/CollectionMgtAction";

function ViewCollectionCategory(props) {
  const [getAllCategory, setAllCategory] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const deleteCollectionCategories = props.deleteCollectionCategories;
  const returnedMessage = props.returnedMessage;

  useEffect(() => {
    async function fetchCategories() {
      setIsPageLoading(true);
      await apiInstance.get("collection-categories").then((response) => {
        setAllCategory(response.data.categories);
      });
      setIsPageLoading(false);
    }

    fetchCategories();
  }, []);

  const mappingCategories =
    getAllCategory && getAllCategory.length > 0 ? (
      getAllCategory.map((SingleCategory) => {
        return (
          <div key={SingleCategory.id} className="px-3 w-1/3">
            <div className="bg-light rounded-lg shadow-lg flex flex-row justify-between items-center p-4 mb-4">
              <p className="text-2xl text-default font-light w-1/2">
                {SingleCategory.name}
              </p>
              <div className="flex flex-row items-center justify-center space-x-3 w-1/2">
                <Link
                  to={{
                    pathname: "/admin_collections/create",
                    state: {
                      selectedService: "EDIT_CATEGORY",
                      selectedCollection: SingleCategory,
                    },
                  }}
                  className="rounded-full shadow-lg bg-default p-2"
                >
                  <BiMessageSquareEdit size="15" color="#FFF" />
                </Link>
                <button
                  onClick={async () => {
                    await deleteCollectionCategories(SingleCategory.id);
                    window.location.reload();
                  }}
                  className="rounded-full shadow-lg bg-default p-2"
                >
                  <AiFillDelete size="15" color="#FFF" />
                </button>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="flex justify-center items-center m-auto font-light">
        No Category Found !
      </div>
    );

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  return !isPageLoading ? (
    <div className="px-5 py-10">
      <div className="flex flex-row flex-wrap">{mappingCategories}</div>
    </div>
  ) : (
    <div className="flex flex-row justify-center items-center mt-20">
      <SyncLoader color="#313131" size="8px" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.collection.returnedMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCollectionCategories: (id) =>
      dispatch(deleteCollectionCategories(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewCollectionCategory);
