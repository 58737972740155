import React from "react";

// External imports
import { Link } from "react-router-dom";
import ReactTextCollapse from "react-text-collapse";

// Component imports
import Nav from "../../shared/Nav";
import Footer from "../../shared/Footer";

// Image imports
import AboutImage from "../../../assets/images/About.jpeg";
import KunjinaImage from "../../../assets/images/Founder.jpg";

export default function Root() {
  const TEXT_COLLAPSE_OPTIONS = {
    collapse: false,
    collapseText: "Read More . . . ",
    expandText: "Collapse",
    minHeight: 170,
    maxHeight: 300,
  };
  return (
    <div>
      <Nav />
      <About TEXT_COLLAPSE_OPTIONS={TEXT_COLLAPSE_OPTIONS} />
      <AboutOurFounder TEXT_COLLAPSE_OPTIONS={TEXT_COLLAPSE_OPTIONS} />
      <Footer />
    </div>
  );
}

// Content
const About = ({ TEXT_COLLAPSE_OPTIONS }) => {
  return (
    <div className="flex flex-col-reverse pt-24 lg:flex-row">
      <div className="flex items-center w-full px-5 lg:w-1/2 text-dark lg:px-20 lg:py-24">
        <div>
          <h1 className="text-6xl uppercase font-extralight">About the</h1>
          <h1 className="text-6xl font-bold uppercase">Brand</h1>
          <div className="flex flex-col mt-8 space-y-3 font-light text-md styling-collapse">
            <ReactTextCollapse
              options={TEXT_COLLAPSE_OPTIONS}
              className="flex flex-col space-y-10"
            >
              <p>
                KUNJINA is a ready-to-wear brand that makes comfortable and
                stylish pieces for people within the age range of 20-40.
                Established in 2017, KUNJINA uses inspirations or concepts that
                arise from the designer's personal experience.
              </p>
              <p className="mt-3">
                "I believe when the work comes from a personal place, it
                portrays a true feeling therefore the output becomes Authentic."
                Says Kunjina, founder and creative director of KUNJINA.
              </p>

              <p className="mt-3">
                What makes KUNJINA unique is the crafted details and elements
                used on the garments to Narrate the inspiration of each
                collection. These crafted details include techniques like
                painting on fabrics, embroidery and fabric manipulation. Since
                the designer’s background is in Engineering, the clothes have
                more of a structured form which is one of the characters that
                defines the brand's aesthetic.
              </p>
              <p className="mt-3">
                Each Year KUNJINA produces one main collection that takes
                atleast six months to develop and one other ready-to-wear
                collection that is more commercial. The brand uses mainly
                locally produced fabrics to create cutting edge pieces that make
                a statement. The brand works with local weavers from different
                part of the country to source beautiful 100%cotton woven
                fabrics. KUNJINA strongly believe s in collaborative work with
                like minded people to bring a fresh perspective in the Ethiopian
                fashion industry. The brand also strongly believes in supporting
                the local economy by creating job opportunities for people and
                giving back to the community. Currently, the company has one
                physical store located at century mall, Addis Abeba, Ethiopia.
              </p>
              <p className="mt-3">
                As we are part of the fashion revolution Ethiopia team, we are
                aware of the impacts the fashion industry makes on the
                environment and people that works under it. Therefore we are
                conscious of the materials we use, how we pay our workers and
                how we manage our textile waste. We are not driven by trends and
                seasons when we make our collection, rather we work on our pace
                to make timeless pieces that tell a story. We are also keen on
                informing our customers and local up and coming designers about
                the global fashion industry, it's impacts and how as a
                developing industry we can make a change by focusing on
                transparency and accountability across the value chain.
              </p>
            </ReactTextCollapse>
          </div>
          <div className="mt-10">
            <Link
              to={"/contact"}
              className="px-4 py-4 text-sm text-white uppercase border rounded-lg bg-default hover:bg-transparent hover:text-default hover:border-default"
            >
              contact us
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-end justify-center text-center lg:w-1/2">
        <img src={AboutImage} alt="Kunjina About Us Page" className="" />
      </div>
    </div>
  );
};

const AboutOurFounder = ({ TEXT_COLLAPSE_OPTIONS }) => {
  return (
    <div className="flex flex-col pt-24 lg:flex-row">
      <div className="flex flex-row items-end justify-center text-center lg:w-1/2">
        <img src={KunjinaImage} alt="Kunjina About Us Page" className="" />
      </div>
      <div className="flex items-center w-full px-5 lg:w-1/2 text-dark lg:px-20 lg:py-24">
        <div>
          <h1 className="text-6xl uppercase font-extralight">About the</h1>
          <h1 className="text-6xl font-bold uppercase">Founder</h1>
          <div className="flex flex-col mt-8 space-y-3 font-light text-md styling-collapse">
            <ReactTextCollapse
              options={TEXT_COLLAPSE_OPTIONS}
              className="flex flex-col space-y-10"
            >
              <p>
                Kunjina was one of the lucky ones who discovered her passion at
                a very young age. On weekends, she and her sister used to do
                fashion shows for the family wearing their mother’s clothes
                which were extra-large for them at the time but Kunjina always
                had a way to style the look and make it fit her. When she turned
                14 years old, she started sketching female figures and to
                creatively dress them using lots of shapes and structures; which
                made the clothes look more like an art piece than something
                wearable.
              </p>
              <p className="mt-3">
                Even though her passion lies somewhere else, Kunjina joined
                Addis Ababa University to study construction technology and
                management because her parents wanted her to have a 'plan B'. In
                2017, while she was a fourth-year student in college, she joined
                Wossi International Fashion Institute and took a short course in
                fashion designing. After acquiring some skills from fashion
                school, Kunjina established the brand KUNJINA and started
                promoting it on social media. Then in 2018, which was her senior
                year in college, Kunjina joined the African Mosaique Fashion
                Incubator program which taught her more about branding, business
                plan development and overall, how the international fashion
                industry works. By the end of the program, she made a collection
                named ART'ITECTURE which was based on the collaboration of the
                two worlds she lived as a designer and an Engineering student.
                ART'ITECTURE collection made her win the most creative,
                innovative and promising fashion designer of 2018 award and she
                was also awarded from the American Embassy by being the runner
                up for the Best Fashion Business Model. Kunjina was featured on
                many local TV channels, radio, magazines and also on BBC Amharic
                for her work that collaborated her passion with another field
                she chose to learn to make her parents happy. She was also one
                of the finalists at the 2019 Frankfurt Style Award and one of
                the pieces from her ART'ITECTURE collection was shown on road
                shows in Berlin, Paris and Vancouver.
              </p>

              <p className="mt-3">
                In 2019 Kunjina collaborated with other two Ethiopian designers
                to open up a shop and she has been working full time on her
                brand ever since. Kunjina's aim as a designer is to use fashion
                as a medium of sharing her experiences and her creativity to the
                world. While working on her passion, she wants to support the
                local economy by creating job opportunities for people. And as
                an advocate for Art, she aspires to become someone that can
                inspire new generations of Africa to listen to their callings
                and do what they're passionate about. Also, to prove to them
                that it is possible to follow one's dream, pave their own way
                and become successful!
              </p>
            </ReactTextCollapse>
          </div>
        </div>
      </div>
    </div>
  );
};
