import React, { useState, useEffect } from "react";

// External imports
import { Link } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { BiMessageSquareEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";

// Component imports
import { apiInstance } from "../../../../api/api";

function ViewCategory(props) {
  const [getAllCategory, setAllCategory] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const deleteCategory = props.deleteCategory;
  // const returnedMessage = props.returnedMessage;

  useEffect(() => {
    async function fetchCategories() {
      setIsPageLoading(true);
      await apiInstance.get("categories").then((response) => {
        setAllCategory(response.data.categories);
      });
      setIsPageLoading(false);
    }

    fetchCategories();
  }, []);

  const mappingCategories =
    getAllCategory && getAllCategory.length > 0 ? (
      getAllCategory.map((SingleCategory) => {
        return (
          <div key={SingleCategory.id} className="px-3 w-1/3">
            <div className="bg-light rounded-lg shadow-lg flex flex-row justify-between items-center p-4 mb-4">
              <p className="text-2xl text-default font-light w-1/2">
                {SingleCategory.title}
              </p>
              <div className="flex flex-row items-center justify-center space-x-3 w-1/2">
                <Link
                  to={{
                    pathname: "/category/create",
                    state: {
                      selectedService: "EDIT_CATEGORY",
                      selectedProduct: SingleCategory,
                    },
                  }}
                  className="rounded-full shadow-lg bg-default p-2"
                >
                  <BiMessageSquareEdit size="15" color="#FFF" />
                </Link>
                <button
                  onClick={() => deleteCategory(SingleCategory.id)}
                  className="rounded-full shadow-lg bg-default p-2"
                >
                  <AiFillDelete size="15" color="#FFF" />
                </button>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="flex justify-center items-center">
        No Category Found !
      </div>
    );

  return !isPageLoading ? (
    <div className="px-5 py-10">
      {/* {returnedMessage !== ""
        ? setTimeout(() => {
            return (
              <div className="bg-gray-100 px-3 py-2 flex flex-row justify-between items-center">
                <p className="text-default">{returnedMessage}</p>
                <button onClick={() => window.location.reload()}>Reload</button>
              </div>
            );
          }, 3000)
        : null} */}
      <div className="flex flex-row flex-wrap">{mappingCategories}</div>
    </div>
  ) : (
    <div className="flex flex-row justify-center items-center mt-20">
      <SyncLoader color="#313131" size="8px" />
    </div>
  );
}

export default ViewCategory;
