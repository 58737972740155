import React, { useState, useEffect, Component } from "react";

// External imports
import SyncLoader from "react-spinners/SyncLoader";
import { IoIosClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import clip from "text-clipper";

// Component imports
import Nav from "../../shared/Nav";
import Footer from "../../shared/Footer";
import { numberFormat } from "../../shared/Variables";
import {
  removeFromWishlist,
  getAllWishList,
} from "../../../actions/OrderMgtAction";

function Root(props) {
  const [isPageLoading, setPageLoading] = useState(false);
  const [wishlistFromState, setWishlistFromState] = useState([]);

  const removeFromWishlist = props.removeFromWishlist;
  const wishlist = props.wishlist;

  useEffect(() => {
    setWishlistFromState(wishlist);
  }, [wishlist]);

  return !isPageLoading ? (
    <div className="bg-light pt-24">
      <Nav />
      <Content
        wishlistElements={wishlistFromState}
        removeFromWishlist={removeFromWishlist}
      />
      <Footer />
    </div>
  ) : (
    <div className="min-h-screen w-screen flex flex-col items-center justify-center">
      <SyncLoader color="#313131" />
    </div>
  );
}

class Content extends Component {
  render() {
    const wishlistElements = this.props.wishlistElements;
    const removeFromWishlist = this.props.removeFromWishlist;

    const shoppingWishlistMapping =
      wishlistElements && wishlistElements.length > 0 ? (
        wishlistElements.map((SingleElement) => {
          const clipProductDescription = clip(SingleElement.description, 100, {
            html: true,
            maxLines: 8,
          });
          return (
            <div class="relative rounded overflow-hidden shadow-lg flex-initial bg-white sm:mx-2 md:mx-1 lg:mx-2 w-full sm:w-1/5 lg:pt-0 mb-10 flex flex-col">
              <div>
                <img
                  src={
                    SingleElement.image ? SingleElement.image.public_url : "..."
                  }
                  alt={SingleElement.name}
                  class="w-full object-cover h-32 sm:h-48 md:h-64"
                />
                <IoIosClose
                  size="20"
                  className="absolute right-3 top-3 text-gray-400 cursor-pointer bg-white rounded-full"
                  onClick={async () =>
                    await removeFromWishlist(SingleElement.id)
                  }
                />
              </div>
              <div class="p-4 md:p-6 bg-white flex flex-col flex-1 space-y-3">
                <p className="text-xl font-light mx-3 text-center">
                  {SingleElement.name}{" "}
                </p>
                <div className="flex flex-row justify-center items-center space-x-3 w-full px-3">
                  <p className="text-xl font-light leading-6">
                    {"$ " + numberFormat.format(SingleElement.price)}
                  </p>
                </div>
                <div class="text-sm flex items-center">
                  <p className="text-sm text-gray-400 mx-0 font-light text-center">
                    {clipProductDescription}{" "}
                  </p>
                </div>
              </div>
              <Link
                to={{
                  pathname: "/shop/" + SingleElement.id,
                  state: { selectedProductID: SingleElement.id },
                }}
                className="font-light text-gray-400 text-sm text-center py-2 hover:bg-default hover:text-white"
              >
                Go to Shop
              </Link>
            </div>
          );
        })
      ) : (
        <p className="text-center font-light m-auto">
          No Product in the Wishlist.
        </p>
      );
    return (
      <div className="px-5 lg:px-20 py-10">
        <div className="flex flex-row justify-between items-center">
          <h1 className="font-light text-4xl">Wishlist</h1>
        </div>
        <div class="container mx-auto my-10">
          <div class="flex flex-col sm:flex-row justify-between mx-4 md:mx-0 lg:-mx-2 flex-wrap">
            {shoppingWishlistMapping}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wishlist: state.order.wishlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllWishList: () => dispatch(getAllWishList()),
    removeFromWishlist: (productID) => dispatch(removeFromWishlist(productID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
