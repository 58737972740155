import {
  ADD_HERO_IMAGE_SUCCESS,
  ADD_HERO_IMAGE_FAILED,
  EDIT_HERO_IMAGE_SUCCESS,
  EDIT_HERO_IMAGE_FAILED,
  DELETE_HERO_IMAGE_SUCCESS,
  DELETE_HERO_IMAGE_FAILED,
  RESET_HERO_IMAGE,
} from "./Type";
import { startLoader, stopLoader } from "./ApplicationAction";
import { apiInstance } from "../api/api";

export const createHeroImage = (values) => {
  return (dispatch) => {
    dispatch(startLoader());

    // Operation
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("hero-images", values)
        .then((response) => {
          if (response.status === 201) {
            dispatch(stopLoader());
            dispatch({
              type: ADD_HERO_IMAGE_SUCCESS,
              payload: "Hero Image added successfully.",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: ADD_HERO_IMAGE_FAILED,
              payload:
                "Failed to add hero image. Potential cause of failure is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: ADD_HERO_IMAGE_FAILED,
            payload:
              "Failed to add hero image. Possible cause of failure is: " +
              error,
          });
        });
    });
  };
};

export const editHero = (values, id) => {
  return (dispatch) => {
    dispatch(startLoader());
    // Operation
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("hero-images/" + id, values)
        .then((response) => {
          if (response.status === 200) {
            dispatch(stopLoader());
            dispatch({
              type: EDIT_HERO_IMAGE_SUCCESS,
              payload: "Hero image updated successfully.",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: EDIT_HERO_IMAGE_FAILED,
              payload:
                "Hero image failed to add successfully. Potential cause of failure is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: EDIT_HERO_IMAGE_FAILED,
            payload:
              "Failed to add hero image. Possible cause of failure is: " +
              error,
          });
        });
    });
  };
};

export const deleteHero = (id) => {
  return (dispatch) => {
    // Operation
    apiInstance
      .delete("hero-images/" + id)
      .then((response) => {
        if (response.status === 200)
          dispatch({
            type: DELETE_HERO_IMAGE_SUCCESS,
            payload: "Hero image deleted successfully.",
          });
        else
          dispatch({
            type: DELETE_HERO_IMAGE_FAILED,
            payload:
              "Failed to delete image successfully. Potential cause of failure is internet connectivity or bad request",
          });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_HERO_IMAGE_FAILED,
          payload:
            "Failed to delete image successfully. Potential cause of failure is: " +
            error,
        });
      });
  };
};

export const resetHero = () => {
  return (dispatch) => {
    dispatch({ type: RESET_HERO_IMAGE });
  };
};
