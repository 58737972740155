import React, { useState, useEffect } from "react";

// External imports
import { Link, useHistory, Redirect } from "react-router-dom";
import { RiAddFill, RiChatQuoteLine } from "react-icons/ri";
import { BiMessageSquareEdit, BiMessageSquareDetail } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import SyncLoader from "react-spinners/SyncLoader";
import { AiFillDelete } from "react-icons/ai";
import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";
import Moment from "react-moment";
import Modal from "react-modal";

import "moment-timezone";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

// Component imports
import SideNav from "../../../shared/SideNav";
import { apiInstance } from "../../../../api/api";
import { numberFormat } from "../../../shared/Variables";
import { resetHero, deleteHero } from "../../../../actions/HeroAction";

import HeroSample from "../../../../assets/images/hawi2.png";

function Root(props) {
  const resetHero = props.resetHero;
  const deleteHero = props.deleteHero;

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  return (
    <div>
      <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
        <SideNav />
        <HeroDashContainer resetHero={resetHero} deleteHero={deleteHero} />
      </div>
    </div>
  );
}

// Hero image dash container
const HeroDashContainer = ({ resetHero, deleteHero }) => {
  const [getHero, setHero] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  useEffect(() => {
    async function fetchHero() {
      setStateLoading(true);

      await apiInstance
        .get("hero-images")
        .then((response) => {
          setHero(response.data.hero);
        })
        .catch((error) => console.log(error));

      setStateLoading(false);
    }

    fetchHero();

    return resetHero();
  }, []);

  return !stateLoading ? (
    <div className="bg-light flex flex-row w-full">
      <HeroDashLeftSide getHero={getHero} deleteHero={deleteHero} />
      <HeroDashRightSide />
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center min-h-screen border w-screen">
      <SyncLoader color="#313131" size="10" />
    </div>
  );
};

const HeroDashLeftSide = ({ getHero, deleteHero }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeletableField, setSelectedDeletableField] = useState();
  const [selectedDelletableFieldName, setSelectedDelletableFieldName] =
    useState();
  const [toggleDetail, setToggleDetail] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState();
  const history = useHistory();

  const mappingHeroImage =
    getHero && getHero.length > 0 ? (
      getHero.map((SingleHero) => {
        return (
          <div key={SingleHero.id} className="px-6 w-1/3">
            <div className="bg-light rounded-lg shadow-lg flex flex-col justify-between items-center mb-4">
              <img
                src={SingleHero.image ? SingleHero.image.public_url : null}
                className="text-2xl text-default font-light rounded-t-lg"
                alt={""}
              />
              <div className="text-sm flex items-center p-4">
                <p className="text-sm text-gray-400 mx-0 font-light text-center">
                  {SingleHero.description}
                </p>
              </div>
              <div className="flex flex-row items-center justify-between space-x-3 mb-4 w-1/2">
                <p className="text-sm text-default font-thin">
                  {SingleHero.is_featured ? "Featured" : null}
                </p>
                {/* <div> */}
                <Link
                  to={{
                    pathname: "/admin_hero/create",
                    state: {
                      selectedService: "EDIT_HERO",
                      selectedHero: SingleHero,
                    },
                  }}
                  className="rounded-full shadow-lg bg-default p-2"
                >
                  <BiMessageSquareEdit size="15" color="#FFF" />
                </Link>
                <button
                  onClick={() =>
                    openingModalElement(SingleHero.id, SingleHero.description)
                  }
                  className="rounded-full shadow-lg bg-default p-2"
                >
                  <AiFillDelete size="15" color="#FFF" />
                </button>
                {/* </div> */}
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div>No Hero Image Found</div>
    );

  // opening modal
  const openingModalElement = (selectedField, selectedFieldName) => {
    setIsModalOpen(true);
    setSelectedDeletableField(selectedField);
    setSelectedDelletableFieldName(selectedFieldName);
  };

  // closing Modal
  const closingModalElement = () => {
    setIsModalOpen(false);
    setSelectedDeletableField(null);
    setSelectedDelletableFieldName("");
  };

  const onCloseButtonClicked = () => {
    // e.preventDefault();
    setToggleDetail(false);
    setSelectedBlog();
    history.push("/admin_blog");
  };

  return (
    <div className="w-5/6 max-h-screen overflow-x-auto">
      {/* Blog Header */}
      <div className="flex flex-row justify-between items-center px-5 py-10">
        {toggleDetail ? (
          <>
            <div className="flex flex-col space-y-2 w-2/3">
              <p className="text-4xl font-bold text-default">
                {selectedBlog.title}
              </p>
              <p className="font-thin text-sm">
                Posted at{" "}
                <Moment
                  date={selectedBlog.posted_at}
                  format={"DD MMMM, YYYY"}
                />
              </p>
            </div>
            <button onClick={() => onCloseButtonClicked()}>
              <IoClose size="25" />
            </button>
          </>
        ) : (
          <div className="flex flex-col ">
            <p className="text-4xl font-bold text-default">
              Hero Image Management
            </p>
            <p className="font-thin text-sm">
              Manage lists of image in your front display
            </p>
          </div>
        )}
      </div>

      {toggleDetail ? (
        {
          /* <BlogDetail selectedBlog={selectedBlog} /> */
        }
      ) : (
        <>
          {/* View Blog */}
          <div
            className="ag-theme-material w-full bg-transparent border text-black placeholder-black"
            style={{ height: "80vh", color: "black" }}
          >
            <div className="flex flex-row flex-wrap">{mappingHeroImage}</div>
          </div>
          <Modal
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            isOpen={isModalOpen}
            onRequestClose={() => closingModalElement()}
            contentLabel="Delete Modal"
            style={{
              overlay: {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
              content: {
                display: "inline-block",
                alignItems: "bottom",
                background: "white",
                borderRadius: "20px",
                textAlign: "left",
                overflow: "hidden",
                position: "fixed",
                left: "30%",
                right: "auto",
                bottom: "auto",
                top: "30vh",
              },
            }}
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Deleting{" "}
                    <span className="font-bold">
                      {selectedDelletableFieldName}
                    </span>{" "}
                    from Hero Image
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete{" "}
                      <span className="font-bold">
                        {selectedDelletableFieldName}
                      </span>{" "}
                      from hero image list? All data will be permanently
                      removed. This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={async () => {
                  await deleteHero(selectedDeletableField);
                  window.location.reload();
                }}
              >
                Delete
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => closingModalElement()}
              >
                Cancel
              </button>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

const HeroDashRightSide = () => {
  return (
    <div className="w-1/3 bg-white rounded-l-2xl px-10 max-h-screen overflow-y-auto">
      <div className="flex flex-col py-10">
        <p className="font-thin text-sm">
          Perform utility functions on hero image
        </p>
        <p className="text-4xl font-bold text-default">Utility</p>
      </div>

      {/* Add Blog */}
      <Link
        to={{
          pathname: "/admin_hero/create",
          state: { selectedService: "ADD_HERO" },
        }}
        className="flex flex-row space-x-5 items-start"
      >
        <div className="bg-gray-100 p-3 rounded-xl">
          <RiAddFill size="25" className="text-default" />
        </div>
        <div className="text-default">
          <p className="font-bold">Add Hero Image</p>
          <p className="text-gray-300 text-sm">
            Add new hero image to your store
          </p>
        </div>
      </Link>

      {/* Summary */}
      <div className="mt-8">
        <div className="flex flex-row justify-between items-center">
          <p className="text-default font-bold text-lg">Summary</p>
        </div>
        {/* Each Notice */}
        <div to={"..."} className="flex flex-row space-x-5 items-start mt-8">
          <div className="bg-gray-100 p-3 rounded-xl">
            <RiChatQuoteLine size="30" className="text-default" />
          </div>
          <div className="text-default">
            <p className="font-bold">{numberFormat.format(0)}</p>
            <p className="text-gray-300 text-sm">
              Total Hero Image in your Store
            </p>
          </div>
        </div>
      </div>

      {/* Recently Added Blog */}
      {/* <div className="mt-8">
        <div className="flex flex-row justify-between items-center">
          <p className="text-default font-bold text-lg">Recently Added</p>
          <RiChatQuoteLine className="text-default" size="25" />
        </div> */}
      {/* Each Notice */}
      {/* <div to={"..."} className="flex flex-row space-x-5 items-start mt-8">
          <div className="bg-green-100 p-3 rounded-xl">
            <RiChatQuoteLine size="30" className="text-default" />
          </div>
          <div className="text-default">
            <p className="font-bold">Blog Title</p>
            <p className="text-gray-300 text-sm">Blog Description</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.hero.returnedMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteHero: (id) => dispatch(deleteHero(id)),
    resetHero: () => dispatch(resetHero()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
