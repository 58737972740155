import React, { Component, useEffect, useState } from "react";

// Component imports
import SideNav from "../../../shared/SideNav";
import { apiInstance } from "../../../../api/api";
import { validateEmptyString } from "../../../shared/Validation";
import {
  addVariations,
  resetProductState,
} from "../../../../actions/ProductMgtAction";
import { resetLoader } from "../../../../actions/ApplicationAction";
import NotFound from "../../../shared/NotFound";

// External imports
import SyncLoader from "react-spinners/SyncLoader";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";

function AddVariation(props) {
  const selectedProduct = props.location.state.selectedProduct;
  const addVariations = props.addVariations;
  const resetProductState = props.resetProductState;
  const returnedMessage = props.returnedMessage;
  const resetLoader = props.resetLoader;
  const isLoading = props.loaderState;

  const [getAllSizes, setAllSizes] = useState([]);
  const [getAllColors, setAllColors] = useState([]);

  useEffect(() => {
    async function fetchVariations() {
      await apiInstance
        .get("sizes")
        .then((response) => setAllSizes(response.data.sizes))
        .catch((error) => console.log(error));

      await apiInstance
        .get("colors")
        .then((response) => setAllColors(response.data.colors))
        .catch((error) => console.log(error));
    }

    fetchVariations();
  }, []);

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <div className="px-10 w-full">
        <div className="flex flex-row space-x-5 items-center">
          <Link to={"/products"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Product Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-default">
              Add Product Variation
            </p>
            <p className="font-thin text-sm">
              Add new product variation to store
            </p>
          </div>
        </div>

        {/* Blog form */}
        <VariationForm
          selectedProduct={selectedProduct}
          addVariations={addVariations}
          resetProductState={resetProductState}
          returnedMessage={returnedMessage}
          getAllSizes={getAllSizes}
          getAllColors={getAllColors}
          resetLoader={resetLoader}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

class VariationForm extends Component {
  state = {
    size: "",
    color: "",
    leadingImage: "",
    image: [],
    stock: 0,

    errorMessage: [],

    getAllCategories: [],
    stateLoading: false,
  };

  componentWillUnmount() {
    this.props.resetProductState();
    this.props.resetLoader();
  }

  async componentDidMount() {
    this.setState({ stateLoading: true });

    await apiInstance
      .get("categories")
      .then((response) =>
        this.setState({ getAllCategories: response.data.categories })
      )
      .catch((error) => console.log(error));

    this.setState({ stateLoading: false });
  }

  render() {
    const {
      selectedProduct,
      getAllColors,
      getAllSizes,
      addVariations,
      returnedMessage,
      isLoading,
    } = this.props;

    const onFormSubmit = async (e) => {
      e.preventDefault();
      const singleErrorMessage = [];
      let { color, size, leadingImage, image, stock } = this.state;

      if (
        !validateEmptyString(color) ||
        !validateEmptyString(size) ||
        !validateEmptyString(leadingImage) ||
        !validateEmptyString(image)
      ) {
        singleErrorMessage.push(
          "One or more fields are missing. Please fill and try again"
        );
        return this.setState({ errorMessage: singleErrorMessage });
      }

      this.setState({ errorMessage: [] });

      let variationContent = new FormData();
      const leadingImageQuery = document.querySelector("#leadingImage");
      leadingImage = leadingImageQuery.files[0];

      variationContent.append("color_id", color);
      variationContent.append("size_id", size);
      variationContent.append("leading_image", leadingImage);
      variationContent.append("available_stock", stock);
      image.forEach((images) => variationContent.append("images[]", images));

      // Action
      await addVariations(variationContent, selectedProduct.id);
    };

    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    // Handling onFileChange
    const onHandleFileInput = (e) => {
      let files = e.target.files;
      const values = [];

      for (let i = 0; i < files.length; i++) {
        values.push(files[i]);
      }

      this.setState({ image: values });
    };

    const sizeVariationsMapping =
      getAllSizes && getAllSizes.length > 0
        ? getAllSizes.map((SingleSize) => {
            return (
              <option key={SingleSize.id} value={SingleSize.id}>
                {SingleSize.size}
              </option>
            );
          })
        : null;

    const colorVariationsMapping =
      getAllColors && getAllColors.length > 0
        ? getAllColors.map((SingleColor) => {
            return (
              <option key={SingleColor.id} value={SingleColor.id}>
                {SingleColor.color}
              </option>
            );
          })
        : null;

    const errorMessageMapping =
      this.state.errorMessage && this.state.errorMessage.length > 0
        ? this.state.errorMessage.map((SingleError) => {
            return (
              <ul className="list-inside list-disc" key={SingleError}>
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return (
      <div className="m-auto w-2/3 font-light">
        <form onSubmit={onFormSubmit} className="flex flex-col space-y-5 py-10">
          <div className="flex flex-col space-y-3">
            <select
              name="size"
              id="size"
              value={this.state.size}
              placeholder="Size"
              onChange={(e) => onHandleChangeInField(e)}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              required
            >
              <option value="">Select size</option>
              {sizeVariationsMapping}
            </select>
          </div>
          <div className="flex flex-col space-y-3">
            <select
              name="color"
              id="color"
              value={this.state.color}
              placeholder="Color"
              onChange={(e) => onHandleChangeInField(e)}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              required
            >
              <option value="">Select color</option>
              {colorVariationsMapping}
            </select>
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-default font-light">Leading Image</label>
            <input
              type="file"
              name="leadingImage"
              id="leadingImage"
              placeholder="Leading Image"
              accept="image/*"
              onChange={(e) => onHandleChangeInField(e)}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              required
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-default font-light">Featured Images</label>
            <input
              type="file"
              name="image"
              id="image"
              placeholder="Images"
              multiple
              accept="image/*"
              onChange={(e) => onHandleFileInput(e)}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              required
            />
          </div>
          <input
            type="number"
            name="stock"
            id="stock"
            placeholder="Available Stock"
            multiple
            accept="image/*"
            onChange={(e) => onHandleChangeInField(e)}
            className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            required
          />
          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="bg-gray-100 px-3 py-2">
              <p className="text-default">{returnedMessage}</p>
            </div>
          ) : null}
          <button
            className="bg-default text-white py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
            type="submit"
            disabled={this.state.stateLoading ? true : false}
          >
            {this.state.stateLoading || isLoading ? (
              <SyncLoader color="#FFF" size="8px" />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.product.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addVariations: (values, productID) =>
      dispatch(addVariations(values, productID)),
    resetProductState: () => dispatch(resetProductState()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVariation);
