import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const leulStripePromise = loadStripe(
  "pk_test_51IRtOXE8eS2YFyXjkPpejyDlRMuxeErCgS54mcPE830Akb6Wuero91SMhAAsCw7jxwvquV2ZUzMhXhPIuSok5Tp500Rtp3dqg5"
);
const stripePromise = loadStripe(
  "pk_test_51J0ubCAuSH5LwQQvlphnVWAziZBoYa1Eq8OoZ5hOF7NRsDkAAxWY8Fax8u9SOQitMzvWdLKy5hFXvFg2IKtcax2i00brlSGsHt"
);

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </Elements>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
