import React, { Component } from "react";

// External imports
import { IoIosClose } from "react-icons/io";

// Component imports
import { numberFormat } from "../../shared/Variables";
export class Content extends Component {
  render() {
    const shoppingCartElements = this.props.shoppingCartElements;
    const removeFromCart = this.props.removeFromCart;
    const removeAllFromCart = this.props.removeAllFromCart;
    const productPrice = this.props.productPrice;

    const shoppingCartMapping =
      shoppingCartElements && shoppingCartElements.length > 0 ? (
        shoppingCartElements.map((SingleElement) => {
          return (
            <div
              className="w-full flex flex-row border-b pb-5 mb-10 bg-transparent rounded-lg relative"
              key={SingleElement.id}
            >
              <div className="w-1/5 h-20 relative">
                <img
                  src={
                    SingleElement.image ? SingleElement.image.public_url : "..."
                  }
                  alt={SingleElement.name}
                  className="object-cover h-20"
                />
                <span className="absolute inline-flex items-center justify-center px-2 py-1 -top-2 right-2 text-sm font-bold leading-none text-white bg-default rounded-full">
                  {SingleElement.quantity}
                </span>
              </div>

              <div className="flex flex-col space-y-2 w-full ml-5">
                <div className="flex flex-row justify-between items-center">
                  <p className="text-xl font-light">{SingleElement.name}</p>
                  <IoIosClose
                    size="20"
                    className="absolute right-5 text-gray-400 cursor-pointer"
                    onClick={async () => await removeFromCart(SingleElement.id)}
                  />
                </div>
                <div className="flex flex-row justify-between font-light space-x-3 text-default text-sm">
                  <div className="flex flex-row font-light space-x-3 text-default text-sm">
                    <p className="">
                      Size: {SingleElement.size ? SingleElement.size.size : "-"}
                    </p>
                    <p>&middot;</p>
                    <p className="">
                      Color:{" "}
                      {SingleElement.color ? SingleElement.color.color : "-"}
                    </p>
                  </div>
                  <div className="">
                    <p className="font-bold text-gray-400 text-sm">
                      {"USD " +
                        numberFormat.format(
                          SingleElement.price * SingleElement.quantity
                        )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className="text-center font-light m-auto py-44">
          No Product in the Shopping Cart.
        </p>
      );
    return (
      <div className="my-20 shadow-lg px-5 py-5">
        <div className="flex flex-row justify-between items-center shadow-lg"></div>
        <div className="flex flex-col flex-wrap items-center">
          {shoppingCartMapping}
        </div>

        {/* Summary */}
        <div className="flex flex-col space-y-1 font-light pb-3 border-b">
          <div className="flex flex-row justify-between items-center w-full">
            <p>Subtotal</p>
            <p className="font-bold text-gray-400 text-base">
              {"$ " + numberFormat.format(productPrice)}
            </p>
          </div>
          <div className="flex flex-row justify-between items-center w-full">
            <p>Shipping</p>
            <p className="font-bold text-gray-400 text-base">{"$ 0.0"}</p>
          </div>
          <div className="flex flex-row justify-between items-center w-full">
            <p>Taxes</p>
            <p className="font-bold text-gray-400 text-base">
              {"$ " + numberFormat.format(productPrice * 0.15)}
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-1 font-light mt-3">
          <div className="flex flex-row justify-between items-center w-full">
            <p className="text-xl">Total</p>
            <p className="text-2xl font-bold">
              <span className="text-gray-400 text-sm">USD $ </span>
              <span>
                {numberFormat.format(productPrice + productPrice * 0.15)}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
