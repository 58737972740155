import "./App.css";
import "./assets/css/style.css"; // Custom css style

// External imports
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";

import "react-toastify/dist/ReactToastify.css";

// Component imports
import AppLanding from "./components/layouts/landing/Root";
import Shop from "./components/layouts/shop/Root";
import SpecificShop from "./components/layouts/shop/SpecificProduct";
import Cart from "./components/layouts/cart/ParentRoot";
import Checkout from "./components/layouts/cart/Checkout";
import Collection from "./components/layouts/collection/Root";
import SpecificCollection from "./components/layouts/collection/SpecificCollection";
import Media from "./components/layouts/media/Root";
import SpecificMedia from "./components/layouts/media/SpecificBlog";
import About from "./components/layouts/about/Root";
import Contact from "./components/layouts/contact/Root";
import Wishlist from "./components/layouts/wishlist/Root";
import Login from "./components/layouts/auth/Login/Root";
import Dashboard from "./components/layouts/admin/dashboard/Root";
import Profile from "./components/layouts/admin/profile/EditProfile.js";
import Order from "./components/layouts/admin/orders/Root";
import AdminBlog from "./components/layouts/admin/blog/Root";
import CreateBlog from "./components/layouts/admin/blog/CreateBlog";
import Product from "./components/layouts/admin/product/Root";
import CreateProduct from "./components/layouts/admin/product/CreateProduct";
import Category from "./components/layouts/admin/category/CreateCategory";
import Variant from "./components/layouts/admin/variants/CreateVariants";
import AddVariation from "./components/layouts/admin/product/AddVariation";
import EditVariation from "./components/layouts/admin/product/EditVariation";
import AdminCollection from "./components/layouts/admin/collections/Root";
import CreateCollection from "./components/layouts/admin/collections/CreateCollections";
import HeroImage from "./components/layouts/admin/hero/Root";
import CreateHeroImage from "./components/layouts/admin/hero/CreateHero";
import User from "./components/layouts/admin/user/Root";
import CreateUser from "./components/layouts/admin/user/CreateUser";
import ThankYou from "./components/layouts/contact/Thankyou";

import ScrollToTop from "./components/shared/ScrollToTop";
import Store from "./store/Store";

toast.configure();

function App() {
  return (
    <Provider store={Store.store}>
      <PersistGate persistor={Store.persistor}>
        <Router>
          <ScrollToTop>
            <div className="App">
              <Switch>
                <Route path="/" exact component={AppLanding} />
                <Route path="/shop" exact component={Shop} />
                <Route path="/cart" exact component={Cart} />
                <Route path="/checkout" exact component={Checkout} />
                <Route path="/wishlist" exact component={Wishlist} />
                <Route path="/collection" exact component={Collection} />
                <Route path="/blog" exact component={Media} />
                <Route path="/about" exact component={About} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/login" exact component={Login} />
                <Route path="/thankyou" exact component={ThankYou} />

                {/* Admin routes */}
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/edit" exact component={Profile} />
                <Route path="/orders" exact component={Order} />
                <Route path="/admin_blog" exact component={AdminBlog} />
                <Route path="/admin_blog/create" exact component={CreateBlog} />
                <Route path="/products" exact component={Product} />
                <Route
                  path="/products/create"
                  exact
                  component={CreateProduct}
                />
                <Route
                  path="/create-variation"
                  exact
                  component={AddVariation}
                />
                <Route path="/edit-variation" exact component={EditVariation} />
                <Route path="/category/create" exact component={Category} />
                <Route path="/variant/create" exact component={Variant} />
                <Route
                  path="/admin_collections"
                  exact
                  component={AdminCollection}
                />
                <Route
                  path="/admin_collections/create"
                  exact
                  component={CreateCollection}
                />
                <Route path="/user" exact component={User} />
                <Route path="/user/create" exact component={CreateUser} />
                <Route path="/hero_image" exact component={HeroImage} />
                <Route
                  path="/admin_hero/create"
                  exact
                  component={CreateHeroImage}
                />

                {/* Specific routes */}
                <Route path="/shop/:id" exact component={SpecificShop} />
                <Route
                  path="/collection/:id"
                  exact
                  component={SpecificCollection}
                />
                <Route path="/blog/:id" exact component={SpecificMedia} />

                {/* Admin specific routes */}
              </Switch>
            </div>
          </ScrollToTop>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
