import { START_LOADER, STOP_LOADER, RESET_LOADER } from "../actions/Type";

const initialState = {
  isLoading: false,
};

const ApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADER:
      return { ...state, isLoading: true };
    case STOP_LOADER:
      return { ...state, isLoading: false };
    case RESET_LOADER:
      return initialState;
    default:
      return state;
  }
};

export default ApplicationReducer;
