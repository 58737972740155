import React, { Component } from "react";

// External imports
import Select from "react-select";

// Component imports
import { apiInstance } from "../../../api/api";

export default class FilterForm extends Component {
  state = {
    filterText: "",
    filterCategory: [],

    getProductCategories: [],
    selectedOption: [],
  };

  async componentDidMount() {
    await apiInstance
      .get("categories")
      .then((response) =>
        this.setState(
          { getProductCategories: response.data.categories },
          () => {
            var fetchedOptions = [];
            response.data.categories && response.data.categories.length > 0
              ? response.data.categories.map((category) =>
                  fetchedOptions.push({
                    value: category.title,
                    label: category.title,
                  })
                )
              : (fetchedOptions = []);
            this.setState({ selectOptions: fetchedOptions });
          }
        )
      )
      .catch((error) => console.log(error));
  }
  render() {
    // State
    const { getProductCategories } = this.state;

    //   Props
    const { onFilterActivated } = this.props;

    // On Change
    const onHandleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value }, () =>
        onFilterActivated(this.state)
      );
    };

    const mappingCategories =
      getProductCategories && getProductCategories.length > 0
        ? getProductCategories.map((SingleCategories) => {
            return (
              <option value={SingleCategories.title} key={SingleCategories.id}>
                {SingleCategories.title}
              </option>
            );
          })
        : null;
    return (
      <div className="pt-24">
        <form className="w-full flex flex-row space-x-5 mx-5 lg:mx-20 my-10 h-12">
          <div className="flex flex-col relative w-1/2">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <button className="p-1 focus:outline-none focus:shadow-outline text-default opacity-50">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </span>
            <input
              className="h-full px-16 border text-default font-light placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              placeholder="Search"
              name="filterText"
              id="filterText"
              onChange={onHandleChange}
            />
          </div>
          <div className="flex flex-col w-1/3 z-40">
            <Select
              name="filterCategory"
              className="h-full py-1 px-3 border text-default font-light placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              onChange={(value) => {
                var selected = [];
                for (var option of value) {
                  selected.push(option.value);
                }
                this.setState({ filterCategory: selected }, () =>
                  onFilterActivated(this.state)
                );
              }}
              isMulti
              options={this.state.selectOptions}
              // placeholder="Filter by category"
            />
          </div>
        </form>
      </div>
    );
  }
}
