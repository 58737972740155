import {
  ADD_PRODUCT_CATEGORY_SUCCESS,
  ADD_PRODUCT_CATEGORY_FAILED,
  ADD_COLOR_SUCCESS,
  ADD_COLOR_FAILED,
  ADD_SIZE_SUCCESS,
  ADD_SIZE_FAILED,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILED,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILED,
  ADD_VARIATION_SUCCESS,
  ADD_VARIATION_FAILED,
  EDIT_VARIATION_SUCCESS,
  EDIT_VARIATION_FAILED,
  DELETE_PRODUCT_VARIATION_SUCCESS,
  DELETE_PRODUCT_VARIATION_FAILED,
  EDIT_VARIANT_SUCCESS,
  EDIT_VARIANT_FAILED,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT_FAILED,
  EDIT_PRODUCT_CATEGORY_SUCCESS,
  EDIT_PRODUCT_CATEGORY_FAILED,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAILED,
  RESET_PRODUCT_STATE,
} from "./Type";
import { startLoader, stopLoader } from "./ApplicationAction";
import { apiInstance } from "../api/api";

// Product
export const createProduct = (values, variationData) => {
  return (dispatch) => {
    dispatch(startLoader());
    // Operation
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("products", values)
        .then((response) => {
          if (response.status === 201) {
            // return [{ status: true, productId: response.data.product.id }];
            variationData.forEach(async (variation) => {
              const returnedValues = await assignFormDataValue(variation);
              apiInstance
                .post(
                  "products/" +
                    response.data.product.id +
                    "/product-variations",
                  returnedValues
                )
                .then((response) => {
                  if (response.status === 201) {
                    dispatch(stopLoader());
                    dispatch({
                      type: ADD_PRODUCT_SUCCESS,
                      payload: "Product added successfully",
                    });
                  } else {
                    dispatch(stopLoader());
                    dispatch({
                      type: "Failed to add product. Potential cause of error is internet connectivity or bad request",
                    });
                  }
                })
                .catch((error) => {
                  dispatch(stopLoader());
                  dispatch({
                    type: ADD_PRODUCT_FAILED,
                    payload:
                      "Failed to add product. Possible cause of error is: " +
                      error,
                  });
                });
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: "Failed to add product. Potential cause of error is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: ADD_PRODUCT_FAILED,
            payload:
              "Failed to add product. Possible cause of error is: " + error,
          });
        });
    });
  };
};

const assignFormDataValue = async (variation) => {
  let variationData = new FormData();
  variationData.append("color_id", parseInt(variation.color));
  variationData.append("size_id", parseInt(variation.size));
  variationData.append("leading_image", variation.leadingImage);
  variationData.append("available_stock", variation.stock);
  variation.image.forEach((image, index) => {
    console.log(image, index);
    variationData.append("images[]", image);
  });
  return variationData;
};

export const editProduct = (values, selectedProductID) => {
  return (dispatch) => {
    dispatch(startLoader());

    // Operation
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("products/" + selectedProductID, values)
        .then((response) => {
          if (response.status === 200) {
            dispatch(stopLoader());
            dispatch({
              type: EDIT_PRODUCT_SUCCESS,
              payload: "Product updated successfully.",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: EDIT_PRODUCT_FAILED,
              payload:
                "Failed to update product. Potential cause of failure is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: EDIT_PRODUCT_FAILED,
            payload:
              "Failed to update product. Possible cause of failure is : " +
              error,
          });
        });
    });
  };
};

export const deleteProduct = (id) => {
  return (dispatch) => {
    apiInstance
      .delete("products/" + id)
      .then((response) => {
        if (response.status === 200)
          dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: "Product deleted successfully",
          });
        else
          dispatch({
            type: DELETE_PRODUCT_FAILED,
            payload:
              "Failed to delete product. Potential cause of error is internet connectivity or bad request",
          });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_PRODUCT_FAILED,
          payload:
            "Failed to delete product. Potential cause of error: " + error,
        });
        console.log(error);
      });
  };
};

export const addVariations = (values, productID) => {
  return (dispatch) => {
    dispatch(startLoader());

    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("products/" + productID + "/product-variations", values)
        .then((response) => {
          if (response.status === 201) {
            dispatch(stopLoader());
            dispatch({
              type: ADD_VARIATION_SUCCESS,
              payload: "Variation added successfully",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              ADD_VARIATION_FAILED,
              payload:
                "Failed to add variations. Potential cause of error is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: ADD_VARIATION_FAILED,
            payload:
              "Failed to add variations. Possible cause of error: " + error,
          });
        });
    });
  };
};

export const editVariation = (values, id) => {
  return (dispatch) => {
    dispatch(startLoader());
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("product-variations/" + id, values)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_VARIATION_SUCCESS,
              payload: "Variation updated successfully",
            });
            dispatch(stopLoader());
          } else {
            dispatch({
              type: EDIT_VARIATION_FAILED,
              payload:
                "Failed to update variation. Potential cause of error is internet connectivity or bad request",
            });
            dispatch(stopLoader());
          }
        })
        .catch((error) => {
          dispatch({
            type: EDIT_VARIATION_FAILED,
            payload:
              "Failed to update variation. Potential cause of error is: " +
              error,
          });
          dispatch(stopLoader());
        });
    });
  };
};

export const deleteVariation = (id) => {
  return (dispatch) => {
    apiInstance
      .delete("product-variations/" + id)
      .then((response) => {
        if (response.status === 200)
          dispatch({
            type: DELETE_PRODUCT_VARIATION_SUCCESS,
            payload: "Variation deleted successfully",
          });
        else
          dispatch({
            type: DELETE_PRODUCT_VARIATION_FAILED,
            payload:
              "Failed to delete variation. Potential cause of error is internet connectivity or bad request",
          });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_PRODUCT_VARIATION_FAILED,
          payload:
            "Failed to delete variation. Potential cause of error: " + error,
        });
        console.log(error);
      });
  };
};

// Product category operation
export const createCategory = (values) => {
  return (dispatch) => {
    const inputData = {
      title: values.name,
    };

    dispatch(startLoader());

    // Operation
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("categories", inputData)
        .then((response) => {
          if (response.status === 201) {
            dispatch({
              type: ADD_PRODUCT_CATEGORY_SUCCESS,
              payload: "Category added successfully",
            });
            dispatch(stopLoader());
          } else {
            dispatch({
              type: ADD_PRODUCT_CATEGORY_FAILED,
              payload:
                "Failed to add category. Potential cause is internet connectivity or bad request.",
            });
            dispatch(stopLoader());
          }
        })
        .catch((error) => {
          dispatch({
            type: ADD_PRODUCT_CATEGORY_FAILED,
            payload:
              "Failed to add category. Potential cause is: Error " + error,
          });
          dispatch(stopLoader());
        });
    });
  };
};

export const editCategory = (values, selectedCategoryId) => {
  return (dispatch) => {
    const inputData = {
      title: values.name,
    };

    dispatch(startLoader());

    // Operation
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .put("categories/" + selectedCategoryId, inputData)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: EDIT_PRODUCT_CATEGORY_SUCCESS,
              payload: "Category updated successfully",
            });
            dispatch(stopLoader());
          } else {
            dispatch({
              type: EDIT_PRODUCT_CATEGORY_FAILED,
              payload:
                "Failed to update category. Potential cause is internet connectivity or bad request",
            });
            dispatch(stopLoader());
          }
        })
        .catch((error) => {
          dispatch({
            type: EDIT_PRODUCT_CATEGORY_FAILED,
            payload:
              "Failed to update category. Potential cause of error: " + error,
          });
          dispatch(stopLoader());
        });
    });
  };
};

export const deleteCategory = (id) => {
  return (dispatch) => {
    apiInstance
      .delete("categories/" + id)
      .then((response) => {
        if (response.status === 200)
          dispatch({
            type: DELETE_PRODUCT_CATEGORY_SUCCESS,
            payload: "Category deleted successfully",
          });
        else
          dispatch({
            type: DELETE_PRODUCT_CATEGORY_FAILED,
            payload:
              "Failed to delete category. Potential cause of error is internet connectivity or bad request",
          });
      })
      .catch((error) =>
        dispatch({
          type: DELETE_PRODUCT_CATEGORY_FAILED,
          payload:
            "Failed to delete category. Potential cause of error: " + error,
        })
      );
  };
};

// Product variations - size and color operation
export const createColor = (values) => {
  return (dispatch) => {
    const inputData = {
      color: values.colorName,
    };

    dispatch(startLoader());
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("colors", inputData)
        .then((response) => {
          if (response.status === 201) {
            dispatch({
              type: ADD_COLOR_SUCCESS,
              payload: "Color variant added successfully",
            });
            dispatch(stopLoader());
          } else {
            dispatch({
              type: ADD_COLOR_FAILED,
              payload:
                "Failed to add color variant. Potential error is internet connectivity or bad request",
            });
            dispatch(stopLoader());
          }
        })
        .catch((error) => {
          dispatch({
            type: ADD_COLOR_FAILED,
            payload: "Failed to add color variant. Potential error: " + error,
          });
          dispatch(stopLoader());
        });
    });
  };
};

export const createSize = (values) => {
  return (dispatch) => {
    const inputData = {
      size: values.sizeName,
    };

    dispatch(startLoader());

    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("sizes", inputData)
        .then((response) => {
          if (response.status === 201) {
            dispatch({
              type: ADD_SIZE_SUCCESS,
              payload: "Size variant added successfully.",
            });
            dispatch(stopLoader());
          } else {
            dispatch({
              type: ADD_SIZE_FAILED,
              payload:
                "Failed to add size variant. Potential error is internet connectivity or bad request",
            });
            dispatch(stopLoader());
          }
        })
        .catch((error) => {
          dispatch({
            type: ADD_SIZE_FAILED,
            payload: "Failed to add size variant. Potential error " + error,
          });
          dispatch(stopLoader());
        });
    });
  };
};

export const editVariants = (values, selectedVariantId, choosenVariant) => {
  return (dispatch) => {
    if (choosenVariant === "EDIT_SIZE") {
      const inputData = {
        size: values.sizeName,
      };

      dispatch(startLoader());

      // Operation
      apiInstance.get("csrf-cookie").then(() => {
        apiInstance
          .put("sizes/" + selectedVariantId, inputData)
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: EDIT_VARIANT_SUCCESS,
                payload: "Variant element updated successfully",
              });
              dispatch(stopLoader());
            } else {
              dispatch({
                type: EDIT_VARIANT_FAILED,
                payload:
                  "Failed to update variant element. Potential cause is internet connectivity or bad request",
              });
              dispatch(stopLoader());
            }
          })
          .catch((error) => {
            dispatch({
              type: EDIT_VARIANT_FAILED,
              payload:
                "Failed to update variant element. Potential cause of error: " +
                error,
            });
            dispatch(stopLoader());
          });
      });
    } else if (choosenVariant === "EDIT_COLOR") {
      const inputData = {
        color: values.colorName,
      };

      dispatch(startLoader());

      // Operation
      apiInstance.get("csrf-cookie").then(() => {
        apiInstance
          .put("colors/" + selectedVariantId, inputData)
          .then((response) => {
            if (response.status === 200) {
              dispatch({
                type: EDIT_VARIANT_SUCCESS,
                payload: "Variant element updated successfully",
              });
              dispatch(stopLoader());
            } else {
              dispatch({
                type: EDIT_VARIANT_FAILED,
                payload:
                  "Failed to update variant element. Potential cause is internet connectivity or bad request",
              });
              dispatch(stopLoader());
            }
          })
          .catch((error) => {
            dispatch({
              type: EDIT_VARIANT_FAILED,
              payload:
                "Failed to update variant element. Potential cause of error: " +
                error,
            });
            dispatch(stopLoader());
          });
      });
    }
  };
};

export const deleteVariants = (id, variantType) => {
  return (dispatch) => {
    if (variantType === "DELETE_SIZE") {
      apiInstance
        .delete("sizes/" + id)
        .then((response) => {
          if (response.status === 200)
            dispatch({
              type: DELETE_VARIANT_SUCCESS,
              payload: "Variant element deleted successfully",
            });
          else
            dispatch({
              type: DELETE_VARIANT_FAILED,
              payload:
                "Failed to delete variant element. Potential cause of error is internet connectivity or bad request",
            });
        })
        .catch((error) =>
          dispatch({
            type: DELETE_VARIANT_FAILED,
            payload:
              "Failed to delete variant element. Potential cause of error: " +
              error,
          })
        );
    } else {
      apiInstance
        .delete("colors/" + id)
        .then((response) => {
          if (response.status === 200)
            dispatch({
              type: DELETE_VARIANT_SUCCESS,
              payload: "Variant element deleted successfully",
            });
          else
            dispatch({
              type: DELETE_VARIANT_FAILED,
              payload:
                "Failed to delete variant element. Potential cause of error is internet connectivity or bad request",
            });
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: DELETE_VARIANT_FAILED,
            payload:
              "Failed to delete variant element. Potential cause of error: " +
              error,
          });
        });
    }
  };
};

export const resetProductState = () => {
  return (dispatch) => {
    console.log(RESET_PRODUCT_STATE);
    dispatch({ type: RESET_PRODUCT_STATE });
  };
};
