import React, { useState, useEffect } from "react";

// External imports
import { Link, useHistory, Redirect } from "react-router-dom";
import { RiAddFill, RiChatQuoteLine } from "react-icons/ri";
import { BiMessageSquareEdit, BiMessageSquareDetail } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import SyncLoader from "react-spinners/SyncLoader";
import { AiFillDelete } from "react-icons/ai";
import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";
import Moment from "react-moment";
import "moment-timezone";
import Modal from "react-modal";
import { SRLWrapper } from "simple-react-lightbox";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

// Component imports
import ViewCollectionCategory from "./ViewCollectionCategory";
import SideNav from "../../../shared/SideNav";
import { apiInstance } from "../../../../api/api";
import { numberFormat } from "../../../shared/Variables";
import {
  deleteCollection,
  resetCollection,
} from "../../../../actions/CollectionMgtAction";

function Root(props) {
  const deleteCollection = props.deleteCollection;

  useEffect(() => {
    return props.resetCollection();
  }, []);

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  return (
    <div>
      <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
        <SideNav />
        <CollectionDashContent deleteCollection={deleteCollection} />
      </div>
    </div>
  );
}

// Colelction Dashboard Content
const CollectionDashContent = ({ deleteCollection }) => {
  const [getCollections, setCollections] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  useEffect(() => {
    async function fetchCollections() {
      setStateLoading(true);
      await apiInstance
        .get("collections")
        .then((response) => {
          setCollections(response.data.collections);
        })
        .catch((error) => console.log(error));
      setStateLoading(false);
    }

    fetchCollections();
  }, []);

  return !stateLoading ? (
    <div className="bg-light flex flex-row w-full">
      <CollectionsDashLeftSide deleteCollection={deleteCollection} />
      <CollectionsDashRightSide getCollections={getCollections.length} />
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center min-h-screen border w-screen">
      <SyncLoader color="#313131" size="10" />
    </div>
  );
};

// Collection left side
const CollectionsDashLeftSide = ({ deleteCollection }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeletableField, setSelectedDeletableField] = useState();
  const [selectedDelletableFieldName, setSelectedDelletableFieldName] =
    useState();
  const [toggleDetail, setToggleDetail] = useState(false);
  const [toggleSelection, setToggleSelection] = useState("COLLECTION");
  const [selectedCollection, setSelectedCollection] = useState();
  const history = useHistory();

  // Ag Grid
  const onGridReady = (params) => {
    apiInstance
      .get("collections")
      .then((response) => {
        params.api.applyTransaction({ add: response.data.collections });
      })
      .catch((error) => console.log(error));
  };

  const columnDefs = [
    { headerName: "Title", field: "title", checkboxSelection: true },
    {
      headerName: "Description",
      field: "body",
    },
    { headerName: "Category", field: "category.name" },
    {
      headerName: "Posted Date",
      field: "comment_at",
      cellRendererFramework: (params) => (
        <Moment date={params.created_at} format={"DD MMMM, YYYY"} />
      ),
    },
    {
      headerName: "Action",
      field: "id",
      filter: false,
      cellRendererFramework: (params) => (
        <div className="flex flex-row space-x-5 items-center my-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              setToggleDetail(true);
              setSelectedCollection(params.data);
            }}
            className="py-0 text-default rounded-full bg-transparent"
          >
            <BiMessageSquareDetail size="25" />
          </button>
          <Link
            to={{
              pathname: "admin_collections/create",
              state: {
                selectedService: "EDIT_COLLECTION",
                selectedCollection: params.data,
              },
            }}
            className="py-0 text-default rounded-full bg-transparent"
          >
            <BiMessageSquareEdit size="25" />
          </Link>
          <button
            className="text-red-400 rounded-full bg-transparent"
            onClick={() =>
              openingModalElement(params.data.id, params.data.title)
            }
          >
            <AiFillDelete size="25" />
          </button>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    filter: true,
    sortable: true,
    floatingFilter: true,
    resizable: true,
    scrollable: true,
  };

  // row selection type
  const rowSelectionType = "single";

  // operation when the row is selected
  const onSelectionChanged = (e) => {
    console.log(e.api.getSelectedRows());
  };

  // opening modal
  const openingModalElement = (selectedField, selectedFieldName) => {
    setIsModalOpen(true);
    setSelectedDeletableField(selectedField);
    setSelectedDelletableFieldName(selectedFieldName);
  };

  // closing Modal
  const closingModalElement = () => {
    setIsModalOpen(false);
    setSelectedDeletableField(null);
    setSelectedDelletableFieldName("");
  };

  const onCloseButtonClicked = () => {
    // e.preventDefault();
    setToggleDetail(false);
    setSelectedCollection();
    history.push("/admin_collections");
  };

  return (
    <div className="w-5/6 max-h-screen overflow-x-auto">
      {/* Blog Header */}
      <div className="flex flex-row justify-between items-center px-5 py-10 space-x-10">
        {toggleDetail ? (
          <>
            <div className="flex flex-col space-y-2 w-2/3">
              <p className="text-4xl font-bold text-default">
                {selectedCollection.title}
              </p>
              <p className="font-thin text-sm">
                Posted at{" "}
                <Moment
                  date={selectedCollection.posted_at}
                  format={"DD MMMM, YYYY"}
                />
              </p>
            </div>
            <button onClick={() => onCloseButtonClicked()}>
              <IoClose size="25" />
            </button>
          </>
        ) : toggleSelection === "COLLECTION" ? (
          <div className="flex flex-col ">
            <p className="text-4xl font-bold text-default">
              Collection Management
            </p>
            <p className="font-thin text-sm">
              Manage lists of collections in your store
            </p>
          </div>
        ) : toggleSelection === "CATEGORY" ? (
          <div className="flex flex-col ">
            <p className="text-4xl font-bold text-default">
              Category Management
            </p>
            <p className="font-thin text-sm">
              Manage lists of collection categories in your store
            </p>
          </div>
        ) : null}

        {toggleDetail ? null : (
          <select
            type="text"
            name="name"
            id="name"
            onChange={(e) => setToggleSelection(e.target.value)}
            className="py-3 px-3 w-1/3 bg-transparent font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
          >
            <option value="COLLECTION">Collection</option>
            <option value="CATEGORY">Category</option>
          </select>
        )}
      </div>

      {toggleDetail ? (
        <CollectionDetail selectedCollection={selectedCollection} />
      ) : toggleSelection === "CATEGORY" ? (
        <ViewCollectionCategory />
      ) : (
        <>
          {/* View Blog */}
          <div
            className="ag-theme-material w-full bg-transparent border text-black placeholder-black"
            style={{ height: "80vh", color: "black" }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              rowSelectionType={rowSelectionType}
              onSelectionChanged={onSelectionChanged}
              rowMultiSelectWithClick={true}
              pagination={true}
              className="text-left text-black placeholder-black"
            ></AgGridReact>
          </div>
          <Modal
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            ariaHideApp={false}
            aria-modal="true"
            aria-labelledby="modal-headline"
            isOpen={isModalOpen}
            onRequestClose={() => closingModalElement()}
            contentLabel="Delete Modal"
            style={{
              overlay: {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
              content: {
                display: "inline-block",
                alignItems: "bottom",
                background: "white",
                borderRadius: "20px",
                textAlign: "left",
                overflow: "hidden",
                position: "fixed",
                left: "30%",
                right: "auto",
                bottom: "auto",
                top: "30vh",
              },
            }}
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Deleting{" "}
                    <span className="font-bold">
                      {selectedDelletableFieldName}
                    </span>{" "}
                    from Collections
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete{" "}
                      <span className="font-bold">
                        {selectedDelletableFieldName}
                      </span>{" "}
                      from collection? All data will be permanently removed.
                      This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={async () => {
                  await deleteCollection(selectedDeletableField);
                  window.location.reload();
                }}
              >
                Delete
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => closingModalElement()}
              >
                Cancel
              </button>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

// collection right side
const CollectionsDashRightSide = ({ getCollections }) => {
  return (
    <div className="w-1/3 bg-white rounded-l-2xl px-10 max-h-screen overflow-y-auto">
      <div className="flex flex-col py-10">
        <p className="font-thin text-sm">
          Perform utility functions on collections
        </p>
        <p className="text-4xl font-bold text-default">Utility</p>
      </div>

      {/* Add Collection */}
      <Link
        to={{
          pathname: "/admin_collections/create",
          state: { selectedService: "ADD_COLLECTION" },
        }}
        className="flex flex-row space-x-5 items-start"
      >
        <div className="bg-gray-100 p-3 rounded-xl">
          <RiAddFill size="25" className="text-default" />
        </div>
        <div className="text-default">
          <p className="font-bold">Add Collection</p>
          <p className="text-gray-300 text-sm">
            Add new collection to your store
          </p>
        </div>
      </Link>

      {/* Add Collection Category */}
      <Link
        to={{
          pathname: "/admin_collections/create",
          state: { selectedService: "ADD_CATEGORY" },
        }}
        className="flex flex-row space-x-5 items-start mt-8"
      >
        <div className="bg-gray-100 p-3 rounded-xl">
          <RiAddFill size="25" className="text-default" />
        </div>
        <div className="text-default">
          <p className="font-bold">Add Category</p>
          <p className="text-gray-300 text-sm">
            Add new collection to your store
          </p>
        </div>
      </Link>

      {/* Summary */}
      <div className="mt-8">
        <div className="flex flex-row justify-between items-center">
          <p className="text-default font-bold text-lg">Summary</p>
        </div>
        {/* Each Notice */}
        <div to={"..."} className="flex flex-row space-x-5 items-start mt-8">
          <div className="bg-gray-100 p-3 rounded-xl">
            <RiChatQuoteLine size="30" className="text-default" />
          </div>
          <div className="text-default">
            <p className="font-bold">{numberFormat.format(getCollections)}</p>
            <p className="text-gray-300 text-sm">
              Total Collections in your Store
            </p>
          </div>
        </div>
      </div>

      {/* Recently Added Blog */}
      {/* <div className="mt-8">
        <div className="flex flex-row justify-between items-center">
          <p className="text-default font-bold text-lg">Recently Added</p>
          <RiChatQuoteLine className="text-default" size="25" />
        </div> */}
      {/* Each Notice */}
      {/* <div to={"..."} className="flex flex-row space-x-5 items-start mt-8">
          <div className="bg-green-100 p-3 rounded-xl">
            <RiChatQuoteLine size="30" className="text-default" />
          </div>
          <div className="text-default">
            <p className="font-bold">Blog Title</p>
            <p className="text-gray-300 text-sm">Blog Description</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

const CollectionDetail = ({ selectedCollection }) => {
  const collectionImageMapping = selectedCollection
    ? selectedCollection.images.map((SingleImage) => (
        <>
          {SingleImage.is_leading ? (
            <>
              <img
                src={SingleImage.public_url}
                alt={selectedCollection.title}
              />
              <p className="my-5 mx-10">{selectedCollection.body}</p>
            </>
          ) : null}
        </>
      ))
    : null;

  const featuredImageMapping = selectedCollection
    ? selectedCollection.images.map((SingleImage) => (
        <>
          {!SingleImage.is_leading ? (
            <a href={SingleImage.public_url} className="px-3 pb-5">
              <img
                src={SingleImage.public_url}
                alt=""
                width="200"
                className="h-auto"
              />
            </a>
          ) : null}
        </>
      ))
    : null;

  return (
    <div className="my-5 mx-5 font-light">
      {collectionImageMapping}
      <hr />
      <div className="my-5 mx-10">
        <p className="mb-5 font-bold">Collection Featured Images</p>
        <SRLWrapper>
          <div className="flex flex-row flex-wrap m-auto justify-start">
            {featuredImageMapping}
          </div>
        </SRLWrapper>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.collection.returnedMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCollection: (id) => dispatch(deleteCollection(id)),
    resetCollection: () => dispatch(resetCollection()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
