import {
    GET_ALL_CART_SUCCESS,
    GET_ALL_CART_FAILED,
    ADD_TO_CART_SUCCESS,
    ADD_TO_CART_FAILED,
    REMOVE_FROM_CART_SUCCESS,
    REMOVE_FROM_CART_FAILED,
    REMOVE_ALL_FROM_CART_SUCCESS,
    REMOVE_ALL_FROM_CART_FAILED,
    GET_ALL_WISHLIST_SUCCESS,
    GET_ALL_WISHLIST_FAILED,
    ADD_TO_WISHLIST_SUCCESS,
    ADD_TO_WISHLIST_FAILED,
    REMOVE_FROM_WISHLIST_SUCCESS,
    REMOVE_FROM_WISHLIST_FAILED,
    ORDER_SUCCESS,
    ORDER_FAILED,
    RESET_ORDER_STATE,
    UPDATE_ORDER_STATUS_SUCCESS,
    UPDATE_ORDER_STATUS_FAILED,
} from "./Type";
import { startLoader, stopLoader } from "./ApplicationAction";
import { apiInstance } from "../api/api";

export const getAllCart = () => {
    return (dispatch) => {
        // Operation
        apiInstance
            .get("get-cart")
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: GET_ALL_CART_SUCCESS,
                        payload: response.data.cart,
                    });
                } else
                    dispatch({
                        type: GET_ALL_CART_FAILED,
                        payload:
                            "Failed to get cart data from server. Possible cause of failure is internet connectivity or bad request",
                    });
            })
            .catch((error) =>
                dispatch({
                    type: GET_ALL_CART_FAILED,
                    payload: "Failed to retrieve cart. Error: " + error,
                })
            );
    };
};

export const addToCart = (variationID, quantity) => {
    return (dispatch) => {
        const inputData = {
            quantity: quantity,
        };

        dispatch(startLoader());

        apiInstance.get("csrf-cookie").then(() => {
            apiInstance
                .post("add-to-cart/" + variationID, inputData)
                .then(async (response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: ADD_TO_CART_SUCCESS,
                            payload: "Product added to cart successfully",
                        });
                        dispatch(getAllCart());
                        dispatch(stopLoader());
                    } else {
                        dispatch(stopLoader());
                        dispatch({
                            type: ADD_TO_CART_FAILED,
                            payload:
                                "Failed to add product. Possible cause of failure is internet connectivity or bad request",
                        });
                    }
                })
                .catch((error) => {
                    dispatch(stopLoader());
                    dispatch({
                        type: ADD_TO_CART_FAILED,
                        payload:
                            "Failed to add product. Possible cause of failure is: " +
                            error,
                    });
                });
        });
    };
};

export const removeFromCart = (variationID) => {
    return (dispatch) => {
        apiInstance
            .delete("remove-cart/" + variationID)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: REMOVE_FROM_CART_SUCCESS,
                        payload: "Product removed successfully",
                    });
                    dispatch(getAllCart());
                } else
                    dispatch({
                        type: REMOVE_FROM_CART_FAILED,
                        payload: "Failed to remove product from cart.",
                    });
            })
            .catch((error) =>
                dispatch({
                    type: REMOVE_FROM_CART_FAILED,
                    payload: "Failed to remove product from cart. " + error,
                })
            );
    };
};

export const removeAllFromCart = () => {
    return (dispatch) => {
        console.log("REMOVED CALLED");
        apiInstance.get("csrf-cookie").then(() => {
            apiInstance
                .post("clear-cart")
                .then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: REMOVE_ALL_FROM_CART_SUCCESS,
                            payload: "All cart removed successfully.",
                        });
                        dispatch(getAllCart());
                    } else
                        dispatch({
                            type: REMOVE_ALL_FROM_CART_FAILED,
                            payload: "Failed to remove all products in cart",
                        });
                })
                .catch((error) =>
                    dispatch({
                        type: REMOVE_ALL_FROM_CART_FAILED,
                        payload:
                            "Failed to remove all products in cart. " + error,
                    })
                );
        });
    };
};

export const getAllWishList = () => {
    return (dispatch) => {
        console.log("GET All WISHLIST CALLED");
        // Operation
        apiInstance
            .get("get-wishes")
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: GET_ALL_WISHLIST_SUCCESS,
                        payload: response.data.wish_list,
                    });
                } else
                    dispatch({
                        type: GET_ALL_WISHLIST_FAILED,
                        payload:
                            "Failed to get wishlist data from server. Possible cause of failure is internet connectivity or bad request",
                    });
            })
            .catch((error) =>
                dispatch({
                    type: GET_ALL_WISHLIST_FAILED,
                    payload: "Failed to retrieve wishlist. Error: " + error,
                })
            );
    };
};

export const addWishlist = (productID) => {
    return (dispatch) => {
        apiInstance.get("csrf-cookie").then(() => {
            apiInstance
                .post("add-wish-list/product/" + productID)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: ADD_TO_WISHLIST_SUCCESS,
                            payload: "Product added to wishlist successfully",
                        });
                        dispatch(getAllWishList());
                    } else
                        dispatch({
                            type: ADD_TO_WISHLIST_FAILED,
                            payload:
                                "Failed to add product to wishlist. Potential cause of error is internet connectivity or bad request",
                        });
                })
                .catch((error) =>
                    dispatch({
                        type: ADD_TO_WISHLIST_FAILED,
                        payload:
                            "Failed to add product to wishlist. Possible cause of error is: " +
                            error.response.data.error,
                    })
                );
        });
    };
};

export const removeFromWishlist = (productID) => {
    return (dispatch) => {
        apiInstance.get("csrf-cookie").then(() => {
            apiInstance
                .post("remove-from-wish-list/product/" + productID)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: REMOVE_FROM_WISHLIST_SUCCESS,
                            payload:
                                "Product removed from wishlist successfully",
                        });
                        dispatch(getAllWishList());
                    } else
                        dispatch({
                            type: REMOVE_FROM_WISHLIST_FAILED,
                            payload: "Failed to remove product from wishlist",
                        });
                })
                .catch((error) =>
                    dispatch({
                        type: REMOVE_FROM_WISHLIST_FAILED,
                        payload:
                            "Failed to remove product from wishlist. " + error,
                    })
                );
        });
    };
};

export const orderProduct = (
    billing_details,
    paymentID,
    isShippingSame,
    shipping_details,
    productInformation
) => {
    return (dispatch) => {
        let products = {},
            newProducts = {};

        productInformation.forEach((product) => {
            newProducts = Object.assign({ ...newProducts }, products, {
                [product.id]: product.quantity,
            });
        });

        dispatch(startLoader());

        const inputData = {
            paymentMethodID: paymentID,
            name: billing_details.name,
            email: billing_details.email,
            phone: billing_details.phone,
            address: billing_details.address.line1,
            city: billing_details.address.city,
            state: billing_details.address.state,
            country: billing_details.address.country,
            is_shipping_different: isShippingSame,
            shipping_name: shipping_details.shipping_name,
            shipping_email: shipping_details.shipping_email,
            shipping_phone: shipping_details.shipping_phone,
            shipping_address: shipping_details.shipping_address,
            shipping_city: shipping_details.shipping_city,
            shipping_state: shipping_details.shipping_state,
            shipping_country: shipping_details.shipping_country,
            products: newProducts,
        };

        apiInstance.get("csrf-cookie").then(() => {
            apiInstance
                .post("orders", inputData)
                .then((response) => {
                    if (response.status === 201) {
                        dispatch({
                            type: ORDER_SUCCESS,
                            payload: "Order send successfully",
                        });
                        dispatch(removeAllFromCart());
                        dispatch(stopLoader());
                    } else {
                        dispatch(stopLoader());
                        dispatch({
                            type: ORDER_FAILED,
                            payload:
                                "Failed to order product. Potential cause of error is internet connectivity or bad request",
                        });
                    }
                })
                .catch((error) => {
                    let error_message = "";

                    if (Array.isArray(error.response.data.error_message)) {
                        Object.keys(error.response.data.error_message).map(
                            (key, i) => {
                                error_message +=
                                    error.response.data.error_message[key];
                            }
                        );
                    } else {
                        error_message += error.response.data.error_message;
                    }

                    dispatch({
                        type: ORDER_FAILED,
                        payload: "Failed to order product. " + error_message,
                    });
                    dispatch(stopLoader());
                });
        });
    };
};

export const updateOrderStatus = (orderID, newStatus) => {
    return (dispatch) => {
        const inputData = {
            order_status: newStatus,
            _method: "PUT",
        };

        dispatch(startLoader());

        apiInstance.get("csrf-cookie").then(() => {
            apiInstance
                .post("orders/" + orderID, inputData)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch(stopLoader());
                        dispatch({
                            type: UPDATE_ORDER_STATUS_SUCCESS,
                            payload: "Order updated successfully",
                        });
                    } else {
                        dispatch(stopLoader());
                        dispatch({
                            type: UPDATE_ORDER_STATUS_FAILED,
                            payload:
                                "Failed to update order. Potential cause of error is internet connectivity or bad request",
                        });
                    }
                })
                .catch((error) => {
                    dispatch(stopLoader());
                    dispatch({
                        type: UPDATE_ORDER_STATUS_FAILED,
                        payload:
                            "Failed to update order. Possible cause of error is: " +
                            error.response.data.error,
                    });
                });
        });
    };
};

export const resetOrderState = () => {
    return (dispatch) => {
        dispatch({ type: RESET_ORDER_STATE });
    };
};
