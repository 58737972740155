import React, { Component, useEffect } from "react";

// Component imports
import SideNav from "../../../shared/SideNav";
import { validateEmptyString } from "../../../shared/Validation";
import {
  createMedia,
  editMedia,
  resetMedia,
} from "../../../../actions/MediaMgtAction";
import { resetLoader } from "../../../../actions/ApplicationAction";
import NotFound from "../../../shared/NotFound";

// External imports
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";

function CreateBlog(props) {
  useEffect(() => {
    return () => {
      props.resetMedia();
      props.resetLoader();
    };
  });

  if (typeof props.location.state === "undefined") return <NotFound />;

  // Getting selected service
  const selectedService = props.location.state.selectedService;
  const selectedBlog = props.location.state.selectedBlog;
  // const createMedia = props.createMedia;
  // const editMedia = props.editMedia;
  const returnedMessage = props.returnedMessage;
  const isLoading = props.loaderState;

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <div className="px-10 w-full">
        <div className="flex flex-row space-x-5 items-center">
          <Link to={"/admin_blog"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Blog Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-default">
              {selectedService === "ADD_BLOG" &&
              typeof selectedService !== "undefined"
                ? "Add Media"
                : selectedService === "EDIT_BLOG" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedBlog !== "undefined"
                ? "Edit Media"
                : ""}
            </p>
            <p className="font-thin text-sm">
              {selectedService === "ADD_BLOG" &&
              typeof selectedService !== "undefined"
                ? "Create and add new media to store"
                : selectedService === "EDIT_BLOG" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedBlog !== "undefined"
                ? "Edit media information on your store"
                : ""}
            </p>
          </div>
        </div>

        {/* Blog form */}
        <BlogForm
          selectedService={selectedService}
          selectedBlog={selectedBlog}
          createMedia={props.createMedia}
          editMedia={props.editMedia}
          returnedMessage={returnedMessage}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

class BlogForm extends Component {
  state = {
    blogTitle:
      this.props.selectedService === "ADD_BLOG"
        ? ""
        : this.props.selectedBlog.title,
    blogDescription:
      this.props.selectedService === "ADD_BLOG"
        ? ""
        : this.props.selectedBlog.body,
    leadingImage: "",
    blogUrl:
      this.props.selectedService === "ADD_BLOG"
        ? ""
        : this.props.selectedBlog.url,
    errorMessage: [],

    // stateLoading: false,
  };
  render() {
    let {
      isLoading,
      returnedMessage,
      selectedService,
      createMedia,
      selectedBlog,
      editMedia,
    } = this.props;

    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   const onFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      let { blogTitle, blogDescription, leadingImage, blogUrl } = this.state;
      const singleErrorMessage = [];

      if (selectedService === "ADD_BLOG") {
        if (
          !validateEmptyString(blogTitle) ||
          !validateEmptyString(blogDescription) ||
          !validateEmptyString(leadingImage) ||
          !validateEmptyString(blogUrl)
        ) {
          singleErrorMessage.push(
            "One or more fields are missing. Please fill and try again"
          );
          return this.setState({ errorMessage: singleErrorMessage });
        }

        // Empyting the error message
        this.setState({ errorMessage: [] });

        let blogContent = new FormData();
        const leadingImageQuery = document.querySelector("#leadingImage");
        leadingImage = leadingImageQuery.files[0];

        blogContent.append("title", blogTitle);
        blogContent.append("body", blogDescription);
        blogContent.append("leading_image", leadingImage);
        blogContent.append("url", blogUrl);

        // Action
        await createMedia(blogContent);
      } else if (selectedService === "EDIT_BLOG") {
        if (
          !validateEmptyString(blogTitle) ||
          !validateEmptyString(blogDescription) ||
          !validateEmptyString(blogUrl)
        ) {
          singleErrorMessage.push(
            "One or more fields are missing. Please fill and try again"
          );
          return this.setState({ errorMessage: singleErrorMessage });
        }

        // Emptying the error message state
        this.setState({ errorMessage: [] });

        let blogContent = new FormData();
        const leadingImageQuery = document.querySelector("#leadingImage");
        leadingImage =
          typeof leadingImageQuery.files[0] === "undefined"
            ? ""
            : leadingImageQuery.files[0];

        blogContent.append("title", blogTitle);
        blogContent.append("body", blogDescription);
        blogContent.append("_method", "PUT");
        if (typeof leadingImageQuery.files[0] !== "undefined") {
          blogContent.append("leading_image", leadingImage);
        }
        blogContent.append("url", blogUrl);

        // Action
        await editMedia(blogContent, selectedBlog.id);
      }
    };

    const errorMessageMapping =
      this.state.errorMessage && this.state.errorMessage.length > 0
        ? this.state.errorMessage.map((SingleError) => {
            return (
              <ul className="list-inside list-disc" key={SingleError}>
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return (
      <div className="m-auto w-1/2">
        <form
          onSubmit={onFormSubmit}
          className="flex flex-col space-y-5 py-10 font-light"
        >
          <div className="flex flex-col space-y-3">
            <label className="text-default">Media Title</label>
            <input
              type="text"
              name="blogTitle"
              id="blogTitle"
              placeholder="Media Title"
              value={this.state.blogTitle}
              onChange={onHandleChangeInField}
              className="py-2 px-3 border font-light text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-default">Description</label>
            <textarea
              type="text"
              name="blogDescription"
              id="blogDescription"
              value={this.state.blogDescription}
              placeholder="Description"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            ></textarea>
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-default">Url</label>
            <input
              type="url"
              name="blogUrl"
              id="blogUrl"
              placeholder="Media URL"
              value={this.state.blogUrl}
              onChange={onHandleChangeInField}
              className="py-2 px-3 border font-light text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-default">Media Leading Image</label>
            <input
              type="file"
              name="leadingImage"
              id="leadingImage"
              onChange={onHandleChangeInField}
              className="py-2 px-3 border font-light text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="bg-gray-100 px-3 py-2">
              <p className="text-default">{returnedMessage}</p>
            </div>
          ) : null}
          <button
            className="bg-default text-white border py-2 rounded-lg hover:bg-transparent hover:text-default hover:border-default"
            type="submit"
          >
            {isLoading ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.media.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createMedia: (values) => dispatch(createMedia(values)),
    editMedia: (values, id) => dispatch(editMedia(values, id)),
    resetMedia: () => dispatch(resetMedia()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBlog);
