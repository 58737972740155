import React, { useState, useEffect } from "react";

// External imports
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { FiHeart, FiShoppingCart, FiUser } from "react-icons/fi";
import { HiDotsHorizontal } from "react-icons/hi";

// Image imports
import KunjinaLogoBlack from "../../assets/images/Kunjina_Black.png";

// Component imports
import { getAllCart } from "../../actions/OrderMgtAction";
import { apiInstance } from "../../api/api";

function Nav(props) {
  const [countCart, setCountCart] = useState(0);
  const [getOriginalCart, setOrigionalCart] = useState(0);
  const [countWishlist, setCountWishlist] = useState(0);
  const [getOriginalWishlist, setOriginalWishlist] = useState(0);
  const getAllCart = props.getAllCart;
  const cart = props.cart;
  const wishlist = props.wishlist;
  const cartFromPage = props.cartFromPage;

  useEffect(() => {
    async function fetchAllNavElements() {
      if (cart && cart.length === 0) {
        await apiInstance
          .get("get-cart")
          .then((response) => {
            if (response.status === 200)
              setOrigionalCart(response.data.cart.length);
            else console.log("Error Found !");
          })
          .catch((error) => console.log(error));
      }

      if (wishlist && wishlist.length === 0) {
        await apiInstance
          .get("get-wishes")
          .then((response) => {
            if (response.status === 200)
              setOriginalWishlist(response.data.wishlist.length);
            else console.log("Error Found !");
          })
          .catch((error) => console.log(error));
      }

      cart && setCountCart(cart.length);
      wishlist && setCountWishlist(wishlist.length);
    }

    // Nav responsiveness
    let hamburger = document.getElementById("hamburgerMenuBtn");
    let navbarContainer = document.getElementById("navBarContainer");
    let mobileMenu = document.getElementById("mobileResponsiveMenu");
    // let cartMenu = document.getElementById("mobileResponsiveCartMenu");

    hamburger.addEventListener("click", () => {
      if (mobileMenu.classList.contains("hidden") && window.innerWidth < 1024) {
        mobileMenu.classList.remove("hidden");
        mobileMenu.classList.add(
          "flex",
          "flex-col",
          "text-center",
          "text-white",
          "bg-white",
          "w-full",
          "absolute",
          "top-16",
          "-mx-5",
          "z-0",
          "space-y-5",
          "text-lg",
          "py-5"
        );
        // cartMenu.classList.add(
        //   "flex",
        //   "flex-col",
        //   "text-center",
        //   "text-white",
        //   "bg-red",
        //   "w-full",
        //   "absolute",
        //   "top-32",
        //   "-mx-5",
        //   "z-40",
        //   "space-y-5",
        //   "text-lg",
        //   "py-5"
        // );
        navbarContainer.classList.remove("bg-gray-50", "opacity-60");
        navbarContainer.classList.add("bg-white");
      } else {
        mobileMenu.classList.add("hidden");
        // cartMenu.classList.add("hidden");
      }
    });

    mobileMenu.addEventListener("click", () => {
      mobileMenu.classList.add("hidden");
      // cartMenu.classList.add("hidden");
    });

    window.addEventListener("resize", () => {
      if (window.innerWidth > 1024) {
        mobileMenu.classList.add("hidden");
        // cartMenu.classList.add("hidden");
        mobileMenu.classList.remove(
          "flex",
          "flex-col",
          "text-center",
          "text-white",
          "bg-primary",
          "w-full",
          "absolute",
          "top-20",
          "right-0",
          "left-0",
          "z-40",
          "space-y-5",
          "text-lg",
          "py-5"
        );
        // cartMenu.classList.remove(
        //   "flex",
        //   "flex-col",
        //   "text-center",
        //   "text-white",
        //   "bg-primary",
        //   "w-full",
        //   "absolute",
        //   "top-44",
        //   "right-0",
        //   "left-0",
        //   "z-40",
        //   "space-y-5",
        //   "text-lg",
        //   "py-5"
        // );
      }
    });

    fetchAllNavElements();
  }, [cart, wishlist]);

  return (
    <div
      className="flex flex-row justify-between items-center bg-gray-100 px-5 py-3 lg:px-20 lg:py-3 fixed z-50 h-24 top-0 w-screen"
      id="navBarContainer"
    >
      <div className="flex flex-row justify-start items-center space-x-10 w-1/3 lg:w-1/6">
        <img
          src={KunjinaLogoBlack}
          alt="Kunjina Fashion Ecommerce"
          className=""
        />
      </div>
      <button
        id="hamburgerMenuBtn"
        className="lg:hidden bg-transparent text-default"
      >
        <HiDotsHorizontal size="20" />
      </button>
      <div
        className="hidden lg:flex lg:flex-row lg:justify-center lg:space-x-10 lg:items-center lg:pl-20"
        id="mobileResponsiveMenu"
      >
        <GenerateNavLink to={"/"} linkText={"Home"} />
        <GenerateNavLink to={"/shop"} linkText={"Shop"} />
        {/* <GenerateDropDownNavLink to={"/shop"} linkText={"Shop"} /> */}
        <GenerateNavLink to={"/about"} linkText={"About"} />
        <GenerateNavLink to={"/collection"} linkText={"Collection"} />
        <GenerateNavLink to={"/blog"} linkText={"Media"} />
        <GenerateNavLink to={"/contact"} linkText={"Contact"} />
      </div>
      <div
        className="flex flex-row justify-end items-center space-x-4 sm:space-x-6 w-1/6 sm:w-1/5"
        id="mobileResponsiveCartMenu"
      >
        <Link to={"/wishlist"} className="flex flex-row items-end space-x-1">
          <FiHeart size="20" />
          <div className="text-dark font-extrabold text-xs">
            {countWishlist < getOriginalWishlist
              ? getOriginalWishlist.length
              : countWishlist}
          </div>
        </Link>
        <Link to={"/cart"} className="flex flex-row items-end space-x-1">
          <FiShoppingCart size="20" />
          <div className="text-dark font-extrabold text-xs">
            {countCart < getOriginalCart ? getOriginalCart.length : countCart}
          </div>
        </Link>
        <Link to={"/login"}>
          <FiUser size="20" />
        </Link>
      </div>
    </div>
  );
}

const GenerateNavLink = ({ to, linkText }) => {
  return (
    <NavLink
      to={to}
      exact
      className="text-default text-sm uppercase font-light"
      activeClassName="active"
    >
      {linkText}
    </NavLink>
  );
};

const GenerateDropDownNavLink = ({ to, linkText, dropDownElement }) => {
  // FC State
  const [getDropDownState, setDropDownState] = useState(false);
  // DropDown Element Rendering
  const dropDownItemMapping =
    dropDownElement && dropDownElement.length > 0
      ? dropDownElement.map((DropdownItem) => {
          return (
            <Link
              to={"/" + DropdownItem.name}
              className="text-gray-700 block px-4 py-2 text-sm"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-0"
              key={DropdownItem.id}
            >
              {DropdownItem.name}
            </Link>
          );
        })
      : null;
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-between text-sm font-light uppercase focus:outline-none"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          <NavLink to={to} activeClassName="active">
            {linkText}
          </NavLink>
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            onClick={() => setDropDownState(!getDropDownState)}
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {getDropDownState ? (
        <div
          className={
            "origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          }
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            {dropDownItemMapping}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.order.cart,
    wishlist: state.order.wishlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCart: () => dispatch(getAllCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
