import {
  ADD_COLLECTION_CATEGORY_SUCCESS,
  ADD_COLLECTION_CATEGORY_FAILED,
  ADD_COLLECTION_SUCCESS,
  ADD_COLLECTION_FAILED,
  EDIT_COLLECTION_CATEGORY_SUCCESS,
  EDIT_COLLECTION_CATEGORY_FAILED,
  EDIT_COLLECTION_SUCCESS,
  EDIT_COLLECTION_FAILED,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAILED,
  DELETE_COLLECTION_CATEGORY_SUCCESS,
  DELETE_COLLECTION_CATEGORY_FAILED,
  RESET_COLLECTION,
} from "./Type";
import { startLoader, stopLoader } from "./ApplicationAction";
import { apiInstance } from "../api/api";

export const createCollectionCategory = (values) => {
  return (dispatch) => {
    const inputData = {
      name: values.categoryTitle,
    };

    dispatch(startLoader());

    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("collection-categories", inputData)
        .then((response) => {
          if (response.status === 201) {
            dispatch(stopLoader());
            dispatch({
              type: ADD_COLLECTION_CATEGORY_SUCCESS,
              payload: "Collection category added successfully",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: ADD_COLLECTION_CATEGORY_FAILED,
              payload:
                "Failed to add collection category. Potential cause of failure is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: ADD_COLLECTION_CATEGORY_FAILED,
            payload:
              "Failed to add collection category. Possible cause of failure is : " +
              error,
          });
        });
    });
  };
};

export const createCollection = (values, categoryID) => {
  return (dispatch) => {
    dispatch(startLoader());

    // Operation

    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("collection-categories/" + categoryID + "/collections", values)
        .then((response) => {
          if (response.status === 201) {
            dispatch(stopLoader());
            dispatch({
              type: ADD_COLLECTION_SUCCESS,
              payload: "Collection added successfully.",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: ADD_COLLECTION_FAILED,
              payload:
                "Failed to add collection. Potential cause of failure is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: ADD_COLLECTION_FAILED,
            payload:
              "Failed to add collection. Possible cause of failure is: " +
              error,
          });
        });
    });
  };
};

export const editCollectionCategory = (values, categoryID) => {
  return (dispatch) => {
    const inputData = {
      name: values.categoryTitle,
    };

    dispatch(startLoader());
    // Operation
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .put("collection-categories/" + categoryID, inputData)
        .then((response) => {
          if (response.status === 200) {
            dispatch(stopLoader());
            dispatch({
              type: EDIT_COLLECTION_CATEGORY_SUCCESS,
              payload: "Collection category updated successfully.",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: EDIT_COLLECTION_CATEGORY_FAILED,
              payload:
                "Failed to update collection category. Potential cause of failure is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: EDIT_COLLECTION_CATEGORY_FAILED,
            payload:
              "Failed to update collection category. Possible cause of failure is : " +
              error,
          });
        });
    });
  };
};

export const editCollection = (values, categoryID) => {
  return (dispatch) => {
    dispatch(startLoader());

    // Operation
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("collections/" + categoryID, values)
        .then((response) => {
          if (response.status === 200) {
            dispatch(stopLoader());
            dispatch({
              type: EDIT_COLLECTION_SUCCESS,
              payload: "Collection updated successfully",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: EDIT_COLLECTION_FAILED,
              payload:
                "Failed to update collection. Potential cause of failure is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: EDIT_COLLECTION_FAILED,
            payload:
              "Failed to update collection. Possible cause of failure is: " +
              error,
          });
        });
    });
  };
};

export const deleteCollection = (id) => {
  return (dispatch) => {
    // Operation
    apiInstance
      .delete("collections/" + id)
      .then((response) => {
        if (response.status === 200)
          dispatch({
            type: DELETE_COLLECTION_SUCCESS,
            payload: "Product collection successfully",
          });
        else
          dispatch({
            type: DELETE_COLLECTION_FAILED,
            payload:
              "Failed to delete collection. Potential cause of error is internet connectivity or bad request",
          });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_COLLECTION_FAILED,
          payload:
            "Failed to delete collection. Potential cause of error: " + error,
        });
        console.log(error);
      });
  };
};

export const deleteCollectionCategories = (id) => {
  return (dispatch) => {
    // Operation
    apiInstance
      .delete("collection-categories/" + id)
      .then((response) => {
        if (response.status === 200)
          dispatch({
            type: DELETE_COLLECTION_CATEGORY_SUCCESS,
            payload: "Collection category deleted successfully",
          });
        else
          dispatch({
            type: DELETE_COLLECTION_CATEGORY_FAILED,
            payload:
              "Failed to delete collection category. Potential cause of failure is internet connectivity or bad request",
          });
      })
      .catch((error) =>
        dispatch({
          type: DELETE_COLLECTION_CATEGORY_FAILED,
          payload:
            "Failed to delete collection category. Potential cause of failure is: " +
            error,
        })
      );
  };
};

export const resetCollection = () => {
  return (dispatch) => {
    dispatch({ type: RESET_COLLECTION });
  };
};
