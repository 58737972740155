import React, { useEffect, useState } from "react";

// External imports
import SyncLoader from "react-spinners/SyncLoader";
import Moment from "react-moment";
import { SRLWrapper } from "simple-react-lightbox";

import "moment-timezone";

// Component imports
import Nav from "../../shared/Nav";
import NotFound from "../../shared/NotFound";
import Footer from "../../shared/Footer";
import { apiInstance } from "../../../api/api";

export default function SpecificCollection(props) {
  const [isPageLoading, setPageLoading] = useState(false);
  const [selectedCollectionDetail, setSelectedCollectionDetail] = useState();
  const selectedCollectionID = props.location.state.selectedCollectionID
    ? props.location.state.selectedCollectionID
    : "";

  useEffect(() => {
    async function fetchSingleCollection() {
      setPageLoading(true);
      await apiInstance
        .get("collections/" + selectedCollectionID)
        .then((response) => {
          setSelectedCollectionDetail(response.data.collection);
        })
        .catch((error) => console.log(error));
      setPageLoading(false);
    }

    fetchSingleCollection();
  }, [selectedCollectionID]);

  return selectedCollectionID !== "" ? (
    !isPageLoading ? (
      selectedCollectionDetail ? (
        <div>
          <Nav />
          <Content selectedCollectionDetail={selectedCollectionDetail} />
          <Footer />
        </div>
      ) : (
        <NotFound />
      )
    ) : (
      <div className="min-h-screen w-screen flex flex-col items-center justify-center">
        <SyncLoader color="#313131" />
      </div>
    )
  ) : (
    <NotFound />
  );
}

const Content = ({ selectedCollectionDetail }) => {
  return (
    <div className="px-5 lg:px-20 py-20 flex flex-col justify-center items-center w-screen text-default">
      <div className="my-10 text-center flex flex-col space-y-3 w-2/3">
        <p className="font-light">
          Published{" "}
          <Moment
            date={selectedCollectionDetail.created_at}
            format={"MMMM DD, YYYY"}
          />
        </p>
        <p className="font-bold text-3xl px-10">
          {selectedCollectionDetail.title}
        </p>
      </div>
      {selectedCollectionDetail.images
        ? selectedCollectionDetail.images.map((SingleImage) => {
            if (SingleImage.is_leading)
              return (
                <img
                  src={SingleImage.public_url}
                  alt={selectedCollectionDetail.name}
                  className="lg:w-2/3"
                />
              );
          })
        : "..."}

      <div className="lg:w-2/3 my-20 px-5 lg:px-10 font-light">
        {selectedCollectionDetail.body}
        <hr className="mt-10" />
      </div>

      <SRLWrapper>
        <div className="flex flex-row flex-wrap m-auto justify-center">
          {selectedCollectionDetail.images
            ? selectedCollectionDetail.images.map((SingleImage) => {
                if (!SingleImage.is_leading)
                  return (
                    <a href={SingleImage.public_url} className="px-3 pb-5">
                      <img
                        src={SingleImage.public_url}
                        alt=""
                        width="200"
                        className="h-auto"
                      />
                    </a>
                  );
              })
            : "..."}
        </div>
      </SRLWrapper>
    </div>
  );
};
