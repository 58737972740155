import {
  CONTACT_FORM_FAILED,
  CONTACT_FORM_SUCCESS,
  ADD_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_FAILED,
  RESET_CONTACT_FORM,
} from "./Type";
import { startLoader, stopLoader } from "./ApplicationAction";
import { apiInstance } from "../api/api";

export const addToSubscribeList = (email) => {
  const inputData = {
    email: email,
  };

  return (dispatch) => {
    dispatch(startLoader());

    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("subscribers", inputData)
        .then((response) => {
          if (response.status === 201) {
            dispatch(stopLoader());
            dispatch({
              type: ADD_SUBSCRIBER_SUCCESS,
              payload: { status: true },
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: ADD_SUBSCRIBER_FAILED,
              payload: {
                status: false,
                message: "Failed to add subscriber. Please try again !",
              },
            });
          }
        })
        .catch((error) => {
          let error_message = "";
          Object.keys(error.response.data.error_message).map((key, i) => {
            error_message = error.response.data.error_message[key];
          });
          dispatch({
            type: ADD_SUBSCRIBER_FAILED,
            payload: {
              status: false,
              message: error_message,
            },
          });
          dispatch(stopLoader());
        });
    });
  };
};

export const sendContactEmail = (contactDetails) => {
  return (dispatch) => {
    dispatch(startLoader());

    const inputData = {
      name: contactDetails.fullName,
      email: contactDetails.email,
      message: contactDetails.message,
    };

    // Dispatching request to api
    apiInstance.get("csrf-cookie").then(() => {
      apiInstance
        .post("contact", inputData)
        .then((response) => {
          if (response.status === 200) {
            dispatch(stopLoader());
            dispatch({
              type: CONTACT_FORM_SUCCESS,
              payload: "Email submitted successfully",
            });
          } else {
            dispatch(stopLoader());
            dispatch({
              type: CONTACT_FORM_FAILED,
              payload:
                "Failed to submit email. Potential cause of failure is internet connectivity or bad request",
            });
          }
        })
        .catch((error) => {
          dispatch(stopLoader());
          dispatch({
            type: CONTACT_FORM_FAILED,
            payload:
              "Failed to submit email. Possible cause of failure is :" +
              error.response.data.error,
          });
        });
    });
  };
};

export const resetContact = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CONTACT_FORM });
  };
};
