// External imports
import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";

// Component imports
import RootReducer from "../reducers/RootReducer";

const initState = {};
const middleware = [thunk];

const store = createStore(
  RootReducer,
  initState,
  applyMiddleware(...middleware)
); // Creating the actual redux store

const persistor = persistStore(store);

const Store = { store, persistor };

export default Store;
