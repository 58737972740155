import React, { useEffect, useState } from "react";

// External import
import SyncLoader from "react-spinners/SyncLoader";
import { connect } from "react-redux";
import { FiShoppingCart } from "react-icons/fi";

// Component imports
import Nav from "../../shared/Nav";
import Footer from "../../shared/Footer";
import ParentCheckout from "./ParentCheckout";
import { Content } from "./Root";
import {
  removeFromCart,
  removeAllFromCart,
} from "../../../actions/OrderMgtAction";

function ParentRoot(props) {
  const [cartFromState, setCartFromState] = useState([]);
  const [productPrice, setProductPrice] = useState(0);
  const [isPageLoading, setPageLoading] = useState(false);

  const removeFromCart = props.removeFromCart;
  const removeAllFromCart = props.removeAllFromCart;
  const cart = props.cart;

  useEffect(() => {
    async function getAllCarts() {
      setPageLoading(true);
      setCartFromState(cart);

      let totalProductPrice = 0;
      cart &&
        cart.forEach((cartItem) => {
          const currentItemPrice = cartItem.quantity * cartItem.price;
          totalProductPrice += currentItemPrice;
        });
      setProductPrice(totalProductPrice);
      setPageLoading(false);
    }

    getAllCarts();
  }, [cart]);

  return !isPageLoading ? (
    <div className="bg-light pt-24">
      <Nav />
      <div className="flex flex-col lg:flex-row justify-between px-5 lg:px-20">
        <div className="w-full lg:w-2/3">
          {cartFromState && cartFromState.length > 0 ? (
            <ParentCheckout shoppingCartElements={cartFromState} />
          ) : (
            <div className="flex flex-col justify-center items-center m-auto my-44 font-light space-y-5">
              <FiShoppingCart size="150px" className="text-gray-400" />
              <p className="text-xl">Please add some item to cart</p>
            </div>
          )}
        </div>
        <div className="w-full lg:w-1/3">
          <Content
            shoppingCartElements={cartFromState}
            productPrice={productPrice}
            removeFromCart={removeFromCart}
            removeAllFromCart={removeAllFromCart}
          />
        </div>
      </div>

      <Footer />
    </div>
  ) : (
    <div className="min-h-screen w-screen flex flex-col items-center justify-center">
      <SyncLoader color="#313131" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.order.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeFromCart: (variationID) => dispatch(removeFromCart(variationID)),
    removeAllFromCart: () => dispatch(removeAllFromCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentRoot);
