import React, { Component } from "react";

// External imports
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { IoMailOpenOutline } from "react-icons/io5";

// Component imports
import Nav from "../../shared/Nav";
import { resetContact } from "../../../actions/ContactAction";

class Thankyou extends Component {
  componentWillUnmount() {
    this.props.resetContact();
  }
  render() {
    return (
      <div className="pt-24">
        <Nav />
        <Content />
      </div>
    );
  }
}

const Content = () => {
  return (
    <div className="flex flex-col items-center my-44 space-y-10 text-center font-light">
      <IoMailOpenOutline size="240" />
      <p className="text-4xl w-1/3">
        Thank you for subscribing to our newsletter !
      </p>
      <Link
        to="/"
        className="bg-default text-white border px-8 py-2 rounded-lg hover:bg-transparent hover:text-default hover:border-default"
      >
        Go to Home
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetContact: () => dispatch(resetContact()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);
