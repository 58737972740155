import React, { Component, useState } from "react";

// Component imports
import SideNav from "../../../shared/SideNav";
import {
  validateEmptyString,
  validateOnlyLetter,
} from "../../../shared/Validation";
import {
  createColor,
  createSize,
  editVariants,
  resetProductState,
} from "../../../../actions/ProductMgtAction";
import { resetLoader } from "../../../../actions/ApplicationAction";

// External imports
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";

function CreateCategory(props) {
  const selectedService = props.location.state.selectedService;
  const selectedProduct = props.location.state.selectedProduct;
  const createColor = props.createColor;
  const createSize = props.createSize;
  const editVariants = props.editVariants;
  const returnedMessage = props.returnedMessage;
  const resetProductState = props.resetProductState;
  const resetLoader = props.resetLoader;
  const isLoading = props.loaderState;

  const [choosenVariant, setChoosenVariant] = useState(
    selectedService === "ADD_VARIANT"
      ? ""
      : selectedService === "EDIT_SIZE"
      ? "SIZE"
      : "COLOR"
  );

  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <div className="px-10 w-full">
        <div className="flex flex-row space-x-5 items-center">
          <Link to={"/products"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Category Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-default">
              {selectedService === "ADD_VARIANT" &&
              typeof selectedService !== "undefined"
                ? "Add Variants"
                : (selectedService === "EDIT_COLOR" ||
                    selectedService === "EDIT_SIZE") &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedProduct !== "undefined"
                ? "Edit Variants"
                : ""}
            </p>
            <p className="font-thin text-sm">
              {selectedService === "ADD_VARIANT" &&
              typeof selectedService !== "undefined"
                ? "Create and add new variants to store"
                : (selectedService === "EDIT_COLOR" ||
                    selectedService === "EDIT_SIZE") &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedProduct !== "undefined"
                ? "Edit variant information on your store"
                : ""}
            </p>
          </div>
        </div>

        <div className="m-auto w-1/2 font-light">
          <select
            name="choosenVariant"
            id="choosenVariant"
            value={choosenVariant}
            onChange={(e) => {
              setChoosenVariant(e.target.value);
              props.resetProductState();
            }}
            disabled={selectedService !== "ADD_VARIANT" ? true : false}
            className="py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default w-full"
          >
            <option value="">Select Variant Choice</option>
            <option value="SIZE">Size</option>
            <option value="COLOR">Color</option>
          </select>
        </div>

        {/* Variant form */}
        <VariantForm
          selectedService={selectedService}
          selectedProduct={selectedProduct}
          choosenVariant={choosenVariant}
          createColor={createColor}
          createSize={createSize}
          editVariants={editVariants}
          returnedMessage={returnedMessage}
          resetProductState={resetProductState}
          resetLoader={resetLoader}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

class VariantForm extends Component {
  state = {
    sizeName:
      this.props.selectedService === "EDIT_SIZE"
        ? this.props.selectedProduct.size
        : "",
    colorName:
      this.props.selectedService === "EDIT_COLOR"
        ? this.props.selectedProduct.color
        : "",
    errorMessage: [],
    stateLoading: false,
  };

  componentWillUnmount() {
    this.props.resetProductState();
    this.props.resetLoader();
  }

  render() {
    //   Accepting props
    const {
      selectedService,
      selectedProduct,
      choosenVariant,
      createColor,
      createSize,
      editVariants,
      returnedMessage,
      isLoading,
    } = this.props;

    //   On Change
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    //   On Submit
    const onFormSubmit = async (e) => {
      e.preventDefault();
      const { sizeName, colorName } = this.state;
      const singleErrorMessage = [];

      if (selectedService === "ADD_VARIANT") {
        if (choosenVariant === "SIZE") {
          if (!validateEmptyString(sizeName)) {
            singleErrorMessage.push(
              "One or more required fields are missing. Please fill and try again."
            );
            return this.setState({ errorMessage: singleErrorMessage });
          }

          if (!validateOnlyLetter(sizeName)) {
            singleErrorMessage.push("Size name is in wrong format");
            return this.setState({ errorMessage: singleErrorMessage });
          }

          this.setState({ errorMessage: [] }, async () => {
            await createSize(this.state);
          });
        } else if (choosenVariant === "COLOR") {
          if (!validateEmptyString(colorName)) {
            singleErrorMessage.push(
              "One or more required fields are missing. Please fill and try again."
            );
            return this.setState({ errorMessage: singleErrorMessage });
          }

          if (!validateOnlyLetter(colorName)) {
            singleErrorMessage.push("Color name is in wrong format");
            return this.setState({ errorMessage: singleErrorMessage });
          }

          this.setState({ errorMessage: [] }, async () => {
            await createColor(this.state);
          });
        }
      } else if (
        selectedService === "EDIT_COLOR" ||
        selectedService === "EDIT_SIZE"
      ) {
        if (choosenVariant === "SIZE") {
          if (!validateEmptyString(sizeName)) {
            singleErrorMessage.push(
              "One or more required fields are missing. Please fill and try again."
            );
            return this.setState({ errorMessage: singleErrorMessage });
          }

          if (!validateOnlyLetter(sizeName)) {
            singleErrorMessage.push("Size name is in wrong format");
            return this.setState({ errorMessage: singleErrorMessage });
          }

          this.setState({ errorMessage: [] }, async () => {
            await editVariants(this.state, selectedProduct.id, selectedService);
          });
        } else if (choosenVariant === "COLOR") {
          if (!validateEmptyString(colorName)) {
            singleErrorMessage.push(
              "One or more required fields are missing. Please fill and try again."
            );
            return this.setState({ errorMessage: singleErrorMessage });
          }

          if (!validateOnlyLetter(colorName)) {
            singleErrorMessage.push("Color name is in wrong format");
            return this.setState({ errorMessage: singleErrorMessage });
          }

          this.setState({ errorMessage: [] }, async () => {
            await editVariants(this.state, selectedProduct.id, selectedService);
          });
        }
      } else {
        singleErrorMessage.push("Unknown operation detected");
        this.setState({ errorMessage: singleErrorMessage });
      }
    };

    //   Error mapping
    const errorMessageMapping =
      this.state.errorMessage && this.state.errorMessage.length > 0
        ? this.state.errorMessage.map((SingleError) => {
            return (
              <ul className="list-inside list-disc" key={SingleError}>
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return (
      <div className="m-auto w-1/2 font-light">
        <form onSubmit={onFormSubmit} className="flex flex-col space-y-5 py-10">
          {choosenVariant === "SIZE" ? (
            <>
              <div className="flex flex-col space-y-3">
                <label className="text-textColor">Size name</label>
                <input
                  type="text"
                  name="sizeName"
                  id="sizeName"
                  value={this.state.sizeName}
                  placeholder="Size Name"
                  onChange={onHandleChangeInField}
                  className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                />
              </div>
              <div>{errorMessageMapping}</div>
              {console.log(returnedMessage)}
              {returnedMessage !== "" ? (
                <div className="bg-gray-100 px-3 py-2">
                  <p className="text-default">{returnedMessage}</p>
                </div>
              ) : null}
              <button
                className="bg-default text-white py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
                type="submit"
                disabled={isLoading ? true : false}
              >
                {isLoading ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
              </button>
            </>
          ) : choosenVariant === "COLOR" ? (
            <>
              <div className="flex flex-col space-y-3">
                <label className="text-textColor">Color Name</label>
                <input
                  type="text"
                  name="colorName"
                  id="colorName"
                  value={this.state.colorName}
                  placeholder="Color Name"
                  onChange={onHandleChangeInField}
                  className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
                />
              </div>
              <div>{errorMessageMapping}</div>
              {returnedMessage !== "" ? (
                <div className="bg-gray-100 px-3 py-2">
                  <p className="text-default">{returnedMessage}</p>
                </div>
              ) : null}
              <button
                className="bg-default text-white py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
                type="submit"
                disabled={isLoading ? true : false}
              >
                {isLoading ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
              </button>
            </>
          ) : null}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.product.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createColor: (values) => dispatch(createColor(values)),
    createSize: (values) => dispatch(createSize(values)),
    editVariants: (values, selectedVariantId, selectedService) =>
      dispatch(editVariants(values, selectedVariantId, selectedService)),
    resetProductState: () => dispatch(resetProductState()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory);
