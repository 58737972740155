import {
  ADD_HERO_IMAGE_SUCCESS,
  ADD_HERO_IMAGE_FAILED,
  EDIT_HERO_IMAGE_SUCCESS,
  EDIT_HERO_IMAGE_FAILED,
  DELETE_HERO_IMAGE_SUCCESS,
  DELETE_HERO_IMAGE_FAILED,
  RESET_HERO_IMAGE,
} from "../actions/Type";

const initialState = { returnedMessage: "" };

const HeroReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_HERO_IMAGE_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case ADD_HERO_IMAGE_FAILED:
      return { ...state, returnedMessage: action.payload };
    case EDIT_HERO_IMAGE_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case EDIT_HERO_IMAGE_FAILED:
      return { ...state, returnedMessage: action.payload };
    case DELETE_HERO_IMAGE_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case DELETE_HERO_IMAGE_FAILED:
      return { ...state, returnedMessage: action.payload };
    case RESET_HERO_IMAGE:
      return { ...state, returnedMessage: "" };
    default:
      return { ...state };
  }
};

export default HeroReducer;
