import React, { Component } from "react";

// External imports
import { Link } from "react-router-dom";
import { FiHeart } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import SwiperCore, { Navigation } from "swiper";
import SyncLoader from "react-spinners/SyncLoader";
import clip from "text-clipper";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

// Component import
import Nav from "../../shared/Nav";
import Footer from "../../shared/Footer";
import FilterForm from "./FilterForm";
import { numberFormat } from "../../shared/Variables";
import { apiInstance } from "../../../api/api";
import {
  addToCart,
  getAllCart,
  addWishlist,
} from "../../../actions/OrderMgtAction";

SwiperCore.use([Navigation]);

class Root extends Component {
  state = {
    isPageLoading: false,
    toggleFiltering: false,

    productList: [],
    filteredCollection: [],
  };

  render() {
    const addToCart = this.props.addToCart;
    const addWishlist = this.props.addWishlist;
    const getAllCart = this.props.getAllCart;

    const onFilterActivated = async (inputData) => {
      this.child.onFilterActivated(inputData);
    };

    return !this.state.isPageLoading ? (
      <div className="bg-light">
        <Nav />
        <FilterForm onFilterActivated={onFilterActivated} />
        <Carousel
          addToCart={addToCart}
          getAllCart={getAllCart}
          addWishlist={addWishlist}
          ref={(instance) => (this.child = instance)}
        />
        <Footer />
      </div>
    ) : (
      <div className="min-h-screen w-screen flex flex-col items-center justify-center">
        <SyncLoader color="#313131" />
      </div>
    );
  }
}

class Carousel extends Component {
  state = {
    isPageLoading: false,
    toggleFiltering: false,
    productList: [],
    filteredProductList: [],
  };

  onFilterActivated = async (inputData) => {
    console.log(inputData.filterCategory);
    let value = [];
    if (inputData.filterText !== "" || inputData.filterCategory.length > 0) {
      this.setState({
        toggleFiltering: true,
      });

      const filterProductByTitleAndCategory =
        inputData.filterText !== "" && inputData.filterCategory !== ""
          ? this.state.productList.filter(
              (item) =>
                inputData.filterCategory.includes(item.title) &&
                item.products
                  .filter(
                    (SingleProduct) =>
                      SingleProduct.name
                        .toLowerCase()
                        .indexOf(inputData.filterText.toLowerCase()) !== -1
                  )
                  .map((element) => {
                    value.push(element);
                    return value;
                  })
            )
          : null;

      const filteredProductResult = this.state.productList.filter((item) => {
        return inputData.filterText !== "" && inputData.filterCategory === ""
          ? item.products
              .filter(
                (SingleProduct) =>
                  SingleProduct.name
                    .toLowerCase()
                    .indexOf(inputData.filterText.toLowerCase()) !== -1
              )
              .map((element) => {
                value.push(element);
                return value;
              })
          : [];
      });

      const filteredProductByCategory =
        inputData.filterCategory.length > 0 && inputData.filterText === ""
          ? this.state.productList
              .filter((item) => inputData.filterCategory.includes(item.title))
              .map((element) => {
                element.products.map((product) => value.push(product));
                return value;
              })
          : null;

      await this.setState({ filteredProductList: value });
    } else this.setState({ toggleFiltering: false });
  };

  async componentDidMount() {
    this.setState({ isPageLoading: true }, async () => {
      await apiInstance
        .get("categories")
        .then(async (response) => {
          let values = [];
          (await response.data.categories) &&
          response.data.categories.length > 0
            ? response.data.categories.map(async (SingleProductCategory) => {
                await apiInstance
                  .get("categories/" + SingleProductCategory.id + "/products")
                  .then((response) => {
                    if (response.data.products.length > 0) {
                      values.push({
                        id: SingleProductCategory.id,
                        title: SingleProductCategory.title,
                        products: response.data.products,
                      });
                      this.setState({ productList: values });
                    }
                  });
              })
            : this.setState({ productList: values });
        })
        .catch((error) => console.log(error));
      this.setState({ isPageLoading: false });
    });
  }

  render() {
    const addWishlist = this.props.addWishlist;

    // Item mapping
    const carouselItemsMapping =
      this.state.productList && this.state.productList.length > 0 ? (
        this.state.productList.map((SingleItem) => {
          return (
            <div className="px-0 lg:px-20 py-6" key={SingleItem.id}>
              <h2 className="text-2xl font-light py-5">{SingleItem.title}</h2>
              <Swiper
                spaceBetween={15}
                slidesPerView={5}
                autoHeight={true}
                breakpoints={{
                  370: {
                    slidesPerView: 1,
                  },
                  640: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 5,
                  },
                }}
                navigation
                className="shopSlider h-auto self-stretch  w-screen lg:w-full"
              >
                {SingleItem.products && SingleItem.products.length > 0 ? (
                  SingleItem.products.map((SingleElement) => {
                    const clipProductDescription = clip(
                      SingleElement.description,
                      100,
                      {
                        html: true,
                        maxLines: 8,
                      }
                    );

                    return (
                      <SwiperSlide
                        className="h-full self-stretch"
                        key={SingleElement.id}
                      >
                        <div
                          className="h-full self-stretch pb-5"
                          key={SingleElement.id}
                        >
                          <Link
                            to={{
                              pathname: "/shop/" + SingleElement.id,
                              state: {
                                selectedProductID: SingleElement.id,
                              },
                            }}
                          >
                            <div className="rounded overflow-hidden shadow-lg bg-white w-full lg:pt-0 flex flex-col items-stretch self-auto h-full">
                              <Link
                                to={{
                                  pathname: "/shop/" + SingleElement.id,
                                  state: {
                                    selectedProductID: SingleElement.id,
                                  },
                                }}
                              >
                                <img
                                  src={SingleElement.leading_image}
                                  alt={SingleElement.name}
                                  className="w-full object-cover h-32 sm:h-48 md:h-64"
                                />
                              </Link>
                              <div className="p-4 md:p-6 bg-white flex flex-col flex-1 space-y-3">
                                <p className="text-xl font-light mx-3 text-center">
                                  {SingleElement.name}{" "}
                                </p>
                                <div className="flex flex-row justify-center items-center space-x-3 w-full px-3">
                                  <p className="text-xl font-light leading-6">
                                    {"$ " +
                                      numberFormat.format(SingleElement.price)}
                                  </p>

                                  <div
                                    className="bg-gray-100 rounded-full p-2 shadow-md cursor-pointer"
                                    onClick={async () =>
                                      await addWishlist(SingleElement.id)
                                    }
                                  >
                                    <FiHeart size="13" />
                                  </div>
                                </div>
                                {/* <div className="text-sm flex items-center">
                                  <p className="text-sm text-gray-400 mx-0 font-light text-center">
                                    {clipProductDescription}{" "}
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <p className="font-light">No element</p>
                )}
              </Swiper>
            </div>
          );
        })
      ) : (
        <div className="flex flex-col justify-center items-center font-light my-32">
          <p>No Product | Waiting for Server Response . . . </p>
          <div className="min-h-screen w-screen flex flex-col items-center justify-center">
            <SyncLoader color="#313131" />
          </div>
        </div>
      );

    const filteredProducts =
      this.state.filteredProductList &&
      this.state.filteredProductList.length > 0 ? (
        this.state.filteredProductList.map((product) => {
          const clipProductDescription = clip(product.description, 100, {
            html: true,
            maxLines: 8,
          });
          return (
            <div className="rounded overflow-hidden shadow-lg flex-initial bg-white sm:mx-2 md:mx-1 lg:mx-2 w-full lg:w-1/5 lg:pt-0 mb-10 flex flex-col">
              <Link
                to={{
                  pathname: "/shop/" + product.id,
                  state: { selectedProductID: product.id },
                }}
              >
                <img
                  src={product.leading_image}
                  alt={product.name}
                  className="w-full object-cover h-32 sm:h-48 md:h-64"
                />
              </Link>
              <div className="p-4 md:p-6 bg-white flex flex-col flex-1 space-y-3">
                <p className="text-xl font-light mx-3 text-center">
                  {product.name}{" "}
                </p>
                <div className="flex flex-row justify-center items-center space-x-3 w-full px-3">
                  <p className="text-xl font-light leading-6">
                    {"$ " + numberFormat.format(product.price)}
                  </p>

                  <div
                    className="bg-gray-100 rounded-full p-2 shadow-md cursor-pointer"
                    onClick={async () => await addWishlist(product.id)}
                  >
                    <FiHeart size="13" />
                  </div>
                </div>
                <div className="text-sm flex items-center">
                  <p className="text-sm text-gray-400 mx-0 font-light text-center">
                    {clipProductDescription}{" "}
                  </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="flex flex-col justify-center items-center font-light my-32">
          <p>No product with this filter</p>
        </div>
      );

    return !this.state.isPageLoading ? (
      <div className="mx-auto">
        <div className="flex flex-col justify-between mx-4 md:mx-0 lg:-mx-2 flex-wrap h-100 items-stretch">
          {this.state.toggleFiltering ? null : carouselItemsMapping}
        </div>

        {this.state.toggleFiltering ? (
          <div className="container mx-auto">
            <h2 className="text-2xl font-light py-5">Search result . . . </h2>
            <div className="flex flex-col sm:flex-row justify-start mx-4 md:mx-0 lg:-mx-2 flex-wrap">
              {filteredProducts}
            </div>
          </div>
        ) : null}
      </div>
    ) : (
      <div className="min-h-screen w-screen flex flex-col items-center justify-center">
        <SyncLoader color="#313131" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (productID) => dispatch(addToCart(productID)),
    addWishlist: (productID) => dispatch(addWishlist(productID)),
    getAllCart: () => dispatch(getAllCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
