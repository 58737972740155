import React from "react";

// External imports
import { Link } from "react-router-dom";
import { SiFacebook } from "react-icons/si";
import { AiOutlineTwitter, AiOutlineInstagram } from "react-icons/ai";

// Component imports
import NewsletterForm from "../layouts/contact/NewsletterForm";

// Image imports
import KunjinaLogoWhite from "../../assets/images/Kunjina_White.png";

export default function Footer() {
    return (
        <div className="bg-dark lg:px-32 lg:py-10 flex flex-col space-y-5 text-white justify-center">
            <div className="flex flex-col md:flex-row flex-wrap justify-center md:justify-around space-y-10 md:space-x-20 mb-6">
                <FirstSection />
                <SecondSection />
                <ThridSection />
            </div>
            <hr className="border border-gray-700" />
            <div className="flex flex-col justify-center items-center space-y-5 font-light text-sm">
                <img
                    src={KunjinaLogoWhite}
                    alt={"Kunjina Logo White"}
                    className="w-1/6"
                />
                <p>&copy; {new Date().getFullYear()}, All right reserved</p>
            </div>
        </div>
    );
}

const FirstSection = () => {
    return (
        <div className="font-light text-center md:text-left">
            <h3 className="text-2xl">Information</h3>
            <div className="flex flex-col space-y-3 text-md mt-8">
                <a href="https://api.kunjina.shop/size-guide">Size Guide</a>
                <a href="https://api.kunjina.shop/shipping-and-delivery">
                    Shipping and Delivery
                </a>
                <Link to={"/contact"}>Contact Us</Link>
            </div>
        </div>
    );
};

const SecondSection = () => {
    return (
        <div className="font-light text-center md:text-left">
            <h3 className="text-2xl">Newsletter</h3>
            <NewsletterForm />
        </div>
    );
};

const ThridSection = () => {
    return (
        <div className="font-light text-center md:text-left">
            <h3 className="text-2xl">Get in touch</h3>
            <div className="flex flex-row justify-center md:justify-start space-x-10 text-lg mt-8">
                <a
                    href={"https://www.facebook.com/kunjina.official/"}
                    target="_blank"
                    rel="noreferrer"
                >
                    <SiFacebook size="35" />
                </a>
                <a
                    href={"https://www.instagram.com/kunjina_/?hl=en"}
                    target="_blank"
                    rel="noreferrer"
                >
                    <AiOutlineInstagram size="35" />
                </a>
            </div>
        </div>
    );
};
