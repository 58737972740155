import React from "react";

// External import
import { NavLink } from "react-router-dom";
import {
  AiOutlineDashboard,
  AiOutlineUser,
  AiOutlineSliders,
} from "react-icons/ai";
import { RiChatQuoteLine, RiSlideshow3Line } from "react-icons/ri";
import { GiChecklist, GiClothes } from "react-icons/gi";

// Image imports
import Logo from "../../assets/images/Kunjina_Black.png";

export default function SideNav() {
  return (
    <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white flex-shrink-0 md:pt-10 shadow-2xl rounded-r-3xl z-10">
      <div className="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
        <a
          href="/dashboard"
          className="text-lg flex flex-col justify-center items-center space-y-5 font-semibold text-center tracking-widest text-default uppercase rouned-lg focus:outline-none focus:shadow-outline"
        >
          <img src={Logo} alt="" className="" />
          <span>Kunjina</span>
        </a>
        <button className="rounded-lg md:hidden focus:outline-none focus:shadow-outline">
          <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
            <path
              x-show="!open"
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
            <path
              x-show="open"
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <nav className="flex-grow md:block pl-4 pb-4 md:my-10 md:pb-0 md:overflow-y-auto space-y-5">
        <NavLink
          className="flex flex-row items-center space-x-5 px-4 py-2 mt-2 text-lg text-default focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          to="/dashboard"
          activeClassName="font-bold text-default border-r-4 border-default"
        >
          <AiOutlineDashboard className="text-default opacity-50" size="25" />
          <span>Dashboard</span>
        </NavLink>
        <NavLink
          className="flex flex-row items-center space-x-5 px-4 py-2 mt-2 text-lg text-default focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          to="/orders"
          activeClassName="font-bold text-default border-r-4 border-default"
        >
          <GiChecklist className="text-default opacity-50" size="25" />
          <span>Orders</span>
        </NavLink>
        <NavLink
          className="flex flex-row items-center space-x-5 px-4 py-2 mt-2 text-lg text-default focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          to="/admin_blog"
          activeClassName="font-bold text-default border-r-4 border-default"
        >
          <RiChatQuoteLine className="text-default opacity-50" size="25" />
          <span>Media</span>
        </NavLink>
        <NavLink
          className="flex flex-row items-center space-x-5 px-4 py-2 mt-2 text-lg text-default focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          to="/products"
          activeClassName="font-bold text-default border-r-4 border-default"
        >
          <GiClothes className="text-default opacity-50" size="25" />
          <span>Products</span>
        </NavLink>
        <NavLink
          className="flex flex-row items-center space-x-5 px-4 py-2 mt-2 text-lg text-default focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          to="/admin_collections"
          activeClassName="font-bold text-default border-r-4 border-default"
        >
          <RiSlideshow3Line className="text-default opacity-50" size="25" />
          <span>Collections</span>
        </NavLink>
        <NavLink
          className="flex flex-row items-center space-x-5 px-4 py-2 mt-2 text-lg text-default focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          to="/hero_image"
          activeClassName="font-bold text-default border-r-4 border-default"
        >
          <AiOutlineSliders className="text-default opacity-50" size="25" />
          <span>Hero Image</span>
        </NavLink>
        <NavLink
          className="flex flex-row items-center space-x-5 px-4 py-2 mt-2 text-lg text-default focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          to="/user"
          activeClassName="font-bold text-default border-r-4 border-default"
        >
          <AiOutlineUser className="text-default opacity-50" size="25" />
          <span>Users</span>
        </NavLink>
      </nav>
    </div>
  );
}
