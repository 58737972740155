import React, { useState, useEffect } from "react";

// External imports
import { BiMessageSquareDetail } from "react-icons/bi";
import { GiChecklist } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { AgGridReact } from "ag-grid-react";
import { Redirect } from "react-router-dom";
import Moment from "react-moment";
import Modal from "react-modal";

import "moment-timezone";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

// Component imports
import SideNav from "../../../shared/SideNav";
import { numberFormat } from "../../../shared/Variables";
import { apiInstance } from "../../../../api/api";
import { validateEmptyString } from "../../../shared/Validation";

export default function Root() {
    if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;
    return (
        <div>
            <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
                <SideNav />
                <OrderDashContent />
            </div>
        </div>
    );
}

// Blog Dashboard Content
const OrderDashContent = () => {
    const [toggleDetail, setToggleDetail] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState();

    return (
        <div className="bg-light flex flex-row w-full">
            <OrderDashLeftSide
                toggleDetail={toggleDetail}
                setToggleDetail={setToggleDetail}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
            />
            <OrderDashRightSide
                toggleDetail={toggleDetail}
                setToggleDetail={setToggleDetail}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
            />
        </div>
    );
};

// Hotel Left Side
const OrderDashLeftSide = ({
    toggleDetail,
    setToggleDetail,
    selectedOrder,
    setSelectedOrder,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDeletableField, setSelectedDeletableField] = useState();
    const [selectedDelletableFieldName, setSelectedDelletableFieldName] =
        useState();

    // Ag Grid
    const onGridReady = (params) => {
        apiInstance
            .get("orders")
            .then((response) => {
                params.api.applyTransaction({ add: response.data.orders });
            })
            .catch((error) => console.log(error));
    };

    const columnDefs = [
        {
            headerName: "Customer Name",
            field: "customer.name",
            checkboxSelection: true,
        },
        {
            headerName: "Customer Email",
            field: "customer.email",
        },
        {
            headerName: "Customer Phone Number",
            field: "customer.phone",
        },
        {
            headerName: "Paid Amount",
            field: "total_amount",
            cellRendererFramework: (params) => (
                <p>$ {params.data.total_amount}</p>
            ),
        },
        {
            headerName: "Order at",
            field: "created_at",
            cellRendererFramework: (params) => (
                <Moment
                    date={params.data.created_at}
                    format={"DD MMMM, YYYY"}
                />
            ),
        },
        {
            headerName: "Payment Status",
            field: "payment_status",
            cellRendererFramework: (params) => (
                <p className="capitalize">{params.data.payment_status}</p>
            ),
        },
        {
            headerName: "Order Status",
            field: "order_status",
            cellRendererFramework: (params) => (
                <p className="capitalize">{params.data.order_status}</p>
            ),
        },
        {
            headerName: "Action",
            field: "id",
            filter: false,
            cellRendererFramework: (params) => (
                <div className="flex flex-row space-x-5 items-center my-2">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setToggleDetail(true);
                            setSelectedOrder(params.data);
                        }}
                        className="py-0 text-default rounded-full bg-transparent"
                    >
                        <BiMessageSquareDetail size="25" />
                    </button>
                </div>
            ),
        },
    ];

    const defaultColDef = {
        filter: true,
        sortable: true,
        floatingFilter: true,
        resizable: true,
        scrollable: true,
    };

    // row selection type
    const rowSelectionType = "single";

    // operation when the row is selected
    const onSelectionChanged = (e) => {
        console.log(e.api.getSelectedRows());
    };

    // opening modal
    const openingModalElement = (selectedField, selectedFieldName) => {
        setIsModalOpen(true);
        setSelectedDeletableField(selectedField);
        setSelectedDelletableFieldName(selectedFieldName);
    };

    // closing Modal
    const closingModalElement = () => {
        setIsModalOpen(false);
        setSelectedDeletableField(null);
        setSelectedDelletableFieldName("");
    };

    return (
        <div className="w-5/6 max-h-screen overflow-x-auto">
            {/* Order Header */}
            <div className="flex flex-col px-5 py-10">
                <p className="text-4xl font-bold text-default">
                    Order Management
                </p>
                <p className="font-thin text-sm">
                    Manage lists of orders in your store
                </p>
            </div>

            {/* View Orders */}
            <div
                className="ag-theme-material w-full bg-transparent border text-black placeholder-black"
                style={{ height: "80vh", color: "black" }}
            >
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    rowSelectionType={rowSelectionType}
                    onSelectionChanged={onSelectionChanged}
                    rowMultiSelectWithClick={true}
                    pagination={true}
                    className="text-left text-black placeholder-black"
                ></AgGridReact>
            </div>
            <Modal
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
                isOpen={isModalOpen}
                onRequestClose={() => closingModalElement()}
                contentLabel="Delete Modal"
                style={{
                    overlay: {
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                    },
                    content: {
                        display: "inline-block",
                        alignItems: "bottom",
                        background: "white",
                        borderRadius: "20px",
                        textAlign: "left",
                        overflow: "hidden",
                        position: "fixed",
                        left: "30%",
                        right: "auto",
                        bottom: "auto",
                        top: "30vh",
                    },
                }}
            >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                                className="h-6 w-6 text-red-600"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                            </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                Cancelling{" "}
                                <span className="font-bold">
                                    {selectedDelletableFieldName}
                                </span>{" "}
                                from Order
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Are you sure you want to cancel{" "}
                                    <span className="font-bold">
                                        {selectedDelletableFieldName}
                                    </span>{" "}
                                    from order? All data will be permanently
                                    removed. This action cannot be undone.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        // onClick={() => deleteShareholder(this.state.selectedDeletableField)}
                    >
                        Yes
                    </button>
                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => closingModalElement()}
                    >
                        No
                    </button>
                </div>
            </Modal>
        </div>
    );
};

// Blog Right Side
const OrderDashRightSide = ({
    toggleDetail,
    setToggleDetail,
    selectedOrder,
    setSelectedOrder,
}) => {
    const [getOrders, setOrders] = useState([]);

    useEffect(() => {
        async function fetchOrders() {
            await apiInstance
                .get("orders")
                .then((response) => {
                    setOrders(response.data.orders);
                })
                .catch((error) => console.log(error));
        }

        fetchOrders();
    }, []);

    return toggleDetail ? (
        <div className="w-1/3 bg-white rounded-l-2xl px-10 max-h-screen overflow-y-auto">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-col py-10">
                    <p className="font-thin text-sm">
                        View order detail information
                    </p>
                    <p className="text-4xl font-bold text-default">
                        Order Detail
                    </p>
                    <p className="font-thin text-sm">
                        Status:
                        <span className="text-dark font-bold capitalize">
                            {" "}
                            {selectedOrder.order_status}
                        </span>
                    </p>
                </div>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        setToggleDetail(false);
                        setSelectedOrder();
                    }}
                >
                    <IoClose />
                </button>
            </div>
            <SelectedOrderDetail selectedOrder={selectedOrder} />
        </div>
    ) : (
        <div className="w-1/3 bg-white rounded-l-2xl px-10 max-h-screen overflow-y-auto">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-col py-10">
                    <p className="font-thin text-sm">
                        Perform utility functions on order
                    </p>
                    <p className="text-4xl font-bold text-default">Utility</p>
                </div>
            </div>

            {/* Summary */}
            <div className="">
                <div className="flex flex-row justify-between items-center">
                    <p className="text-default font-bold text-lg">Summary</p>
                </div>
                {/* Each Notice */}
                <div className="flex flex-row space-x-5 items-start mt-8">
                    <div className="bg-gray-100 p-3 rounded-xl">
                        <GiChecklist size="30" className="text-default" />
                    </div>
                    <div className="text-default">
                        <p className="font-bold">
                            {numberFormat.format(getOrders.length)}
                        </p>
                        <p className="text-gray-300 text-sm">
                            Total Orders this month
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SelectedOrderDetail = ({ selectedOrder }) => {
    const [selectedOperation, setSelectedOperation] = useState("");
    console.log(selectedOrder);
    const productMapping =
        selectedOrder.products.length > 0 ? (
            selectedOrder.products.map((SingleProduct) => (
                <div className="flex flex-row space-x-5" key={SingleProduct.id}>
                    <img
                        src={
                            SingleProduct.product
                                ? SingleProduct.product.leading_image
                                : ""
                        }
                        alt="..."
                        className="w-20 h-20"
                    />
                    <div className="flex flex-col space-y-2 w-full">
                        <p className="text-default font-bold text-base">
                            {SingleProduct.product.name}
                        </p>
                        <div className="flex flex-col space-y-2">
                            <div className="flex flex-row justify-between items-center">
                                <p className="text-default font-light text-sm">
                                    Size
                                </p>
                                <p className="text-default font-light text-sm">
                                    {SingleProduct.size.size}
                                </p>
                            </div>
                            <div className="flex flex-row justify-between items-center">
                                <p className="text-default font-light text-sm">
                                    Color
                                </p>
                                <p className="text-default font-light text-sm">
                                    {SingleProduct.color.color}
                                </p>
                            </div>
                            <div className="flex flex-row justify-between items-center">
                                <p className="text-default font-light text-sm">
                                    Quantity
                                </p>
                                <p className="text-default font-light text-sm">
                                    {SingleProduct.pivot.quantity}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        ) : (
            <p className="font-light">No products found</p>
        );

    const mappingBillingInformation =
        selectedOrder.addresses.length > 0
            ? selectedOrder.addresses.map((BillingAddress) => {
                  if (BillingAddress.type === "billing")
                      return (
                          <div className="flex flex-col space-y-2">
                              <div className="flex flex-row justify-between items-center">
                                  <p className="text-default font-light text-sm w-1/2">
                                      Address
                                  </p>
                                  <p className="text-default font-light text-sm w-1/2">
                                      {BillingAddress.address}
                                  </p>
                              </div>
                              <div className="flex flex-row justify-between items-center">
                                  <p className="text-default font-light text-sm w-1/2">
                                      City
                                  </p>
                                  <p className="text-default font-light text-sm w-1/2">
                                      {BillingAddress.city}
                                  </p>
                              </div>
                              <div className="flex flex-row justify-between items-center">
                                  <p className="text-default font-light text-sm w-1/2">
                                      State
                                  </p>
                                  <p className="text-default font-light text-sm w-1/2">
                                      {BillingAddress.state}
                                  </p>
                              </div>
                              <div className="flex flex-row justify-between items-center">
                                  <p className="text-default font-light text-sm w-1/2">
                                      Country
                                  </p>
                                  <p className="text-default font-light text-sm w-1/2">
                                      {BillingAddress.country}
                                  </p>
                              </div>
                          </div>
                      );
              })
            : null;

    const mappingShippingInformation =
        selectedOrder.addresses.length > 0
            ? selectedOrder.addresses.map((ShippingAddress) => {
                  if (ShippingAddress.type === "shipping")
                      return (
                          <div className="flex flex-col space-y-2">
                              <div className="flex flex-row justify-between items-center">
                                  <p className="text-default font-light text-sm w-1/2">
                                      Name
                                  </p>
                                  <p className="text-default font-light text-sm w-1/2">
                                      {ShippingAddress.customer.name}
                                  </p>
                              </div>
                              <div className="flex flex-row justify-between items-center">
                                  <p className="text-default font-light text-sm w-1/2">
                                      Email
                                  </p>
                                  <p className="text-default font-light text-sm w-1/2">
                                      {ShippingAddress.customer.email}
                                  </p>
                              </div>
                              <div className="flex flex-row justify-between items-center">
                                  <p className="text-default font-light text-sm w-1/2">
                                      Phone
                                  </p>
                                  <p className="text-default font-light text-sm w-1/2">
                                      {ShippingAddress.customer.phone}
                                  </p>
                              </div>
                              <div className="flex flex-row justify-between items-center">
                                  <p className="text-default font-light text-sm w-1/2">
                                      Address
                                  </p>
                                  <p className="text-default font-light text-sm w-1/2">
                                      {ShippingAddress.address}
                                  </p>
                              </div>
                              <div className="flex flex-row justify-between items-center">
                                  <p className="text-default font-light text-sm w-1/2">
                                      Country
                                  </p>
                                  <p className="text-default font-light text-sm w-1/2">
                                      {ShippingAddress.country}
                                  </p>
                              </div>
                              <div className="flex flex-row justify-between items-center">
                                  <p className="text-default font-light text-sm w-1/2">
                                      State
                                  </p>
                                  <p className="text-default font-light text-sm w-1/2">
                                      {ShippingAddress.state}
                                  </p>
                              </div>
                          </div>
                      );
              })
            : null;

    return (
        <div className="flex flex-col space-y-10 mb-10">
            {/* Customer */}

            <div className="flex flex-col space-y-5">
                <div className="bg-gray-50 px-3 py-2 text-default shadow-md">
                    <p>Customer</p>
                </div>
                <div className="flex flex-row justify-between items-end">
                    <div className="flex flex-col w-1/2">
                        <p className="text-default font-bold">
                            {selectedOrder.customer.name}
                        </p>
                        <p className="text-default font-light text-sm">
                            {selectedOrder.customer.email}
                        </p>
                        <p className="text-default font-light text-sm">
                            {selectedOrder.addresses.map((SingleAddress) =>
                                SingleAddress.type === "billing"
                                    ? SingleAddress.city +
                                      ", " +
                                      SingleAddress.country
                                    : null
                            )}
                        </p>
                    </div>
                    <div className="flex flex-col justify-end items-end font-light text-sm w-1/2">
                        {selectedOrder.addresses.map((SingleAddress) =>
                            SingleAddress.type === "billing" ? (
                                <>
                                    <p className="text-default">
                                        {SingleAddress.state}
                                    </p>
                                    <p className="text-default">
                                        {SingleAddress.country}
                                    </p>
                                </>
                            ) : null
                        )}
                    </div>
                </div>
            </div>

            {/* Product */}

            <div className="flex flex-col space-y-5">
                <div className="bg-gray-50 px-3 py-2 text-default shadow-md">
                    <p>Product</p>
                </div>
                {productMapping}
            </div>

            {/* Billing information */}
            <div className="flex flex-col space-y-5">
                <div className="bg-gray-50 px-3 py-2 text-default shadow-md">
                    <p>Billing Information</p>
                </div>
                {mappingBillingInformation}
            </div>

            {/* Shipping Address */}

            <div className="flex flex-col space-y-5">
                <div className="bg-gray-50 px-3 py-2 text-default shadow-md">
                    <p>Shipping Information</p>
                </div>
                {mappingShippingInformation}
            </div>

            {/* Refund and Status update form */}
            <hr />
            <select
                className="px-3 py-2 bg-transparent font-light border border-gray-300 placeholder-gray-400 w-full focus: outline-none"
                onChange={(e) => setSelectedOperation(e.target.value)}
            >
                <option value="">Select Operation</option>
                <option value="STATUS">Update Status</option>
                {/* <option value="REFUND">Refund</option> */}
            </select>
            <RefundAndStatusForm
                type={selectedOperation}
                selectedOrderID={selectedOrder.id}
            />
        </div>
    );
};

const RefundAndStatusForm = ({ type, selectedOrderID }) => {
    const [status, setStatus] = useState("");
    const [refundOption, setRefundOption] = useState("");
    const [isFormSubmitLoading, setIsFormSubmitLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [serverResponse, setServerResponse] = useState();

    const onFormSubmit = async (e) => {
        e.preventDefault();
        let singleErrorMessage = [];

        if (!validateEmptyString(status)) {
            singleErrorMessage.push("Status is required");
            return setErrorMessage(singleErrorMessage);
        }

        setIsFormSubmitLoading(true);
        const inputData = {
            order_status: status,
            _method: "PUT",
        };

        await apiInstance.get("csrf-cookie").then(() => {
            apiInstance
                .post("orders/" + selectedOrderID, inputData)
                .then((response) => {
                    if (response.status === 200) {
                        setIsFormSubmitLoading(false);
                        setServerResponse("Order updated successfully");
                    } else {
                        setIsFormSubmitLoading(false);
                        setServerResponse(
                            "Failed to update order. Potential cause of error is internet connectivity or bad request"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormSubmitLoading(false);
                    setServerResponse(
                        "Failed to update order. Possible cause of error is: " +
                            error.response.data.error
                    );
                });
        });
    };

    return (
        <div>
            {type === "STATUS" ? (
                <form
                    className="flex flex-col space-y-3"
                    onSubmit={onFormSubmit}
                >
                    <select
                        className="px-3 py-2 bg-transparent font-light border border-gray-300 placeholder-gray-400 w-full focus: outline-none"
                        onChange={(e) => setStatus(e.target.value)}
                        name="status"
                        id="status"
                    >
                        <option value="">Select Status</option>
                        <option value="Processing">Processing</option>
                        {/* <option value="Refunded">Refunded</option> */}
                        <option value="On the way">On the way</option>
                        <option value="Delivered">Delivered</option>
                    </select>
                    {serverResponse ? (
                        <div className="bg-gray-100 px-3 py-2 mt-3">
                            <p className="text-default">{serverResponse}</p>
                        </div>
                    ) : null}
                    <button className="px-3 py-2 border bg-default text-white hover:border-default hover:bg-white hover:text-default w-full">
                        Submit
                    </button>
                </form>
            ) : type === "REFUND" ? (
                <form>
                    <form className="flex flex-col space-y-3">
                        <select
                            className="px-3 py-2 bg-transparent font-light border border-gray-300 placeholder-gray-400 w-full focus: outline-none"
                            onChange={(e) => setRefundOption(e.target.value)}
                        >
                            <option value="">Select Refund Option</option>
                            <option value="Processing">
                                Refund by Provider
                            </option>
                            <option value="Refunded">Refund by Customer</option>
                        </select>

                        <button className="px-3 py-2 border bg-default text-white hover:border-default hover:bg-white hover:text-default w-full">
                            Submit
                        </button>
                    </form>
                </form>
            ) : null}
        </div>
    );
};
