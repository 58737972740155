import React, { Component } from "react";

// Component imports
import SideNav from "../../../shared/SideNav";
import { validateEmptyString } from "../../../shared/Validation";
import {
  editVariation,
  resetProductState,
} from "../../../../actions/ProductMgtAction";
import { resetLoader } from "../../../../actions/ApplicationAction";

// External imports
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";

function EditVariation(props) {
  const selectedProduct = props.location.state.selectedProduct;
  const editVariation = props.editVariation;
  const resetProductState = props.resetProductState;
  const returnedMessage = props.returnedMessage;
  const resetLoader = props.resetLoader;
  const isLoading = props.loaderState;

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <div className="px-10 w-full">
        <div className="flex flex-row space-x-5 items-center">
          <Link to={"/products"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Product Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-default">Edit Variation</p>
            <p className="font-thin text-sm">
              Edit variation information of a product on your store
            </p>
          </div>
        </div>

        {/* Product form */}
        <VariationForm
          selectedProduct={selectedProduct}
          editVariation={editVariation}
          resetProductState={resetProductState}
          returnedMessage={returnedMessage}
          resetLoader={resetLoader}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

class VariationForm extends Component {
  state = {
    available_stock: this.props.selectedProduct.available_stock,
    leading_image: "",

    errorMessage: [],

    getAllCategories: [],
    stateLoading: false,
  };

  componentWillUnmount() {
    this.props.resetProductState();
    this.props.resetLoader();
  }

  render() {
    const { selectedProduct, editVariation, returnedMessage, isLoading } =
      this.props;

    const onFormSubmit = async (e) => {
      e.preventDefault();
      const singleErrorMessage = [];
      const { available_stock } = this.state;

      if (!validateEmptyString(available_stock)) {
        singleErrorMessage.push(
          "One or more required fields are missing. Please fill and try again."
        );
        return this.setState({ errorMessage: singleErrorMessage });
      }

      let variationData = new FormData();
      const leadingPhoto = document.querySelector("#leading_image");
      this.state.leading_image =
        typeof leadingPhoto.files[0] === "undefined"
          ? ""
          : leadingPhoto.files[0];

      variationData.append("available_stock", this.state.available_stock);
      variationData.append("_method", "PUT");
      if (typeof leadingPhoto.files[0] !== "undefined") {
        variationData.append("leading_image", this.state.leading_image);
      }

      this.setState({ errorMessage: [] });
      await editVariation(variationData, selectedProduct.id);
      // this.setState({ stateLoading: false });
    };

    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    const errorMessageMapping =
      this.state.errorMessage && this.state.errorMessage.length > 0
        ? this.state.errorMessage.map((SingleError) => {
            return (
              <ul className="list-inside list-disc" key={SingleError}>
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return (
      <div className="m-auto w-2/3 font-light">
        <form onSubmit={onFormSubmit} className="flex flex-col space-y-5 py-10">
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Available Stock</label>
            <input
              type="number"
              name="available_stock"
              id="available_stock"
              value={this.state.available_stock}
              placeholder="Variation Stock"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-textColor">Featured Image</label>
            <input
              type="file"
              name="leading_image"
              id="leading_image"
              accept="image/*"
              placeholder="Featured Image"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="bg-gray-100 px-3 py-2">
              <p className="text-default">{returnedMessage}</p>
            </div>
          ) : null}
          <button
            className="bg-default text-white py-2 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
            type="submit"
            disabled={isLoading ? true : false}
          >
            {isLoading ? <SyncLoader color="#FFF" size="8px" /> : "Submit"}
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.product.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editVariation: (values, id) => dispatch(editVariation(values, id)),
    resetProductState: () => dispatch(resetProductState()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVariation);
