// Component imports
import {
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  RESET_USER_STATE,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAILED,
} from "../actions/Type";

const initialState = { returnedMessage: "", isLoggedIn: false };

const UserMgtReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case ADD_USER_FAILED:
      return { ...state, returnedMessage: action.payload };
    case LOGIN_SUCCESS:
      return { ...state, returnedMessage: "", isLoggedIn: true };
    case LOGIN_FAILED:
      return { ...state, returnedMessage: action.payload };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case CHANGE_PASSWORD_FAILED:
      console.log("Return: " + action.payload);
      return { ...state, returnedMessage: action.payload };
    case EDIT_PROFILE_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case EDIT_PROFILE_FAILED:
      return { ...state, returnedMessage: action.payload };
    case RESET_USER_STATE:
      return initialState;
    default:
      return state;
  }
};

export default UserMgtReducer;
