import {
  ADD_COLLECTION_CATEGORY_SUCCESS,
  ADD_COLLECTION_CATEGORY_FAILED,
  ADD_COLLECTION_SUCCESS,
  ADD_COLLECTION_FAILED,
  EDIT_COLLECTION_SUCCESS,
  EDIT_COLLECTION_FAILED,
  EDIT_COLLECTION_CATEGORY_SUCCESS,
  EDIT_COLLECTION_CATEGORY_FAILED,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAILED,
  DELETE_COLLECTION_CATEGORY_SUCCESS,
  DELETE_COLLECTION_CATEGORY_FAILED,
  RESET_COLLECTION,
} from "../actions/Type";

const initialState = { returnedMessage: "" };

const CollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COLLECTION_CATEGORY_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case ADD_COLLECTION_CATEGORY_FAILED:
      return { ...state, returnedMessage: action.payload };
    case ADD_COLLECTION_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case ADD_COLLECTION_FAILED:
      return { ...state, returnedMessage: action.payload };
    case EDIT_COLLECTION_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case EDIT_COLLECTION_FAILED:
      return { ...state, returnedMessage: action.payload };
    case DELETE_COLLECTION_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case DELETE_COLLECTION_FAILED:
      return { ...state, returnedMessage: action.payload };
    case EDIT_COLLECTION_CATEGORY_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case EDIT_COLLECTION_CATEGORY_FAILED:
      return { ...state, returnedMessage: action.payload };
    case DELETE_COLLECTION_CATEGORY_SUCCESS:
      return { ...state, returnedMessage: action.payload };
    case DELETE_COLLECTION_CATEGORY_FAILED:
      return { ...state, returnedMessage: action.payload };
    case RESET_COLLECTION:
      return { ...state, returnedMessage: "" };
    default:
      return { ...state };
  }
};

export default CollectionReducer;
