import React, { Component } from "react";

// Component imports
import SideNav from "../../../shared/SideNav";
import {
  validateEmptyString,
  validateOnlyLetter,
} from "../../../shared/Validation";
import {
  createCollectionCategory,
  createCollection,
  editCollection,
  editCollectionCategory,
  resetCollection,
} from "../../../../actions/CollectionMgtAction";
import { resetLoader } from "../../../../actions/ApplicationAction";
import { apiInstance } from "../../../../api/api";

// External imports
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";

function CreateCollections(props) {
  // Getting selected service
  const selectedService = props.location.state.selectedService;
  const selectedCollection = props.location.state.selectedCollection;
  const returnedMessage = props.returnedMessage;
  const createCollection = props.createCollection;
  const createCollectionCategory = props.createCollectionCategory;
  const editCollection = props.editCollection;
  const editCollectionCategory = props.editCollectionCategory;
  const resetCollection = props.resetCollection;
  const resetLoader = props.resetLoader;
  const isLoading = props.loaderState;

  if (!localStorage.getItem("login_user_id")) return <Redirect to="/login" />;

  return (
    <div className="md:flex flex-col md:flex-row md:min-h-screen bg-light">
      <SideNav />
      <div className="px-10 w-full">
        <div className="flex flex-row space-x-5 items-center">
          <Link to={"/admin_collections"}>
            <FaLongArrowAltLeft className="text-default " size="30" />
          </Link>
          {/* Collection Header */}
          <div className="flex flex-col py-10">
            <p className="text-4xl font-bold text-default">
              {selectedService === "ADD_COLLECTION" &&
              typeof selectedService !== "undefined"
                ? "Add Collection"
                : selectedService === "ADD_CATEGORY" &&
                  typeof selectedService !== "undefined"
                ? "Add Category"
                : selectedService === "EDIT_COLLECTION" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedCollection !== "undefined"
                ? "Edit Collection"
                : selectedService === "EDIT_CATEGORY" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedCollection !== "undefined"
                ? "Edit Category"
                : ""}
            </p>
            <p className="font-thin text-sm">
              {selectedService === "ADD_COLLECTION" &&
              typeof selectedService !== "undefined"
                ? "Create and add new collection to store"
                : selectedService === "ADD_CATEGORY" &&
                  typeof selectedService !== "undefined"
                ? "Create new collection category on your store"
                : selectedService === "EDIT_COLLECTION" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedCollection !== "undefined"
                ? "Edit collection information on your store"
                : selectedService === "EDIT_CATEGORY" &&
                  typeof selectedService !== "undefined" &&
                  typeof selectedCollection !== "undefined"
                ? "Edit category information on your store"
                : ""}
            </p>
          </div>
        </div>

        {/* Collection form */}
        <CollectionForm
          selectedService={selectedService}
          selectedCollection={selectedCollection}
          createCollection={createCollection}
          createCollectionCategory={createCollectionCategory}
          editCollection={editCollection}
          editCollectionCategory={editCollectionCategory}
          returnedMessage={returnedMessage}
          resetCollection={resetCollection}
          resetLoader={resetLoader}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

class CollectionForm extends Component {
  state = {
    collectionTitle:
      this.props.selectedService === "ADD_COLLECTION"
        ? ""
        : this.props.selectedService === "EDIT_COLLECTION"
        ? this.props.selectedCollection.title
        : "",
    collectionDescription:
      this.props.selectedService === "ADD_COLLECTION"
        ? ""
        : this.props.selectedService === "EDIT_COLLECTION"
        ? this.props.selectedCollection.body
        : "",
    leadingImage: "",
    collectionCategory:
      this.props.selectedService === "ADD_COLLECTION"
        ? ""
        : this.props.selectedService === "EDIT_COLLECTION"
        ? this.props.selectedCollection.category.id
        : "",
    images: [],

    categoryTitle:
      this.props.selectedService === "ADD_CATEGORY"
        ? ""
        : this.props.selectedService === "EDIT_CATEGORY"
        ? this.props.selectedCollection.name
        : "",

    getAllCategories: [],
    errorMessage: [],
    stateLoading: false,
  };

  async componentDidMount() {
    this.setState({ stateLoading: true });

    await apiInstance
      .get("collection-categories")
      .then((response) =>
        this.setState({
          getAllCategories: response.data.categories,
        })
      )
      .catch((error) => console.log(error));

    this.setState({ stateLoading: false });
  }

  componentWillUnmount() {
    this.props.resetCollection();
    this.props.resetLoader();
  }

  render() {
    const {
      selectedService,
      selectedCollection,
      createCollection,
      createCollectionCategory,
      editCollection,
      editCollectionCategory,
      returnedMessage,
      isLoading,
    } = this.props;

    //   Handling onChange
    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    // Handling onFileChange
    const onHandleFileInput = (e) => {
      let files = e.target.files;
      const values = [];

      for (let i = 0; i < files.length; i++) {
        values.push(files[i]);
      }

      this.setState({ images: values });
    };

    //   const onFormSubmit
    const onFormSubmit = async (e) => {
      e.preventDefault();

      let {
        collectionTitle,
        collectionDescription,
        leadingImage,
        collectionCategory,
        images,
        categoryTitle,
      } = this.state;
      const singleErrorMessage = [];

      if (selectedService === "ADD_COLLECTION") {
        if (
          !validateEmptyString(collectionTitle) ||
          !validateEmptyString(collectionDescription) ||
          !validateEmptyString(leadingImage) ||
          !validateEmptyString(collectionCategory) ||
          !validateEmptyString(images)
        ) {
          singleErrorMessage.push(
            "One or more fields are missing. Please fill and try again"
          );
          return this.setState({ errorMessage: singleErrorMessage });
        }

        this.setState({ errorMessage: [] }, async () => {
          let collectionContent = new FormData();
          const leadingImageQuery = document.querySelector("#leadingImage");
          leadingImage = leadingImageQuery.files[0];

          collectionContent.append("title", collectionTitle);
          collectionContent.append("body", collectionDescription);
          collectionContent.append("leading_image", leadingImage);
          collectionContent.append("category_id", collectionCategory);
          images.forEach((image) =>
            collectionContent.append("images[]", image)
          );

          // Action
          await createCollection(collectionContent, collectionCategory);
        });
      } else if (selectedService === "ADD_CATEGORY") {
        if (!validateEmptyString(categoryTitle)) {
          singleErrorMessage.push(
            "One or more fields are missing. Please fill and try again"
          );
          return this.setState({ errorMessage: singleErrorMessage });
        }

        if (!validateOnlyLetter(categoryTitle)) {
          singleErrorMessage.push("Category name is in a wrong format");
          return this.setState({ errorMessage: singleErrorMessage });
        }

        this.setState({ errorMessage: [] });
        await createCollectionCategory(this.state);
      } else if (selectedService === "EDIT_COLLECTION") {
        if (
          !validateEmptyString(collectionTitle) ||
          !validateEmptyString(collectionDescription) ||
          !validateEmptyString(collectionCategory)
        ) {
          singleErrorMessage.push(
            "One or more fields are missing. Please fill and try again"
          );
          return this.setState({ errorMessage: singleErrorMessage });
        }

        this.setState({ errorMessage: [] });

        let collectionContent = new FormData();
        const leadingImageQuery = document.querySelector("#leadingImage");
        leadingImage =
          typeof leadingImageQuery.files[0] === "undefined"
            ? ""
            : leadingImageQuery.files[0];

        collectionContent.append("title", collectionTitle);
        collectionContent.append("body", collectionDescription);
        collectionContent.append("_method", "PUT");
        if (typeof leadingImageQuery.files[0] !== "undefined") {
          collectionContent.append("leading_image", leadingImage);
        }
        collectionContent.append("category_id", collectionCategory);
        if (images.length > 0) {
          images.forEach((image) =>
            collectionContent.append("images[]", image)
          );
        }

        // Action
        await editCollection(collectionContent, collectionCategory);
      } else if (selectedService === "EDIT_CATEGORY") {
        if (!validateEmptyString(categoryTitle)) {
          singleErrorMessage.push(
            "One or more fields are missing. Please fill and try again"
          );
          return this.setState({ errorMessage: singleErrorMessage });
        }

        if (!validateOnlyLetter(categoryTitle)) {
          singleErrorMessage.push("Category name is in a wrong format");
          return this.setState({ errorMessage: singleErrorMessage });
        }

        this.setState({ errorMessage: [] });
        await editCollectionCategory(this.state, selectedCollection.id);
      }
    };

    const categoriesMapping =
      this.state.getAllCategories && this.state.getAllCategories.length > 0
        ? this.state.getAllCategories.map((SingleCategory) => {
            return (
              <option key={SingleCategory.id} value={SingleCategory.id}>
                {SingleCategory.name}
              </option>
            );
          })
        : null;

    const errorMessageMapping =
      this.state.errorMessage && this.state.errorMessage.length > 0
        ? this.state.errorMessage.map((SingleError) => {
            return (
              <ul className="list-inside list-disc" key={SingleError}>
                <li className="font-light">{SingleError}</li>
              </ul>
            );
          })
        : null;

    return selectedService === "ADD_COLLECTION" ||
      selectedService === "EDIT_COLLECTION" ? (
      <div className="m-auto w-1/2">
        <form
          onSubmit={onFormSubmit}
          className="flex flex-col space-y-5 py-10 font-light"
        >
          <div className="flex flex-col space-y-3">
            <label className="text-default">Collection Title</label>
            <input
              type="text"
              name="collectionTitle"
              id="collectionTitle"
              placeholder="Collection Title"
              value={this.state.collectionTitle}
              onChange={onHandleChangeInField}
              className="py-2 px-3 border text-default font-light placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-default">Description</label>
            <textarea
              type="text"
              name="collectionDescription"
              id="collectionDescription"
              value={this.state.collectionDescription}
              placeholder="Description"
              onChange={onHandleChangeInField}
              className="py-2 px-3 font-light border text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            ></textarea>
          </div>
          {selectedService === "ADD_COLLECTION" ? (
            <div className="flex flex-col space-y-3">
              <label className="text-default">Collection Category</label>
              <select
                name="collectionCategory"
                id="collectionCategory"
                value={this.state.collectionCategory}
                placeholder="Collection Category"
                onChange={onHandleChangeInField}
                className="py-2 px-3 font-light border text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              >
                <option value="">Select collection category</option>
                {categoriesMapping}
              </select>
            </div>
          ) : null}
          <div className="flex flex-col space-y-3">
            <label className="text-default">Collection Leading Image</label>
            <input
              type="file"
              accept="image/*"
              name="leadingImage"
              id="leadingImage"
              onChange={onHandleChangeInField}
              className="py-2 px-3 border font-light text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <label className="text-default">Featured Images</label>
            <input
              type="file"
              name="images"
              id="images"
              placeholder="Images"
              multiple
              accept="image/*"
              onChange={(e) => onHandleFileInput(e)}
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              required={selectedService === "ADD_COLLECTION" ? true : false}
            />
          </div>
          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="bg-gray-100 px-3 py-2">
              <p className="text-default">{returnedMessage}</p>
            </div>
          ) : null}
          <button
            className="bg-default text-white border py-2 rounded-lg hover:bg-transparent hover:text-default hover:border-default"
            type="submit"
            disabled={this.state.stateLoading ? true : false}
          >
            {this.state.stateLoading || isLoading ? (
              <SyncLoader color="#FFF" size="8px" />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    ) : selectedService === "ADD_CATEGORY" ||
      selectedService === "EDIT_CATEGORY" ? (
      <div className="m-auto w-1/2">
        <form
          onSubmit={onFormSubmit}
          className="flex flex-col space-y-5 py-10 font-light"
        >
          <div className="flex flex-col space-y-3">
            <label className="text-default">Collection Category</label>
            <input
              type="text"
              name="categoryTitle"
              id="categoryTitle"
              placeholder="Collection Category"
              value={this.state.categoryTitle}
              onChange={onHandleChangeInField}
              className="py-2 px-3 border font-light text-default placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            />
          </div>
          <div>{errorMessageMapping}</div>
          {returnedMessage !== "" ? (
            <div className="bg-gray-100 px-3 py-2">
              <p className="text-default font-light">{returnedMessage}</p>
            </div>
          ) : null}
          <button
            className="bg-default text-white border py-2 rounded-lg hover:bg-transparent hover:text-default hover:border-default"
            type="submit"
            disabled={this.state.stateLoading ? true : false}
          >
            {this.state.stateLoading || isLoading ? (
              <SyncLoader color="#FFF" size="8px" />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    returnedMessage: state.collection.returnedMessage,
    loaderState: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCollection: (values, id) => dispatch(createCollection(values, id)),
    createCollectionCategory: (values) =>
      dispatch(createCollectionCategory(values)),
    editCollection: (values, id) => dispatch(editCollection(values, id)),
    editCollectionCategory: (values, id) =>
      dispatch(editCollectionCategory(values, id)),
    resetCollection: () => dispatch(resetCollection()),
    resetLoader: () => dispatch(resetLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCollections);
