import React from "react";

// External import
import clip from "text-clipper";
import { AiOutlineInstagram } from "react-icons/ai";

// FIND US ON Instagram
export const FindUsOnInstagram = ({ getInstagramFeeds }) => {
  const mappingInstagramFeeds =
    getInstagramFeeds && getInstagramFeeds.length > 0
      ? getInstagramFeeds.map((SingleFeed) => {
          const clipInstagramCaption = clip(SingleFeed.caption, 50, {
            html: true,
            maxLines: 8,
          });
          return (
            <div className="lg:w-1/3 px-5 mb-9 min-h-96 max-h-96">
              <div className=" bg-white rounded-lg flex flex-row shadow-lg">
                <img
                  src={SingleFeed.url}
                  alt={SingleFeed.caption}
                  className="w-1/2"
                />
                <div className="flex flex-col space-y-5 justify-center items-center w-1/2 p-5">
                  <a href={SingleFeed.permalink}>
                    <AiOutlineInstagram size="30" />
                  </a>
                  <p className="text-center text-sm">{clipInstagramCaption}</p>
                </div>
              </div>
            </div>
          );
        })
      : null;

  return (
    <div className="mx-10 lg:mx-32 mt-10 lg:mt-20 lg:mb-32 flex flex-col space-y-10">
      <p className="text-center">
        <span className="uppercase text-4xl font-light">Find us on</span>
        <span className="custom-font text-4xl"> Instagram</span>
      </p>

      <div className="flex flex-col lg:flex-row flex-wrap">
        {mappingInstagramFeeds}
      </div>
    </div>
  );
};
