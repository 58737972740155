import React, { useState, useEffect } from "react";

// External imports
import csc from "country-state-city";
import SyncLoader from "react-spinners/SyncLoader";

// Component imports
import {
  validateEmptyString,
  validateFullNameLength,
  validateOnlyLetter,
  validateEmail,
} from "../../shared/Validation.js";

function BillingFormComponent(props) {
  const billingInformation = props.billingInformation;

  console.log(billingInformation);

  const [name, setName] = useState(
    typeof billingInformation.name !== "undefined"
      ? billingInformation.name
      : ""
  );
  const [email, setEmail] = useState(
    typeof billingInformation.email !== "undefined"
      ? billingInformation.email
      : ""
  );
  const [address, setAddress] = useState(
    typeof billingInformation.address !== "undefined"
      ? billingInformation.address.line1
      : ""
  );
  const [phone, setPhone] = useState(
    typeof billingInformation.phone !== "undefined"
      ? billingInformation.phone
      : ""
  );
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [ZIP, setZIP] = useState("");

  const [getAllCountry, setAllCountry] = useState([]);
  const [getCountryCode, setCountryCode] = useState("");
  const [getAllCity, setAllCity] = useState([]);
  const [getAllState, setAllState] = useState([]);

  const [getErrorMessage, setErrorMessage] = useState([]);
  const [isStateFetching, setIsStateFetching] = useState(false);

  // Data fetch
  useEffect(() => {
    async function fetchFormData() {
      setIsStateFetching(true);
      await setAllCountry(csc.getAllCountries());
      setIsStateFetching(false);
    }

    // Calling fetchFormData
    fetchFormData();
  }, []);

  // OnChange
  const onHandleChange = (e) => {
    if (e.target.name === "country") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let option = el.getAttribute("id");

      setCountryCode(option);
      setCountry(option);
      setAllState(csc.getStatesOfCountry(option));
    }

    if (e.target.name === "state") {
      let index = e.target.selectedIndex;
      let el = e.target.childNodes[index];
      let optionState = el.getAttribute("id");

      setState(e.target.value);
      setAllCity(csc.getCitiesOfState(getCountryCode, optionState));
    }
  };

  // On Submit
  const onFormSubmit = async (e) => {
    e.preventDefault();

    const singleErrorMessage = [];
    const { updateBillingInformation, updatePage } = props;

    if (
      !validateEmptyString(name) ||
      !validateEmptyString(email) ||
      !validateEmptyString(address) ||
      !validateEmptyString(phone) ||
      !validateEmptyString(country) ||
      !validateEmptyString(state) ||
      !validateEmptyString(city) ||
      !validateEmptyString(ZIP)
    ) {
      singleErrorMessage.push(
        "One or more fields are missing. Please fill and try again"
      );
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateFullNameLength(name)) {
      singleErrorMessage.push(
        "Full Name should be less than 50 characters and greater than 5 characters"
      );
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateOnlyLetter(name)) {
      singleErrorMessage.push("Full Name should be letter only");
      return setErrorMessage(singleErrorMessage);
    }
    if (!validateEmail(email)) {
      singleErrorMessage.push("Email is in a wrong format");
      return setErrorMessage(singleErrorMessage);
    }

    //   Billing detail format
    const billing_details = {
      name: name,
      email: email,
      phone: phone,
      address: {
        city: city,
        country: country,
        line1: address,
        state: state,
      },
    };

    setErrorMessage([]);
    await updateBillingInformation(billing_details);
    updatePage();
  };

  // Mapping functions
  const countryMapping =
    getAllCountry && getAllCountry.length > 0
      ? getAllCountry.map((SingleCountry) => {
          return (
            <option
              value={SingleCountry.name}
              id={SingleCountry.isoCode}
              key={SingleCountry.isoCode}
            >
              {SingleCountry.name}
            </option>
          );
        })
      : null;

  const stateMapping =
    getAllState && getAllState.length > 0
      ? getAllState.map((SingleState) => {
          return (
            <option
              value={SingleState.name}
              id={SingleState.isoCode}
              key={SingleState.isoCode}
            >
              {SingleState.name}
            </option>
          );
        })
      : null;

  const cityMapping =
    getAllCity && getAllCity.length > 0
      ? getAllCity.map((SingleCity) => {
          return (
            <option value={SingleCity.name} key={SingleCity.name}>
              {SingleCity.name}
            </option>
          );
        })
      : null;

  const errorMessageMapping =
    getErrorMessage && getErrorMessage.length > 0
      ? getErrorMessage.map((SingleError) => {
          return (
            <ul className="list-inside list-disc" key={SingleError}>
              <li className="font-light">{SingleError}</li>
            </ul>
          );
        })
      : null;
  return (
    <div>
      <form onSubmit={onFormSubmit}>
        <div className="flex flex-col space-y-5">
          <p className="text-default font-light text-xl pb-5">
            Billing Information
          </p>
          <input
            className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
            placeholder="Full name"
            name="name"
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="flex flex-row space-x-2">
            <input
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default w-1/2"
              placeholder="Email"
              name="email"
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default w-1/2"
              placeholder="Phone number"
              name="phone"
              id="phone"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="flex flex-row space-x-3 w-full">
            <input
              className="w-2/3 py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              placeholder="Address"
              name="address"
              id="address"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
              className="w-1/3 py-2 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              placeholder="ZIP"
              name="zip"
              id="zip"
              type="number"
              value={ZIP}
              onChange={(e) => setZIP(e.target.value)}
            />
          </div>

          <div className="flex flex-row space-x-5 w-full">
            <select
              className="w-1/2 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              name="country"
              id="country"
              onChange={onHandleChange}
            >
              <option value="">Choose Country</option>
              {countryMapping}
            </select>
            <select
              className="w-1/3 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              name="state"
              id="state"
              onChange={onHandleChange}
            >
              <option value="">Choose State</option>
              {stateMapping}
            </select>
            <select
              className="w-1/3 py-3 px-3 font-light border text-textColor placeholder-gray-300 rounded-lg focus:outline-none focus:border-default"
              name="city"
              id="city"
              onChange={(e) => setCity(e.target.value)}
            >
              <option value="">Choose City</option>
              {cityMapping}
            </select>
          </div>
          <div>{errorMessageMapping}</div>
          <div className="flex justify-end">
            <button
              className="bg-default text-white py-2 px-5 border rounded-lg hover:bg-transparent hover:text-default hover:border-default focus:outline-none"
              type="submit"
              disabled={isStateFetching ? true : false}
            >
              {isStateFetching ? (
                <SyncLoader color="#FFF" size="8px" />
              ) : (
                "Continue to Shipping"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default BillingFormComponent;
