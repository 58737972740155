import React, { useEffect, useState } from "react";

// External imports
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import SyncLoader from "react-spinners/SyncLoader";
import clip from "text-clipper";

// Component import
import Nav from "../../shared/Nav";
import Footer from "../../shared/Footer";
import Pagination from "../../shared/Pagination";
import { apiInstance } from "../../../api/api";

export default function Root() {
  const [isPageLoading, setPageLoading] = useState(false);
  const [collectionList, setCollectionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(4);

  useEffect(() => {
    async function fetchCollection() {
      setPageLoading(true);
      await apiInstance
        .get("collections")
        .then((response) => setCollectionList(response.data.collections))
        .catch((error) => console.log(error));
      setPageLoading(false);
    }

    fetchCollection();
  }, []);

  // Pagination
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = collectionList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return !isPageLoading ? (
    <div>
      <Nav />
      <Content carouselItems={currentPosts} />
      <Pagination
        postsPerPage={postPerPage}
        totalPost={collectionList.length}
        paginate={paginate}
        currentPage={currentPage}
      />
      <Footer />
    </div>
  ) : (
    <div className="min-h-screen w-screen flex flex-col items-center justify-center">
      <SyncLoader color="#313131" />
    </div>
  );
}

const Content = ({ carouselItems }) => {
  // Item mapping
  const carouselItemsMapping =
    carouselItems && carouselItems.length > 0
      ? carouselItems.map((SingleItem, index) => {
          const clipProductDescription = clip(SingleItem.body, 300, {
            html: true,
            maxLines: 8,
          });
          return index % 2 === 0 ? (
            <div className="bg-transparent flex flex-col-reverse lg:flex-row lg:justify-between  my-10 px-5 lg:px-20">
              <div className="w-full lg:w-1/2 py-7 flex flex-col space-y-5">
                <p className="text-3xl font-light mx-7">
                  {SingleItem.title}
                  <br />
                  <span className="text-sm font-bold px-3 py-1 bg-gray-200 text-default rounded-full">
                    {SingleItem.category.name}
                  </span>
                </p>
                <p className="text-sm text-gray-400 mx-7 font-base">
                  {clipProductDescription}
                </p>
                <Link
                  to={{
                    pathname: "/collection/" + SingleItem.id,
                    state: { selectedCollectionID: SingleItem.id },
                  }}
                  className="text-default text-base mb-4 flex flex-row space-x-3 items-center font-light mx-7"
                >
                  <p>Read more</p>
                  <BsArrowRight size="25" />
                </Link>
              </div>

              {SingleItem.images
                ? SingleItem.images.map((SingleImage) => {
                    if (SingleImage.is_leading)
                      return (
                        <div className="lg:w-1/2" style={{ height: "25%" }}>
                          <img
                            src={SingleImage.public_url}
                            alt={SingleItem.title}
                            className="m-auto"
                            style={{ maxHeight: "50vh" }}
                          />
                        </div>
                      );
                  })
                : "..."}
            </div>
          ) : (
            <div className="bg-gray-100">
              <div className="flex flex-col lg:flex-row lg:justify-between my-10 px-5 lg:px-20">
                {SingleItem.images
                  ? SingleItem.images.map((SingleImage) => {
                      if (SingleImage.is_leading)
                        return (
                          <div className="lg:w-1/2" style={{ height: "25%" }}>
                            <img
                              src={SingleImage.public_url}
                              alt={SingleItem.title}
                              className="m-auto"
                              style={{ maxHeight: "50vh" }}
                            />
                          </div>
                        );
                    })
                  : "..."}
                <div className="lg:w-1/2 py-7 flex flex-col space-y-5">
                  <p className="text-3xl font-light mx-7">
                    {SingleItem.title}
                    <br />
                    <span className="text-sm font-bold px-3 py-1 bg-gray-200 text-default rounded-full">
                      {SingleItem.category.name}
                    </span>
                  </p>
                  <p className="text-sm text-gray-400 mx-7 font-base">
                    {clipProductDescription}
                  </p>
                  <Link
                    to={{
                      pathname: "/collection/" + SingleItem.id,
                      state: { selectedCollectionID: SingleItem.id },
                    }}
                    className="text-default text-base mb-4 flex flex-row space-x-3 items-center font-light mx-7"
                  >
                    <p>Read more</p>
                    <BsArrowRight size="25" />
                  </Link>
                </div>
              </div>
            </div>
          );
        })
      : null;
  return (
    <div className="py-12 pt-24">
      <div className="flex flex-col flex-wrap my-8">{carouselItemsMapping}</div>
    </div>
  );
};
