import React from "react";

// External imports
import { TiLocation } from "react-icons/ti";
import { CgPhone } from "react-icons/cg";
import { HiMail } from "react-icons/hi";

// Component imports
import Nav from "../../shared/Nav";
import ContactForm from "./ContactForm";
import Footer from "../../shared/Footer";

// Image import
import Map from "../../../assets/images/Map.png";

export default function Root() {
  return (
    <div>
      <Nav />
      <Content />
    </div>
  );
}

// Content
const Content = () => {
  return (
    <div className="pt-24">
      {/* Map Image - REPLACED WITH IFRAME */}
      <img src={Map} alt="Map" className="w-screen" />
      <ContactDetails />
      <Footer />
    </div>
  );
};

// Contact Details
const ContactDetails = () => {
  return (
    <div className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 px-5 lg:px-20 py-32">
        <h1 className="text-5xl font-light">
          Get in touch<span className="font-bold"> With Us</span>
        </h1>
        <div className="flex flex-col space-y-8 mt-10 font-light text-xl">
          <div className="flex flex-row space-x-5 items-center">
            <TiLocation size="30" />
            <p>2nd Floor, Century Mall, Addis Ababa, Ethiopia</p>
          </div>
          {/* <div className="flex flex-row space-x-5 items-center">
            <CgPhone size="30" />
            <a href="tel:0939063358">+251-939-063-358</a>
          </div> */}
          <div className="flex flex-row space-x-5 items-center">
            <HiMail size="30" />
            <a
              href="mailto:info@kunjina.shop"
              className="no-underline hover:no-underline"
            >
              info@kunjina.shop
            </a>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 bg-dark text-gray-300">
        <ContactForm />
      </div>
    </div>
  );
};
