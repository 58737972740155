// Application wide
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const RESET_LOADER = "RESET_LOADER";

// Contact form email
export const CONTACT_FORM_SUCCESS = "CONTACT_FORM_SUCCESS";
export const CONTACT_FORM_FAILED = "CONTACT_FORM_FAILED";
export const ADD_SUBSCRIBER_SUCCESS = "ADD_SUBSCRIBER_SUCCESS";
export const ADD_SUBSCRIBER_FAILED = "ADD_SUBSCRIBER_FAILED";
export const RESET_CONTACT_FORM = "RESET_CONTACT_FORM";

// User and auth
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILED = "EDIT_PROFILE_FAILED";
export const RESET_USER_STATE = "RESET_USER_STATE";

// Product Category
export const ADD_PRODUCT_CATEGORY_SUCCESS = "ADD_PRODUCT_CATEGORY_SUCCESS";
export const ADD_PRODUCT_CATEGORY_FAILED = "ADD_PRODUCT_CATEGORY_FAILED";
export const EDIT_PRODUCT_CATEGORY_SUCCESS = "EDIT_PRODUCT_CATEGORY_SUCCESS";
export const EDIT_PRODUCT_CATEGORY_FAILED = "EDIT_PRODUCT_CATEGORY_FAILED";
export const DELETE_PRODUCT_CATEGORY_SUCCESS =
  "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_FAILED = "DELETE_PRODUCT_CATEGORY_FAILED";

// Product variations
export const ADD_COLOR_SUCCESS = "ADD_COLOR_SUCCESS";
export const ADD_COLOR_FAILED = "ADD_COLOR_FAILED";
export const ADD_SIZE_SUCCESS = "ADD_SIZE_SUCCESS";
export const ADD_SIZE_FAILED = "ADD_SIZE_FAILED";
export const ADD_VARIATION_SUCCESS = "ADD_VARIATION_SUCCESS";
export const ADD_VARIATION_FAILED = "ADD_VARIATION_FAILED";
export const EDIT_VARIANT_SUCCESS = "EDIT_VARIANT_SUCCESS";
export const EDIT_VARIANT_FAILED = "EDIT_VARIANT_FAILED";
export const DELETE_VARIANT_SUCCESS = "DELETE_VARIANT_SUCCESS";
export const DELETE_VARIANT_FAILED = "DELETE_VARIANT_FAILED";

// Product
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILED = "ADD_PRODUCT_FAILED";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILED = "EDIT_PRODUCT_FAILED";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED";
export const EDIT_VARIATION_SUCCESS = "EDIT_VARIATION_SUCCESS";
export const EDIT_VARIATION_FAILED = "EDIT_VARIATION_FAILED";
export const DELETE_PRODUCT_VARIATION_SUCCESS =
  "DELETE_PRODUCT_VARIATION_SUCCESS";
export const DELETE_PRODUCT_VARIATION_FAILED =
  "DELETE_PRODUCT_VARIATION_FAILED";
export const RESET_PRODUCT_STATE = "RESET_PRODUCT_STATE";

// Collection
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS";
export const ADD_COLLECTION_FAILED = "ADD_COLLECTION_FAILED";
export const ADD_COLLECTION_CATEGORY_SUCCESS =
  "ADD_COLLECTION_CATEGORY_SUCCESS";
export const ADD_COLLECTION_CATEGORY_FAILED = "ADD_COLLECTION_CATEGORY_FAILED";
export const EDIT_COLLECTION_SUCCESS = "EDIT_COLLECTION_SUCCESS";
export const EDIT_COLLECTION_FAILED = "EDIT_COLLECTION_FAILED";
export const EDIT_COLLECTION_CATEGORY_SUCCESS =
  "EDIT_COLLECTION_CATEGORY_SUCCESS";
export const EDIT_COLLECTION_CATEGORY_FAILED =
  "EDIT_COLLECTION_CATEGORY_FAILED";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_FAILED = "DELETE_COLLECTION_FAILED";
export const DELETE_COLLECTION_CATEGORY_SUCCESS =
  "DELETE_COLLECTION_CATEGORY_SUCCESS";
export const DELETE_COLLECTION_CATEGORY_FAILED =
  "DELETE_COLLECTION_CATEGORY_FAILED";
export const RESET_COLLECTION = "RESET_COLLECTION";

// Media
export const ADD_MEDIA_SUCCESS = "ADD_MEDIA_SUCCESS";
export const ADD_MEDIA_FAILED = "ADD_MEDIA_FAILED";
export const EDIT_MEDIA_SUCCESS = "EDIT_MEDIA_SUCCESS";
export const EDIT_MEDIA_FAILED = "EDIT_MEDIA_FAILED";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_FAILED = "DELETE_MEDIA_FAILED";
export const RESET_MEDIA = "RESET_MEDIA";

// Order
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAILED = "ORDER_FAILED";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILED = "UPDATE_ORDER_STATUS_FAILED";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART";
export const ADD_TO_CART_FAILED = "ADD_TO_CART_FAILED";
export const GET_ALL_CART_SUCCESS = "GET_ALL_CART_SUCCESS";
export const GET_ALL_CART_FAILED = "GET_ALL_CART_FAILED";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_FAILED = "REMOVE_FROM_CART_FAILED";
export const REMOVE_ALL_FROM_CART_SUCCESS = "REMOVE_ALL_FROM_CART_SUCCESS";
export const REMOVE_ALL_FROM_CART_FAILED = "REMOVE_ALL_FROM_CART_FAILED";
export const GET_ALL_WISHLIST_SUCCESS = "GET_ALL_WISHLIST_SUCCESS";
export const GET_ALL_WISHLIST_FAILED = "GET_ALL_WISHLIST_FAILED";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAILED = "ADD_TO_WISHLIST_FAILED";
export const REMOVE_FROM_WISHLIST_SUCCESS = "REMOVE_FROM_WISHLIST_SUCCESS";
export const REMOVE_FROM_WISHLIST_FAILED = "REMOVE_FROM_WISHLIST_FAILED";
export const REMOVE_ALL_CART = "REMOVE_ALL_CART";
export const REMOVE_ALL_WISHLIST = "REMOVE_ALL_WISHLIST";
export const RESET_ORDER_STATE = "RESET_ORDER_STATE";

// Hero image
export const ADD_HERO_IMAGE_SUCCESS = "ADD_HERO_IMAGE_SUCCESS";
export const ADD_HERO_IMAGE_FAILED = "ADD_HERO_IMAGE_FAILED";
export const EDIT_HERO_IMAGE_SUCCESS = "EDIT_HERO_IMAGE_SUCCESS";
export const EDIT_HERO_IMAGE_FAILED = "EDIT_HERO_IMAGE_FAILED";
export const DELETE_HERO_IMAGE_SUCCESS = "DELETE_HERO_IMAGE_SUCCESS";
export const DELETE_HERO_IMAGE_FAILED = "DELETE_HERO_IMAGE_FAILED";
export const RESET_HERO_IMAGE = "RESET_HERO_IMAGE";
