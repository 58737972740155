import { START_LOADER, STOP_LOADER, RESET_LOADER } from "./Type";

export const startLoader = () => {
  return (dispatch) => {
    dispatch({ type: START_LOADER });
  };
};

export const stopLoader = () => {
  return (dispatch) => {
    dispatch({ type: STOP_LOADER });
  };
};

export const resetLoader = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LOADER });
  };
};
